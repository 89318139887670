import React, { useState, useEffect, useContext } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import DevStateContext from "../data/contexts/DevStateContext";
import Box from "@mui/material/Box";
import ApptPreviewCard from "../components/cards/ApptPreviewCard";
import {
    createAppointment,
    createAppointmentAnimal,
    createAppointmentNote,
    createApptTasks
} from "../data/create/appt/createAppt";
import BaseStyledButton from "../components/buttons/BaseStyledButton";
import CreateNewRecSuccessDialog from "../components/modals/CreateNewRecSuccessDialog";
import ClientApptsContext from "../data/contexts/ClientApptsContext";
import { useConsoleLog } from "../components/hooks/logging/console";
import { CreateNewApptProgressMessagesObj } from "../utils/text";

// TODO - UPDATE TO USE FORM OBJS FROM UTILS

export default function CreateApptPreviewScreen(props) {
    const [ampUser, setAmpUser] = useState({});
    const [showLogs, setShowLogs] = useState(false);
    const [apptClientId, setApptClientId] = useState(null);
    const [clientLastAppt, setClientLastAppt] = useState(null);
    const [triggerClientApptsProvider, setTriggerClientApptsProvider] = useState(false);

    const [newApptRec, setNewApptRec] = useState(null);
    const [newApptRecId, setNewApptRecId] = useState(null);
    const [newApptRecCreateResObj, setNewApptRecCreateResObj] = useState(null);

    const [newApptAnimalRecs, setNewApptAnimalRecs] = useState([]);
    const [newApptAnimalRecsCreateResObj, setNewApptAnimalRecsCreateResObj] = useState(null);

    const [newApptNoteRec, setNewApptNoteRec] = useState(null);
    const [newApptNoteRecCreateResObj, setNewApptNoteRecCreateResObj] = useState(null);

    const [newShoeTaskRecs, setNewShoeTaskRecs] = useState([]);
    const [newTrimTaskRecs, setNewTrimTaskRecs] = useState([]);
    const [newApptTasksCreateResObj, setNewApptTasksCreateResObj] = useState(null);

    const [apptFormValues, setApptFormValues] = useState({
        appointmentStatus: '',
        amount: 0,
        apptAmount: 0,
        address: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        apptType: '',
        apptDate: null,
        apptTime: null,
        apptDateTime: '',
        duration: 0,
        apptDuration: 0,
        apptNotes: '',
        lastApptNotes: null,
        animalIds: [],
        clientId: null,
        clientName: '',
        gateCode: '',
    });
    const [apptNoteFormValues, setApptNoteFormValues] = useState({
        apptNote: '',
    });
    const [tasksForAnimals, setTasksForAnimals] = useState([]);

    const [createNewApptRec, setCreateNewApptRec] = useState(false);
    const [createNewApptRelatedRecs, setCreateNewApptRelatedRecs] = useState(false);
    const [isCreatingApptRecs, setIsCreatingApptRecs] = useState(false);

    const [displaySuccessDialog, setDisplaySuccessDialog] = useState(false);

    // TODO - SOME OF THESE ARE PROB OVERKILL, USE SUCCESS DIALOG TO DISPLAY SUCCESS MSG
    const [displayProgressDialog, setDisplayProgressDialog] = useState(false);
    const [progressDialogTitleText, setProgressDialogTitleText] = useState('');
    const [progressDialogBodyText, setProgressDialogBodyText] = useState('');
    const [newApptProgressMessagesObjKey, setNewApptProgressMessagesObjKey] = useState(null);
    const [newApptProgressMessagesObjKeyIndex, setNewApptProgressMessagesObjKeyIndex] = useState(0);
    const [initSyncProgressDialogBodyTextTimestamp, setInitSyncProgressDialogBodyTextTimestamp] = useState(-1);
    const [lastSyncProgressDialogBodyTextTimestamp, setLastSyncProgressDialogBodyTextTimestamp] = useState(-1);
    const [lastSyncProgressDialogBodyTextTimestampUpdatesCount, setLastSyncProgressDialogBodyTextTimestampUpdatesCount] = useState(0);
    const [lastSyncProgressDialogBodyTextTimestampDiffMs, setLastSyncProgressDialogBodyTextTimestampDiffMs] = useState(0);
    const [continueUpdatingProgressDialogBodyTextSyncTimestamp, setContinueUpdatingProgressDialogBodyTextSyncTimestamp] = useState(true);

    let navigate = useNavigate();
    let location = useLocation();

    const {devStateObj} = useContext(DevStateContext);
    const {clientLastApptRec, clientNextApptRec, setAmpUserRecId, setClientRecId } = useContext(ClientApptsContext);

    // useEffect( setShowLogs(
    // useEffect(() => {
    //     if (devStateObj?.showLogs){
    //         setShowLogs(devStateObj.showLogs);
    //     } else {
    //         setShowLogs(false);
    //     }
    // }, [devStateObj]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser, showLogs]);

    // useEffect(() => { console.log('location', location);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('location', location);
    //     }
    // }, [location, showLogs]);

    // TODO - update to use from props
    // useEffect( setApptFormValues( setApptNoteFormValues( setTasksForAnimals(
    // useEffect(() => {
    //     if (location?.state?.apptFormValues) {
    //         setApptFormValues(location.state.apptFormValues);
    //     }
    //     if (location?.state?.apptNoteFormValues) {
    //         setApptNoteFormValues(location.state.apptNoteFormValues);
    //     }
    //     if (location?.state?.tasksForAnimals) {
    //         setTasksForAnimals(location.state.tasksForAnimals);
    //     }
    // }, [location]);
    useEffect(() => {
        if (props?.apptFormValues) {
            setApptFormValues(props.apptFormValues);
        }
        if (props?.apptNoteFormValues) {
            setApptNoteFormValues(props.apptNoteFormValues);
        }
        if (props?.tasksForAnimals) {
            setTasksForAnimals(props.tasksForAnimals);
        }
    }, [props]);

    // useEffect( setApptClientId(apptFormValues.clientId);
    useEffect(() => {
        if (apptFormValues && apptFormValues?.clientId) {
            setApptClientId(apptFormValues.clientId);
        }
    }, [apptFormValues]);

    useEffect(() => {
        if (clientLastApptRec) {
            setClientLastAppt(clientLastApptRec);
        }
        else {
            if (!triggerClientApptsProvider) {
                setTriggerClientApptsProvider(true);
            }
            // setTriggerClientApptsProvider(!triggerClientApptsProvider);
        }
    // }, [clientLastApptRec, triggerClientApptsProvider]);
    }, [clientLastApptRec]);

    useConsoleLog(showLogs, [{label: 'CreateApptPreviewScreen clientLastApptRec', value: clientLastApptRec}]);
    useConsoleLog(showLogs, [{label: 'CreateApptPreviewScreen triggerClientApptsProvider', value: triggerClientApptsProvider}]);

    useEffect(() => {
        if (triggerClientApptsProvider && ampUser && apptClientId) {
            // setClientLastAppt(clientLastApptRec);
            if (setClientRecId && setAmpUserRecId) {
                setAmpUserRecId(ampUser.id);
                setClientRecId(apptClientId);
                setTriggerClientApptsProvider(false);
            }
            // setTriggerClientApptsProvider(false);

        }
    }, [triggerClientApptsProvider, ampUser, apptClientId]);

    // useEffect(() => { console.log(apptFormValues, apptNoteFormValues, tasksForAnimals, apptDateTime
    useEffect(() => {
        if (showLogs) {
            console.log('apptFormValues', apptFormValues);
            console.log('apptNoteFormValues', apptNoteFormValues);
            console.log('tasksForAnimals', tasksForAnimals);
            // console.log('apptDateTime', apptDateTime);
            // console.log('apptTimeVals', apptTimeVals);
        }
    // }, [showLogs, apptFormValues, apptNoteFormValues, tasksForAnimals, apptDateTime, apptTimeVals]);
    }, [showLogs, apptFormValues, apptNoteFormValues, tasksForAnimals]);

    // useEffect( console.log( newApptRec, newApptAnimalRecs
    // useEffect( console.log( newApptRec, newApptAnimalRecs, newApptNoteRec, newShoeTaskRecs, newTrimTaskRecs
    useEffect(() => {
        if (showLogs) {
            console.log('newApptRec', newApptRec);
            console.log('newApptAnimalRecs', newApptAnimalRecs);
            // console.log('newApptNoteRec', newApptNoteRec);
            // console.log('newShoeTaskRecs', newShoeTaskRecs);
            // console.log('newTrimTaskRecs', newTrimTaskRecs);
        }
        // }, [showLogs, newApptRec, newApptAnimalRecs, newApptNoteRec, newShoeTaskRecs, newTrimTaskRecs]);
    }, [showLogs, newApptRec, newApptAnimalRecs]);

    // useEffect( console.log( newApptNoteRec, newShoeTaskRecs, newTrimTaskRecs
    // useEffect( console.log( newApptRec, newApptAnimalRecs, newApptNoteRec, newShoeTaskRecs, newTrimTaskRecs
    useEffect(() => {
        if (showLogs) {
            // console.log('newApptRec', newApptRec);
            // console.log('newApptAnimalRecs', newApptAnimalRecs);
            console.log('newApptNoteRec', newApptNoteRec);
            console.log('newShoeTaskRecs', newShoeTaskRecs);
            console.log('newTrimTaskRecs', newTrimTaskRecs);
        }
    }, [showLogs, newApptNoteRec, newShoeTaskRecs, newTrimTaskRecs]);

    useEffect(() => {
        // if (isCreatingApptRecs && createNewApptRec) {
            async function createApptRec() {
                const lastApptId = clientLastAppt ? clientLastAppt.id : null;
                const createApptRes =  await createAppointment(ampUser.id, apptFormValues, lastApptId, showLogs);
                setNewApptRecCreateResObj(createApptRes);
                const apptRec = createApptRes.newApptRec;
                if (createApptRes.success && apptRec) {
                    setNewApptRec(apptRec);
                    setNewApptRecId(apptRec.id);
                    setCreateNewApptRelatedRecs(true);
                    // return apptRec;
                    return createApptRes;
                    // TODO - SHOW SUCCESS DIALOG AFTER ALL RECS CREATED SUCCESSFULLY,
                    //  NOT JUST THIS ONE,
                    //  ADD SUCCESS DIALOG AND SET TEXT TO NEW RECS CREATED
                    // setDisplaySuccessDialog(true);
                }
                else {
                    console.error('createApptRes.error', createApptRes.error);
                    // TODO - ADD CHECK, HANDLING FOR NULL RETURN
                    return null;
                }
            }
        // }

        if (isCreatingApptRecs && createNewApptRec) {
            async function createApptRecTwo() {
                const lastApptId = clientLastAppt ? clientLastAppt.id : null;
                const createApptRes =  await createAppointment(ampUser.id, apptFormValues, lastApptId, showLogs);
                setNewApptRecCreateResObj(createApptRes);
                const apptRec = createApptRes.newApptRec;
                if (createApptRes.success && apptRec) {
                    setNewApptRec(apptRec);
                    setNewApptRecId(apptRec.id);
                    setCreateNewApptRelatedRecs(true);
                    // return apptRec;
                    return createApptRes;
                    // TODO - SHOW SUCCESS DIALOG AFTER ALL RECS CREATED SUCCESSFULLY,
                    //  NOT JUST THIS ONE,
                    //  ADD SUCCESS DIALOG AND SET TEXT TO NEW RECS CREATED
                    // setDisplaySuccessDialog(true);
                }
                else {
                    console.error('createApptRes.error', createApptRes.error);
                    // TODO - ADD CHECK, HANDLING FOR NULL RETURN
                    return null;
                }
            }
            // createApptRec().then((res) => {
            createApptRecTwo().then((res) => {
                setNewApptRecCreateResObj(res);
                // setIsCreatingApptRecs(false);
                if (res) {
                    // setNewApptRecCreateResObj(createApptRes);
                    // setNewApptRecCreateResObj(res);
                    if (res.success) {
                        setNewApptRec(res.newApptRec);
                        setNewApptRecId(res.newApptRec.id);
                        setCreateNewApptRec(false);
                        setCreateNewApptRelatedRecs(true);
                    }
                    else {
                        console.error('createApptRec error', res.error);
                        setNewApptRec(null);
                        setNewApptRecId(null);
                        setIsCreatingApptRecs(false);
                        setCreateNewApptRec(false);
                        setCreateNewApptRelatedRecs(false);
                        setProgressDialogBodyText(CreateNewApptProgressMessagesObj.error[0]);
                        // TODO - ADD ERROR DIALOG,
                        //  DISPLAY NEXT DEFAULT ERR MSG TO CONTACT US
                    }
                    // new appt rec is not null -> now create appt related recs
                    // setCreateNewApptRec(false);
                    // setCreateNewApptRelatedRecs(true);
                }
                else {
                    console.error('createApptRec error', res.error);
                    // setCreateNewApptRec(false);
                    setNewApptRec(null);
                    setNewApptRecId(null);
                    setIsCreatingApptRecs(false);
                    setCreateNewApptRec(false);
                    setCreateNewApptRelatedRecs(false);
                    setProgressDialogBodyText(CreateNewApptProgressMessagesObj.error[0]);
                    // TODO - ADD ERROR DIALOG,
                    //  DISPLAY NEXT DEFAULT ERR MSG TO CONTACT US
                }
            }).catch((error) => {
                // TODO - DISPLAY ERROR DIALOG
                console.error('createAppt error', error);
                setNewApptRec(null);
                setNewApptRecId(null);
                setIsCreatingApptRecs(false);
                setCreateNewApptRec(false);
                setCreateNewApptRelatedRecs(false);
                setProgressDialogBodyText(CreateNewApptProgressMessagesObj.error[0]);
                // TODO - ADD ERROR DIALOG,
                //  DISPLAY NEXT DEFAULT ERR MSG TO CONTACT US
            });
        }
    }, [isCreatingApptRecs, createNewApptRec]);

    useEffect(() => {
        // if (isCreatingApptRecs && createNewApptRelatedRecs && newApptRec && newApptRecId) {
            async function createApptRelatedRecs() {
                const resObj = {};
                if (newApptRec && newApptRecId && apptFormValues.animalIds.length > 0) {
                    const apptAnimals = [];
                    for (const animalId of apptFormValues.animalIds) {
                        const newRecRes = await createAppointmentAnimal(newApptRec.id, animalId, showLogs);
                        // TODO - ADD CHECK, HANDLING FOR NULL RETURN, SUCCESS CHECK
                        if (newRecRes && newRecRes?.success) {
                            apptAnimals.push(newRecRes.newApptAnimalRec);
                        }
                        else {
                            // TODO - ADD ERROR DIALOG,
                            //  CHECK FOR ERROR IN RES OBJS IN ARR
                            apptAnimals.push(newRecRes);
                        }
                    }
                    resObj.newApptAnimalRecs = apptAnimals;
                    setNewApptAnimalRecs(apptAnimals);
                }
                if (newApptRec && newApptRecId) {
                    if (apptNoteFormValues?.apptNote && apptNoteFormValues?.apptNote?.length > 0) {
                        const noteRecRes = await createAppointmentNote(newApptRec.id, apptNoteFormValues.apptNote, showLogs);
                        setNewApptNoteRecCreateResObj(noteRecRes);
                        resObj.noteRecRes = noteRecRes;
                        if (noteRecRes && noteRecRes?.success) {
                            setNewApptNoteRec(noteRecRes.newApptNote);
                        }
                        else {
                            // TODO - ADD ERROR DIALOG,
                            //  CHECK FOR ERROR IN RES OBJS IN ARR
                            setNewApptNoteRec(null);
                        }
                    }
                    if (tasksForAnimals && tasksForAnimals?.length > 0) {
                        const newTaskRecsRes = await createApptTasks(newApptRec.id, tasksForAnimals, showLogs);
                        // setNewShoeTaskRecs(newTaskRecsRes.shoeTasks);
                        // setNewTrimTaskRecs(newTaskRecsRes.trimTasks);
                        resObj.newTaskRecsRes = newTaskRecsRes;
                        setNewApptTasksCreateResObj(newTaskRecsRes);
                        // if (newTaskRecsRes && newTaskRecsRes?.success) {
                        if (newTaskRecsRes) {
                            // TODO - THIS NEEDS MAJOR WORK TO USE HERE,
                            //  ITERATE THROUGH AND SHOW ERRORS, ETC,
                            //  JUST USING DEFAULT SET STATE ARR FOR NOW
                            setNewShoeTaskRecs(newTaskRecsRes.newTaskRecs.shoeTasks);
                            setNewTrimTaskRecs(newTaskRecsRes.newTaskRecs.trimTasks);
                        }
                        else {
                            // TODO - ADD ERROR DIALOG,
                            //  CHECK FOR ERROR IN RES OBJS IN ARR
                            setNewShoeTaskRecs([]);
                            setNewTrimTaskRecs([]);
                        }
                    }
                }
                // TODO - USING DEFAULT OF SUCCESS = TRUE FOR NOW, HANDLE EACH RELATED REC CREATE ERROR LATER
                resObj.success = true;
                return resObj;
            }
        // }

        if (isCreatingApptRecs && createNewApptRelatedRecs && newApptRec && newApptRecId) {
            async function createApptRelatedRecsTwo() {
                const resObj = {};
                // if (newApptRec && newApptRecId && apptFormValues.animalIds.length > 0) {
                if (apptFormValues.animalIds.length > 0) {
                    const apptAnimals = [];
                    for (const animalId of apptFormValues.animalIds) {
                        const newRecRes = await createAppointmentAnimal(newApptRec.id, animalId, showLogs);
                        // TODO - ADD CHECK, HANDLING FOR NULL RETURN, SUCCESS CHECK
                        if (newRecRes && newRecRes?.success) {
                            apptAnimals.push(newRecRes.newApptAnimalRec);
                        }
                        else {
                            // TODO - ADD ERROR DIALOG,
                            //  CHECK FOR ERROR IN RES OBJS IN ARR
                            apptAnimals.push(newRecRes);
                        }
                    }
                    resObj.newApptAnimalRecs = apptAnimals;
                    setNewApptAnimalRecs(apptAnimals);
                }
                // if (newApptRec && newApptRecId) {
                    if (apptNoteFormValues?.apptNote && apptNoteFormValues?.apptNote?.length > 0) {
                        const noteRecRes = await createAppointmentNote(newApptRec.id, apptNoteFormValues.apptNote, showLogs);
                        setNewApptNoteRecCreateResObj(noteRecRes);
                        resObj.noteRecRes = noteRecRes;
                        if (noteRecRes && noteRecRes?.success) {
                            setNewApptNoteRec(noteRecRes.newApptNote);
                        }
                        else {
                            // TODO - ADD ERROR DIALOG,
                            //  CHECK FOR ERROR IN RES OBJS IN ARR
                            setNewApptNoteRec(null);
                        }
                    }
                    if (tasksForAnimals && tasksForAnimals?.length > 0) {
                        const newTaskRecsRes = await createApptTasks(newApptRec.id, tasksForAnimals, showLogs);
                        // setNewShoeTaskRecs(newTaskRecsRes.shoeTasks);
                        // setNewTrimTaskRecs(newTaskRecsRes.trimTasks);
                        resObj.newTaskRecsRes = newTaskRecsRes;
                        setNewApptTasksCreateResObj(newTaskRecsRes);
                        // if (newTaskRecsRes && newTaskRecsRes?.success) {
                        if (newTaskRecsRes) {
                            // TODO - THIS NEEDS MAJOR WORK TO USE HERE,
                            //  ITERATE THROUGH AND SHOW ERRORS, ETC,
                            //  JUST USING DEFAULT SET STATE ARR FOR NOW
                            setNewShoeTaskRecs(newTaskRecsRes.newTaskRecs.shoeTasks);
                            setNewTrimTaskRecs(newTaskRecsRes.newTaskRecs.trimTasks);
                        }
                        else {
                            // TODO - ADD ERROR DIALOG,
                            //  CHECK FOR ERROR IN RES OBJS IN ARR
                            setNewShoeTaskRecs([]);
                            setNewTrimTaskRecs([]);
                        }
                    }
                // }
                // TODO - USING DEFAULT OF SUCCESS = TRUE FOR NOW, HANDLE EACH RELATED REC CREATE ERROR LATER
                resObj.success = true;
                return resObj;
            }

            // createApptRelatedRecs().then((res) => {
            createApptRelatedRecsTwo().then((res) => {
                if (res) {
                    // setNewApptRecCreateResObj(createApptRes);
                    // setNewApptRecCreateResObj(res);
                    if (res.success) {
                        // setNewApptRec(res.newApptRec);
                        // setNewApptRecId(res.newApptRec.id);
                        setIsCreatingApptRecs(false);
                        setCreateNewApptRelatedRecs(false);

                        // TODO - HAND OFF SUCCESS MSG BETWEEN THESE TWO,
                        //  ONLY USE PROGRESS DIALOG FOR PROGRESS UPDATES
                        setProgressDialogBodyText(CreateNewApptProgressMessagesObj.success[0]);
                        setDisplaySuccessDialog(true);

                        // TODO - IMPLEMENT MSG, TS INCREMENTING THROUGH CREATE REC OUTCOMES
                        setContinueUpdatingProgressDialogBodyTextSyncTimestamp(false);
                    } else {
                        console.error('createApptRelatedRecs error', res.error);
                        setNewApptAnimalRecs([]);
                        setNewApptNoteRec(null);
                        setNewShoeTaskRecs([]);
                        setNewTrimTaskRecs([]);
                        setIsCreatingApptRecs(false);
                        setCreateNewApptRec(false);
                        setCreateNewApptRelatedRecs(false);
                        setProgressDialogBodyText(CreateNewApptProgressMessagesObj.error[0]);
                        // TODO - ADD ERROR DIALOG,
                        //  DISPLAY NEXT DEFAULT ERR MSG TO CONTACT US
                    }
                    // new appt rec is not null -> now create appt related recs
                    // setCreateNewApptRec(false);
                    // setCreateNewApptRelatedRecs(true);
                } else {
                    console.error('createApptRelatedRecs error', res.error);
                    setNewApptAnimalRecs([]);
                    setNewApptNoteRec(null);
                    setNewShoeTaskRecs([]);
                    setNewTrimTaskRecs([]);
                    setIsCreatingApptRecs(false);
                    setCreateNewApptRec(false);
                    setCreateNewApptRelatedRecs(false);
                    setProgressDialogBodyText(CreateNewApptProgressMessagesObj.error[0]);
                    // TODO - ADD ERROR DIALOG,
                    //  DISPLAY NEXT DEFAULT ERR MSG TO CONTACT US
                }
            }).catch((error) => {
                // TODO - DISPLAY ERROR DIALOG
                console.error('createApptRelatedRecs error', error);
                setNewApptAnimalRecs([]);
                setNewApptNoteRec(null);
                setNewShoeTaskRecs([]);
                setNewTrimTaskRecs([]);
                setIsCreatingApptRecs(false);
                setCreateNewApptRec(false);
                setCreateNewApptRelatedRecs(false);
                setProgressDialogBodyText(CreateNewApptProgressMessagesObj.error[0]);
                // TODO - ADD ERROR DIALOG,
                //  DISPLAY NEXT DEFAULT ERR MSG TO CONTACT US
            });
        }
    }, [createNewApptRelatedRecs, isCreatingApptRecs, newApptRecCreateResObj, newApptRec, newApptRecId]);

    async function createAppt() {
        // if (showLogs) {
        //     console.log('CreateApptPreviewScreen createAppt()');
        // }

        // TODO - NEW APPROACH TO ENFORCE ORDER OF OPS - SET A BUNCH OF STATE VALS HERE,
        //  THEN KICK OFF USEEFFECTS BASED ON STATE VALS AS TRIGGERS
        setInitSyncProgressDialogBodyTextTimestamp(Date.now());
        setLastSyncProgressDialogBodyTextTimestamp(Date.now());
        setLastSyncProgressDialogBodyTextTimestampUpdatesCount(0);
        setLastSyncProgressDialogBodyTextTimestampDiffMs(0);
        setContinueUpdatingProgressDialogBodyTextSyncTimestamp(true);
        setNewApptProgressMessagesObjKey('base');
        setNewApptProgressMessagesObjKeyIndex(0);
        setProgressDialogTitleText(CreateNewApptProgressMessagesObj.title);
        setProgressDialogBodyText(CreateNewApptProgressMessagesObj.base[0]);
        setIsCreatingApptRecs(true);
        setCreateNewApptRec(true);
        setDisplayProgressDialog(true);

        // todo - recent toggle
        // const lastApptId = clientLastAppt ? clientLastAppt.id : null;

        // xx
        // const apptRec =  await createAppointment(ampUser.id, apptFormValues, lastApptId, showLogs);

        // TODO - !!! START HERE, FR, FR - SHOW PROGRESS DIALOG WHILE ALL RECS CREATED
        // todo - recent toggle
        // const createApptRes =  await createAppointment(ampUser.id, apptFormValues, lastApptId, showLogs);

        // todo - recent toggle
        // const apptRec = createApptRes.newApptRec;
        // if (createApptRes.success) {
        //     setNewApptRec(apptRec);
        //
        //     // TODO - SHOW SUCCESS DIALOG AFTER ALL RECS CREATED SUCCESSFULLY,
        //     //  NOT JUST THIS ONE,
        //     //  ADD SUCCESS DIALOG AND SET TEXT TO NEW RECS CREATED
        //     setDisplaySuccessDialog(true);
        // }
        // else {
        //     console.error('createApptRes.error', createApptRes.error);
        //     return;
        // }

        // todo - recent toggle
        // if (apptRec && apptFormValues.animalIds.length > 0) {
        //     const apptAnimals = [];
        //     for (const animalId of apptFormValues.animalIds) {
        //         const newRec = await createAppointmentAnimal(apptRec.id, animalId, showLogs);
        //         apptAnimals.push(newRec);
        //     }
        //     setNewApptAnimalRecs(apptAnimals);
        // }

        // todo - recent toggle
        // if (apptRec) {
        //     if (apptNoteFormValues.apptNote && apptNoteFormValues.apptNote.length > 0) {
        //         const noteRec = await createAppointmentNote(apptRec.id, apptNoteFormValues.apptNote, showLogs);
        //         setNewApptNoteRec(noteRec);
        //     }
        //     if (tasksForAnimals.length > 0) {
        //         const newTaskRecs = await createApptTasks(apptRec.id, tasksForAnimals, showLogs);
        //         // if (showLogs) {
        //         //     console.log('newTaskRecs', newTaskRecs);
        //         // }
        //         setNewShoeTaskRecs(newTaskRecs.shoeTasks);
        //         setNewTrimTaskRecs(newTaskRecs.trimTasks);
        //     }
        // }

        // TODO - ADD USE EFFECTS FOR HANDLING MMSG UPDAATES, ERRORS, OTHER STATE OUTCOMES BASED ON CREATE REC RESULTS

        // TODO -RESOLVE NAVE BETWEEN HERE AND SUCCESS DIALOG
        // TODO ADD ROUTE FOR APPTS BY AMPUSER
        // navigate('/appointments');
    }

    return (
        <Box>
            <ApptPreviewCard
                apptFormValues={apptFormValues}
                apptNoteFormValues={apptNoteFormValues}
                tasksForAnimals={tasksForAnimals}
                ampUser={ampUser}
                showLogs={showLogs}
            />
            <Box>
                {/*<button onClick={createAppt}>Create Appointment</button>*/}
                <BaseStyledButton
                    label="Create Appointment"
                    onClick={createAppt}
                    />
            </Box>
        </Box>
    )
}