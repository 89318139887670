import React, {useState, useEffect} from 'react';
import {RequireAuth} from '../auth/RequireAuth';
import DevStateProvider from "../../data/providers/DevStateProvider";
import AmpUserClientsDataProvider from "../../data/providers/AmpUserClientsDataProvider";
import CreateApptPreviewScreen from "../../screens/CreateApptPreviewScreen";
import {useLocation} from "react-router-dom";
import ClientApptsDataProvider from "../../data/providers/ClientApptsDataProvider";
import {RequireSub} from "../auth/RequireSub";
// import CreateApptScreen from "../../screens/CreateApptScreen";

const WrappedCreateApptPreview = ({ routerPaths, ampUser, showLogs, resetProvidersStates }) => {
    const [tasksForAnimals, setTasksForAnimals] = useState([]);
    const [apptNoteFormValues, setApptNoteFormValues] = useState({
        apptNote: '',
    });
    const [apptFormValues, setApptFormValues] = useState({
        appointmentStatus: '',
        amount: 0,
        apptAmount: 0,
        address: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        apptType: '',
        apptDate: null,
        apptTime: null,
        apptDateTime: '',
        duration: 0,
        apptDuration: 0,
        apptNotes: '',
        lastApptNotes: null,
        animalIds: [],
        clientId: null,
        clientName: '',
        gateCode: '',
    });
    const [apptPreviewValsSet, setApptPreviewValsSet] = useState(false);
    const [apptClientId, setApptClientId] = useState(null);

    let location = useLocation();

    // useEffect( setApptFormValues( setApptNoteFormValues( setTasksForAnimals(
    useEffect(() => {
        let allLocationStateValsSet = true;
        if (location?.state?.apptFormValues) {
            setApptFormValues(location.state.apptFormValues);
        }
        else {
            allLocationStateValsSet = false;
        }
        if (location?.state?.apptNoteFormValues) {
            setApptNoteFormValues(location.state.apptNoteFormValues);
        }
        else {
            allLocationStateValsSet = false;
        }
        if (location?.state?.tasksForAnimals) {
            setTasksForAnimals(location.state.tasksForAnimals);
        }
        else {
            allLocationStateValsSet = false;
        }
        setApptPreviewValsSet(allLocationStateValsSet);
    }, [location]);

    // useEffect( setApptClientId(apptFormValues.clientId);
    useEffect(() => {
        if (apptFormValues && apptFormValues?.clientId) {
            setApptClientId(apptFormValues.clientId);
        }
    }, [apptFormValues]);

    function toggleReturnOnClientIdExists() {
        if (apptClientId && ampUser) {
            return (
                // <RequireSub ampUser={ampUser}>
                <ClientApptsDataProvider ampUserId={ampUser.id} showLogs={showLogs} clientId={apptClientId} resetProviderState={resetProvidersStates}>
                    {/*<AmpUserClientsDataProvider ampUser={ampUser} showLogs={showLogs}>*/}
                        <CreateApptPreviewScreen
                            routerPaths={routerPaths}
                            ampUser={ampUser}
                            showLogs={showLogs}
                            apptFormValues={apptFormValues}
                            apptNoteFormValues={apptNoteFormValues}
                            tasksForAnimals={tasksForAnimals}
                        />
                    {/*</AmpUserClientsDataProvider>*/}
                </ClientApptsDataProvider>
                // </RequireSub>
            );
        }
        else if (ampUser) {
            return (
                // <RequireSub ampUser={ampUser}>
                <ClientApptsDataProvider ampUserId={ampUser.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                    {/*<AmpUserClientsDataProvider ampUser={ampUser} showLogs={showLogs}>*/}
                        <CreateApptPreviewScreen
                            routerPaths={routerPaths}
                            ampUser={ampUser}
                            showLogs={showLogs}
                            apptFormValues={apptFormValues}
                            apptNoteFormValues={apptNoteFormValues}
                            tasksForAnimals={tasksForAnimals}
                        />
                    {/*</AmpUserClientsDataProvider>*/}
                </ClientApptsDataProvider>
                // </RequireSub>
            );
        }
        else {
            return (
                <h2>Loading User Data...</h2>
            );
        }
    }

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        {toggleReturnOnClientIdExists()}
                    </RequireSub>
                ) : (
                    <h2>No Amp User</h2>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}

export default WrappedCreateApptPreview;
