import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ClientInfoScreen from "../../screens/ClientInfoScreen";
import DevStateProvider from "../../data/providers/DevStateProvider";
import {RequireAuth} from '../auth/RequireAuth';
import AmpUserClientNotesProvider from "../../data/providers/AmpUserClientNotesProvider";
import ClientApptsDataProvider from "../../data/providers/ClientApptsDataProvider";
import ClientAnimalsDataProvider from "../../data/providers/ClientAnimalsDataProvider";
import {RequireSub} from "../auth/RequireSub";

export default function WrappedClientInfo({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const {clientRouterPath} = useParams();
    const location = useLocation();

    const stateClientRec = location.state?.clientRec;

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( console.log('location', location);
    useEffect(() => {
        if (localShowLogs) {
            console.log('location', location);
        }
    }, [localShowLogs, location]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (ampUser) {
            setAmpUserRec(ampUser);
            // setAmpUserId(props.ampUser.id);
        }
    }, [ampUser]);

    // useEffect( setClientRec(stateClientRec);
    useEffect(() => {
        if (stateClientRec) {
            setClientRec(stateClientRec);
        }
    }, [location]);

    // useEffect( console.log( ampUserRec, clientRec, clientRouterPath
    useEffect(() => {
        if (localShowLogs) {
            console.log('ampUserRec', ampUserRec);
            // console.log('clientRouterPath', clientRouterPath);
            console.log('clientRec', clientRec);  // Log the postRec object
        }
    }, [clientRouterPath, ampUser, clientRec, localShowLogs]);

    return (
        <DevStateProvider>
            <RequireAuth>
                {ampUserRec && clientRec ? (
                    <RequireSub ampUser={ampUserRec}>
                        <ClientApptsDataProvider clientId={clientRec.id} showLogs={showLogs} ampUserId={ampUserRec.id} resetProviderState={resetProvidersStates}>
                            <ClientAnimalsDataProvider clientId={clientRec.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                <AmpUserClientNotesProvider clientId={clientRec.id} resetProviderState={resetProvidersStates}>
                                    <ClientInfoScreen clientRec={clientRec}/>
                                </AmpUserClientNotesProvider>
                            </ClientAnimalsDataProvider>
                        </ClientApptsDataProvider>
                    </RequireSub>
                ) : (
                    <div><h2>Loading Client Data...</h2></div>
                )}
            </RequireAuth>
        </DevStateProvider>
    )
}
