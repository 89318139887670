import {DataStore} from "aws-amplify";
import {
    AmpUser, Animal, Appointment,
    AppointmentNote, AppointmentStatus, Client,
    ClientNote, ShoePackage,
    AppointmentAnimal, ShoeTask, TrimTask, AmpUserSubscriber, AnimalNote
} from "../../models";

export async function fetchAmpUser(ampUserId) {
    console.warn('fetchAmpUser(ampUserId)', ampUserId);
    const updatedAmpUser = await DataStore.query(AmpUser, ampUserId);
    return updatedAmpUser
}

export async function fetchAmpUserSubscriberById(ampUserSubscriberId, showLogs) {
    const ampUserSubscriber = await DataStore.query(AmpUserSubscriber, ampUserSubscriberId);
    return ampUserSubscriber;
}

export async function fetchAmpUserSubscriberByAmpUserId(ampUserId, showLogs) {
    if (ampUserId) {
        const ampUserSubscriber = await DataStore.query(AmpUserSubscriber, (aus) => aus.ampuserID.eq(ampUserId));
        return ampUserSubscriber;
    }
}

export async function fetchApptAnimalByApptId(apptId) {
    // console.log('fetchApptAnimalByIds apptId', apptId);
    // console.log('fetchApptAnimalByIds animalId', animalId);
    const retModels = {};
    const apptAnimals = await DataStore.query(AppointmentAnimal, (aa) =>
        aa.and(aa => [
            aa.appointmentId.eq(apptId),
            aa.isDeleted.ne(true)
        ])
    );
    console.log('fetchApptAnimalByApptId apptAnimals', apptAnimals);
    const modelRecs = apptAnimals;
    retModels.apptAnimalRecs = modelRecs;
    const modelAnimalRecs = [];
    for (const modelRec of modelRecs) {
        const modelAnimalRec = await modelRec.animal
        modelAnimalRecs.push(modelAnimalRec);
    }
    retModels.animalRecs = modelAnimalRecs;

    // const modelApptRec = await modelRec.appointment
    // console.log('fetchApptAnimalByIds modelApptRec', modelApptRec);
    // retModels.apptRec = modelApptRec;
    // const modelAnimalRec = await modelRec.animal
    // console.log('fetchApptAnimalByIds modelAnimalRec', modelAnimalRec);
    // retModels.animalRec = modelAnimalRec;

    return retModels;
}

export async function fetchApptAnimalByIds(apptId, animalId) {
    // console.log('fetchApptAnimalByIds apptId', apptId);
    // console.log('fetchApptAnimalByIds animalId', animalId);
    const retModels = {};
    const apptAnimals = await DataStore.query(AppointmentAnimal, (aa) => aa.and(aa => [
        aa.appointmentId.eq(apptId),
        aa.animalId.eq(animalId),
        aa.isDeleted.ne(true)
    ]));
    // console.log('fetchApptAnimalByIds apptAnimals', apptAnimals);
    const modelRec = apptAnimals[0];
    retModels.apptAnimalRec = modelRec;
    const modelApptRec = await modelRec.appointment
    // console.log('fetchApptAnimalByIds modelApptRec', modelApptRec);
    retModels.apptRec = modelApptRec;
    const modelAnimalRec = await modelRec.animal
    // console.log('fetchApptAnimalByIds modelAnimalRec', modelAnimalRec);
    retModels.animalRec = modelAnimalRec;
    return retModels;
}

// TODO - TRY TO REPLACE WITH NEW APPTS BY CLIENTS PROVIDER
export async function fetchLastApptByClientId(clientID) {
    // const lastAppt = await DataStore.query(Appointment, c => c.clientID.eq(clientID).appointmentStatus.eq("Done"));
    const resObj = {};
    const appts = await DataStore.query(Appointment, (p) => p.and(p => [
        p.clientID.eq(clientID),
        p.appointmentStatus.eq(AppointmentStatus.DONE),
        p.isDeleted.ne(true)
    ]));
    // return lastAppt;
    // Sort the appointments based on createdAt and updatedAt
    const sortedAppointments = appts.sort((a, b) => {
        // Compare by the latest of createdAt or updatedAt
        const latestA = new Date(Math.max(new Date(a.createdAt).getTime(), new Date(a.updatedAt).getTime()));
        const latestB = new Date(Math.max(new Date(b.createdAt).getTime(), new Date(b.updatedAt).getTime()));
        return latestB - latestA; // Sort in descending order
    });

    // Return the first element of the sorted array, which is the newest record
    return sortedAppointments[0];
}

export async function fetchApptShoeTasksByIds(apptId, animalId, showLogs) {
    const apptShoeTasks = await DataStore.query(ShoeTask, (st) => st.and(st => [
        st.appointmentID.eq(apptId),
        st.animalID.eq(animalId),
        st.isDeleted.ne(true)
    ]));
    // console.log('fetchApptShoeTasksByIds apptShoeTasks', apptShoeTasks);
    return apptShoeTasks;
}

export async function fetchApptTrimTasksByIds(apptId, animalId, showLogs) {
    const apptTrimTasks = await DataStore.query(TrimTask, (tt) => tt.and(tt => [
        tt.appointmentID.eq(apptId),
        tt.animalID.eq(animalId),
        tt.isDeleted.ne(true)
    ]));
    // console.log('fetchApptShoeTasksByIds apptShoeTasks', apptShoeTasks);
    return apptTrimTasks;
}

export async function fetchApptNotes(apptID) {
    const apptNotes = await DataStore.query(AppointmentNote, (an) =>
        an.and(an => [
            an.appointmentID.eq(apptID),
            an.isDeleted.ne(true)
        ])
    );
    return apptNotes;
}

export async function fetchClientNoteById(clientNoteId) {
    const clientNote = await DataStore.query(ClientNote, (cn) =>
        cn.and(cn => [
            cn.id.eq(clientNoteId),
            cn.isDeleted.ne(true)
        ])
    );
    return clientNote;
}

export async function fetchClientById(clientId, showLogs) {
    if (showLogs) {
        console.log('fetchClientById clientId', clientId);
    }
    try {
        const clientModelRec = await DataStore.query(Client, (c) =>
            c.and(c => [
                c.id.eq(clientId),
                c.isDeleted.ne(true)
            ])
        );
        return {success: true, clientModelRec};
    }
    catch (error) {
        return {success: false, error};
    }
}

export async function fetchClientNotesByClientId(clientId) {
    const clientNotes = await DataStore.query(ClientNote, (cn) =>
        cn.and(cn => [
            cn.clientID.eq(clientId),
            cn.isDeleted.ne(true)
        ])
    );
    return clientNotes;
}

export async function fetchClientByRouterPath(routerPath) {
    const client = await DataStore.query(Client, (c) =>
        c.and(c => [
            c.routerPath.eq(routerPath),
            c.isDeleted.ne(true)
        ])
    );
    return client;
}

// TODO - START HERE, FR, FR, FIND WHY CANT FETCH ANIMAL BY ID
export async function fetchAnimalById(animalRecArg, showLogs) {
    if (showLogs) {
        // console.log('fetchAnimalById animalId', animalRecArg.id);
        console.log('fetchAnimalById animalRecArg', animalRecArg);
    }
    try {
        // const animalRec = await DataStore.query(Animal, animalRecArg.id.toString());
        const animalRec = await DataStore.query(Animal, animalRecArg);
            // a.and(a => [
            //     a.id.eq(animalRecArg.id),
            //     // a.isDeleted.eq(false)
            // ])
        // );
        if (showLogs) {
            console.log('fetchAnimalById animalRec res', animalRec);
        }
        if (animalRec) {
            return {success: true, animalRec: animalRec};
        }
        else {
            return {success: false, error: "Animal not found"};
        }
    }
    catch (error) {
        return {success: false, error};
    }

}

export async function fetchAnimalByRouterPath(routerPath) {
    const animal = await DataStore.query(Animal, (a) =>
        a.and(a => [
            a.routerPath.eq(routerPath),
            a.isDeleted.ne(true)
        ])
    );
    return animal;
}

export async function fetchShoePackageById(shoePackageId) {
    const shoePackage = await DataStore.query(ShoePackage, (sp) =>
        sp.and(sp => [
            sp.id.eq(shoePackageId),
            sp.isDeleted.ne(true)
        ])
    );
    return shoePackage;
}

export async function fetchShoePackageByRouterPath(routerPath) {
    const shoePackage = await DataStore.query(ShoePackage, (sp) =>
        sp.and(sp => [
            sp.routerPath.eq(routerPath),
            sp.isDeleted.ne(true)
        ])
    );
    return shoePackage;
}

export async function fetchShoePackagesByAnimalId(animalID) {
    const shoePackages = await DataStore.query(ShoePackage, (sp) =>
        sp.and(sp => [
            sp.animalID.eq(animalID),
            sp.isDeleted.ne(true)
        ])
    );
    return shoePackages;
}

export async function fetchShoeTasksByAnimalId(animalID) {
    const shoeTasks = await DataStore.query(ShoeTask, (st) =>
        st.and(st => [
            st.animalID.eq(animalID),
            st.isDeleted.ne(true)
        ])
    );
    return shoeTasks;
}

export async function fetchTrimTasksByAnimalId(animalID) {
    const trimTasks = await DataStore.query(TrimTask, (tt) =>
        tt.and(tt => [
            tt.animalID.eq(animalID),
            tt.isDeleted.ne(true)
        ])
    );
    return trimTasks;
}

export async function fetchAnimalNotesByAnimalId(animalID) {
    const animalNotes = await DataStore.query(AnimalNote, (an) =>
        an.and(an => [
            an.animalID.eq(animalID),
            an.isDeleted.ne(true)
        ])
    );
    return animalNotes;
}

export async function fetchAnimalRecsByClientId(clientID) {
    const animals = await DataStore.query(Animal, (a) =>
        a.and(a => [
            a.clientID.eq(clientID),
            a.isDeleted.ne(true)
        ])
    );
    return animals;
}

export async function fetchApptRecsByClientId(clientID) {
    const appts = await DataStore.query(Appointment, (a) =>
        a.and(a => [
            a.clientID.eq(clientID),
            a.isDeleted.ne(true)
        ])
    );
    return appts;
}
