import {DataStore} from "aws-amplify";
import {UserSub, UserStatus, UserSubStatus, SubscriptionType} from "../../../models";
import { createRecTempId } from "../../../utils/helpers";
import { modelRouterPathAttrFactory } from "../../../utils/breadcrumbsRoutes";
import { msSubMap } from "../../../utils/datesTimes";

// TODO - CREATE TEMP SUBPAYMENT ALSO, OR DECOUPLE FROM THAT MODEL AND ONLY USE SUBPAYMENTVIEW MODEL RECS
// TODO - MAKE SURE TO UPDATE AMPUSER, AMPUSERSUBSCRIBER RECS WITH NEW SUBSCRIPTION DATA
// TODO - UPDATE PROVIDERS TO CHECK IF STATUS IS PENDING,
//  DON'T TRY TO GET PAYMENT VIEW DATA
export async function createTempUserSubOnPaymentSuccess(ampUserId, ampUserSubscriberId, showLogs) {
    if (showLogs) {
        console.log('createTempUserSubOnPaymentSuccess ampUserId', ampUserId);
        console.log('createTempUserSubOnPaymentSuccess ampUserSubscriberId', ampUserSubscriberId);
    }
    const newTempId = createRecTempId();
    const newRouterPath = modelRouterPathAttrFactory(newTempId);
    const nowTS = Date.now();
    const tempSubPeriodMS = msSubMap.week;
    const tempSubEndTS = nowTS + tempSubPeriodMS;
    const resObj = {};
    try {
        const tempUserSubRec = await DataStore.save(
            new UserSub({
                id: newTempId,
                ampuserID: ampUserId,
                ampusersubscriberID: ampUserSubscriberId,
                userSubStatus: UserSubStatus.PENDING,
                userStatus: UserStatus.PENDING,
                subscriptionType: SubscriptionType.PENDING,
                routerPath: newRouterPath,
                begin: nowTS,
                end: tempSubEndTS,
            })
        );
        if (showLogs) {
            console.log('createTempUserSubOnPaymentSuccess tempUserSubRec', tempUserSubRec);
        }
        resObj.success = true;
        resObj.newRec = tempUserSubRec;
        return resObj;
    }
    catch (err) {
        console.error('createTempUserSubOnPaymentSuccess error', err);
        resObj.success = false;
        resObj.error = err;
    }
}