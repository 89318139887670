import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "../cards/StyledListCard";
import { useNavigate } from "react-router-dom";
import StyledAccordion from "./StyledAccordion";
import Divider from "@mui/material/Divider";
import {toTitleCase} from "../../utils/text";
import BaseNavigateButton from "../links/BaseNavigateButton";
import BaseStyledDeleteButton from "../buttons/BaseStyledDeleteButton";
import Box from "@mui/material/Box";

// import ConfirmDeleteAnimalDialog from "../modals/ConfirmDeleteAnimalDialog";

// TODO - UPDATE STYLING TO REMAIN CENTER ALIGNED ON ACCORDION OPEN

export default function ClientAnimalsActionsAccordion(props) {
    const [clientRec, setClientRec] = useState(null);
    const [animalRecs, setAnimalRecs] = useState([]);
    const [accordionHeaderText, setAccordionHeaderText] = useState('');

    let navigate = useNavigate();

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props?.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( setAnimalRecs(props.animals);
    useEffect(() => {
        if (props?.animals) {
            setAnimalRecs(props.animals);
        }
    }, [props.animals]);

    // useEffect( setAccordionHeaderText(props.headerText);
    useEffect(() => {
        if (props?.headerText) {
            setAccordionHeaderText(props.headerText);
        }
    }, [props.headerText]);

    return (
        <StyledAccordion
            sx={{
                marginTop: '1rem',
                marginBottom: '1rem !important',
                width: '90%',  // Set the width to 90% of the parent container
                marginLeft: 'auto',  // Center the accordion horizontally
                marginRight: 'auto',
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StyledCardContentTypography
                    text={accordionHeaderText || 'Animals:'}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {animalRecs.map((animalRec, index) => (
                        <div key={`${accordionHeaderText}-${animalRec.id}-${index}`}>
                            <StyledListCard>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                    sx={{ ...props.customsx, padding: '0.5rem 0' }}
                                >
                                    <StyledCardContentTypography
                                        text={`${animalRec?.name ? toTitleCase(animalRec.name) : '-'}`}
                                        sx={{
                                            flexGrow: 1,  // Allows the text to take up available space
                                            marginRight: '1rem',  // Space between the text and buttons
                                            whiteSpace: 'nowrap',  // Prevents text from wrapping to the next line
                                            overflow: 'hidden',  // Hides any overflow text
                                            textOverflow: 'ellipsis',  // Adds "..." at the end of the truncated text
                                            textAlign: 'left',  // Ensures text is aligned to the left
                                        }}
                                        onClick={() => {
                                            navigate(`/animal-info/${animalRec?.routerPath}`, {state: {animalRec}});
                                        }}
                                    />
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={0.5}
                                    >
                                        <BaseNavigateButton
                                            label="Edit"
                                            navTarget={`/edit-animal/${animalRec.routerPath}`}
                                            navState={{animalRec, clientRec}}
                                            customsx={{
                                                padding: '0.25rem 0.75rem',
                                                margin: '0 0.5rem 0 0',
                                            }}
                                        />
                                        <BaseStyledDeleteButton
                                            label="-"
                                            customsx={{
                                                padding: '0.25rem 0.75rem',
                                                margin: '0',
                                            }}
                                            onClick={() => {
                                                console.log('Delete animalRec', animalRec);
                                                props.setAnimalRecToDelete(animalRec);
                                                props.setDisplayDeleteAnimalModal(true);
                                            }}
                                        />
                                    </Box>
                                </Box>

                            </StyledListCard>
                            <Divider />
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
}
