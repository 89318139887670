import React, {useState, useEffect, useContext} from 'react';
import {useNavigate} from "react-router-dom";
import ApptShoeTasksContext from "../data/contexts/ApptShoeTasksContext";
import ApptTrimTasksContext from "../data/contexts/ApptTrimTasksContext";
import ApptNotesContext from "../data/contexts/ApptNotesContext";
import AmpUserApptAnimalsContext from "../data/contexts/AmpUserApptAnimalsContext";
import AmpUserApptsContext from "../data/contexts/AmpUserApptsContext";
import ClientAnimalsContext from "../data/contexts/ClientAnimalsContext";
import EditApptForm from "../components/forms/edit/EditApptForm";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesApptsScreen} from "../utils/breadcrumbsRoutes";


// TODO - START HERE, A LOT OF THESE STATE VALS ARE NOT USED AND SHOULD BE IN THE FORM,
//  VERIFY VALUES BEING PASSED DOWN TO FORM
export default function WrappedEditAppt(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [routerPaths, setRouterPaths] = useState({});
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])

    const [apptRec, setApptRec] = useState(null);
    const [apptClientRec, setApptClientRec] = useState(null);

    // these are animals for this appt, not the related ApptAnimal recs
    const [apptAnimals, setApptAnimals] = useState([]);
    const [apptAnimalRecs, setApptAnimalRecs] = useState([]);
    const [apptClientAnimals, setApptClientAnimals] = useState([]);
    const [apptNotes, setApptNotes] = useState([]);
    const [apptShoeTasks, setApptShoeTasks] = useState([]);
    const [apptTrimTasks, setApptTrimTasks] = useState([]);
    const [tasksByAnimalId, setTasksByAnimalId] = useState({});

    const [shoeTasksByAnimal, setShoeTasksByAnimal] = useState({});
    const [trimTasksByAnimal, setTrimTasksByAnimal] = useState({});
    const [animalByShoeTask, setAnimalByShoeTask] = useState({});
    const [animalByTrimTask, setAnimalByTrimTask] = useState({});

    const { apptShoeTasksRecs, shoeTaskRecsByAnimalId, animalRecsByShoeTaskId } = useContext(ApptShoeTasksContext);
    const { apptTrimTasksRecs, trimTaskRecsByAnimalId, animalRecsByTrimTaskId } = useContext(ApptTrimTasksContext);
    const { apptNotesRecs } = useContext(ApptNotesContext);
    const { ampUserApptAnimalsRecs, animalRecs, animalsRecsByApptId } = useContext(AmpUserApptAnimalsContext);
    const { ampUserApptRec, clientRecsByApptId } = useContext(AmpUserApptsContext);
    const { ampUserClientAnimalsRecs, shoePackageRecsByAnimalId } = useContext(ClientAnimalsContext);

    const [pauseDataForFormSubmit, setPauseDataForFormSubmit] = useState(false);

    const navigate = useNavigate();

    // // setBreadcrumbRoutes
    useEffect(() => {
        // if (apptRec) {
        //     setBreadcrumbRoutes(breadcrumbRoutesEditApptScreen(apptRec.id) || [])
        // }
        setBreadcrumbRoutes(breadcrumbRoutesApptsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    // }, [props, apptRec]);
    }, [props]);

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setRouterPaths(props.routerPaths);
    useEffect(() => {
        if (props?.routerPaths) {
            setRouterPaths(props.routerPaths);
        }
    }, [props.routerPaths]);

    // useEffect( setShowLogs( props.showLogs
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    // useEffect( setApptRec( ampUserApptRec
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            if (ampUserApptRec) {
                setApptRec(ampUserApptRec);
            }
        }
    }, [ampUserApptRec, pauseDataForFormSubmit]);

    // useEffect( setApptClientRec(clientRec);
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            if (clientRecsByApptId && apptRec) {
                const clientRecArr = clientRecsByApptId[apptRec.id];
                if (clientRecArr && clientRecArr.length > 0) {
                    setApptClientRec(clientRecArr[0]);
                }
            }
        }
    }, [clientRecsByApptId, apptRec, pauseDataForFormSubmit]);

    // useEffect( setApptNotes(apptNotesRecs);
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            if (apptNotesRecs) {
                setApptNotes(apptNotesRecs);
            }
        }
    }, [apptNotesRecs, pauseDataForFormSubmit]);

    // useEffect( setApptAnimals(animalRecs);
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            if (animalRecs) {
                setApptAnimals(animalRecs);
            }
        }
    }, [animalRecs, pauseDataForFormSubmit]);

    // useEffect( setApptAnimalRecs(ampUserApptAnimalsRecs);
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            if (ampUserApptAnimalsRecs) {
                setApptAnimalRecs(ampUserApptAnimalsRecs);
            }
        }
    }, [pauseDataForFormSubmit, ampUserApptAnimalsRecs]);

    // useEffect( console.log( showLogs, apptAnimals, apptClientAnimals, apptAnimalRecs
    // useEffect(() => {
    //     if (showLogs) {
    //         console.warn('apptAnimals', apptAnimals);
    //         console.warn('apptClientAnimals', apptClientAnimals);
    //         console.warn('apptAnimalRecs', apptAnimalRecs);
    //     }
    // }, [showLogs, apptAnimals, apptClientAnimals, apptAnimalRecs]);


    // useEffect( setApptClientAnimals(ampUserClientAnimalsRecs);
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            if (ampUserClientAnimalsRecs) {
                setApptClientAnimals(ampUserClientAnimalsRecs);
            }
        }
    }, [ampUserClientAnimalsRecs, pauseDataForFormSubmit]);

    // useEffect( console.log(ampUser, apptRec, apptClientRec, apptNotes, apptAnimals, apptClientAnimals
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUser', ampUser);
    //         console.log('apptRec', apptRec);
    //         console.log('apptClientRec', apptClientRec);
    //         console.log('apptNotes', apptNotes);
    //         console.log('apptAnimals', apptAnimals);
    //         console.log('apptClientAnimals', apptClientAnimals);
    //     }
    // }, [showLogs, ampUser, apptRec, apptClientRec, apptNotes, apptAnimals, apptClientAnimals]);

    // useEffect( setApptShoeTasks(apptShoeTasksRecs); setShoeTasksByAnimal(shoeTaskRecsByAnimalId);
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            if (apptShoeTasksRecs) {
                setApptShoeTasks(apptShoeTasksRecs);
            }
            if (shoeTaskRecsByAnimalId) {
                setShoeTasksByAnimal(shoeTaskRecsByAnimalId);
            }
            if (animalRecsByShoeTaskId) {
                setAnimalByShoeTask(animalRecsByShoeTaskId);
            }
        }
    }, [apptShoeTasksRecs, shoeTaskRecsByAnimalId, animalRecsByShoeTaskId, pauseDataForFormSubmit]);

    // useEffect( setApptTrimTasks(apptTrimTasksRecs); setTrimTasksByAnimal(trimTaskRecsByAnimalId);
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            if (apptTrimTasksRecs) {
                setApptTrimTasks(apptTrimTasksRecs);
            }
            if (trimTaskRecsByAnimalId) {
                setTrimTasksByAnimal(trimTaskRecsByAnimalId);
            }
            if (animalRecsByTrimTaskId) {
                setAnimalByTrimTask(animalRecsByTrimTaskId);
            }
        }
    }, [apptTrimTasksRecs, trimTaskRecsByAnimalId, animalRecsByTrimTaskId], pauseDataForFormSubmit);

    // useEffect( setTasksByAnimalId(tasksByAnimal);
    useEffect(() => {
        if (!pauseDataForFormSubmit) {
            const tasksByAnimal = {
                shoe: {},
                trim: {},
            };
            for (const shoeTask of apptShoeTasks) {
                if (shoeTask.animalID in tasksByAnimal.shoe) {
                    tasksByAnimal.shoe[shoeTask.animalID].push(shoeTask);
                } else {
                    tasksByAnimal.shoe[shoeTask.animalID] = [shoeTask];
                }
            }
            for (const trimTask of apptTrimTasks) {
                if (trimTask.animalID in tasksByAnimal.trim) {
                    tasksByAnimal.trim[trimTask.animalID].push(trimTask);
                } else {
                    tasksByAnimal.trim[trimTask.animalID] = [trimTask];
                }
            }
            setTasksByAnimalId(tasksByAnimal);
        }
    }, [apptShoeTasks, apptTrimTasks, pauseDataForFormSubmit]);

    // useEffect( console.log( apptShoeTasks, apptTrimTasks, shoeTasksByAnimal, trimTasksByAnimal, animalByShoeTask, animalByTrimTask
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ApptInfoScreen [apptShoeTasks]', apptShoeTasks);
    //         console.log('ApptInfoScreen [apptTrimTasks]', apptTrimTasks);
    //         console.log('ApptInfoScreen [shoeTasksByAnimal]', shoeTasksByAnimal);
    //         console.log('ApptInfoScreen [trimTasksByAnimal]', trimTasksByAnimal);
    //         console.log('ApptInfoScreen [animalByShoeTask]', animalByShoeTask);
    //         console.log('ApptInfoScreen [animalByTrimTask]', animalByTrimTask);
    //         console.log('ApptInfoScreen [tasksByAnimalId]', tasksByAnimalId);
    //     }
    // }, [showLogs, apptShoeTasks, apptTrimTasks, shoeTasksByAnimal, trimTasksByAnimal, animalByShoeTask, animalByTrimTask, tasksByAnimalId]);

    // useEffect( console.log( apptShoeTasks, apptTrimTasks, shoeTasksByAnimal, trimTasksByAnimal, animalByShoeTask, animalByTrimTask
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ApptInfoScreen [apptShoeTasks]', apptShoeTasks);
    //         console.log('ApptInfoScreen [apptTrimTasks]', apptTrimTasks);
    //         console.log('ApptInfoScreen [shoeTasksByAnimal]', shoeTasksByAnimal);
    //         console.log('ApptInfoScreen [trimTasksByAnimal]', trimTasksByAnimal);
    //         console.log('ApptInfoScreen [animalByShoeTask]', animalByShoeTask);
    //         console.log('ApptInfoScreen [animalByTrimTask]', animalByTrimTask);
    //         console.log('ApptInfoScreen [tasksByAnimalId]', tasksByAnimalId);
    //     }
    // }, [showLogs, apptShoeTasks, apptTrimTasks, shoeTasksByAnimal, trimTasksByAnimal, animalByShoeTask, animalByTrimTask, tasksByAnimalId]);

    return (
        <div style={{
            backgroundColor: 'lightgray',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            // color: 'white',
            color: 'black',
            marginBottom: '0.5vh'
        }}>
            <h2>Edit Appointment</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <EditApptForm
                ampUser={ampUser}
                showLogs={showLogs}
                apptRec={apptRec}
                apptClientRec={apptClientRec}
                apptNotes={apptNotes}
                apptAnimals={apptAnimals}
                apptAnimalRecs={apptAnimalRecs}
                apptClientAnimals={apptClientAnimals}
                apptShoeTasks={apptShoeTasks}
                apptTrimTasks={apptTrimTasks}
                tasksByAnimalId={tasksByAnimalId}
                animalByShoeTask={animalByShoeTask}
                animalByTrimTask={animalByTrimTask}
                setPauseDataForFormSubmit={setPauseDataForFormSubmit}
                pauseDataForFormSubmit={pauseDataForFormSubmit}
                // routerPaths={props?.routerPaths}
            />
        </div>
    )
}
