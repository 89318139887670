import React, {useState, useEffect} from 'react';
import {DataStore, SortDirection} from "aws-amplify";
import {TrimTask} from "../../models";
import AnimalTrimTasksContext from "../contexts/AnimalTrimTasksContext";

const AnimalTrimTasksProvider = ({children, animalId, showLogs, resetProviderState}) => {
    const [animalTrimTasksRecs, setAnimalTrimTasksRecs] = useState([]);
    const [animalTrimTasksShowLogs, setAnimalTrimTasksShowLogs] = useState(false);
    const [trimTasksAnimalRecId, setTrimTasksAnimalRecId] = useState(null);

    // useEffect( reset data, based on resetProviderState
    useEffect(() => {
        if (resetProviderState) {
            setTrimTasksAnimalRecId(null);
            setAnimalTrimTasksRecs([]);
        }
    }, [resetProviderState]);

    // useEffect( setAnimalTrimTasksShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setAnimalTrimTasksShowLogs(showLogs);
        } else {
            setAnimalTrimTasksShowLogs(false);
        }
    }, [showLogs]);

    useEffect(() => {
        if (animalId && !resetProviderState) {
            const subscription = DataStore.observeQuery(TrimTask,
                (c) => c.animalID.eq(animalId),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalTrimTasksShowLogs) {
                        console.log(`AnimalTrimTasksProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('AnimalTrimTasksProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalTrimTasksRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [animalId, animalTrimTasksShowLogs, resetProviderState])

    useEffect(() => {
        if (trimTasksAnimalRecId && !resetProviderState) {
            const subscription = DataStore.observeQuery(TrimTask,
                (c) => c.animalID.eq(trimTasksAnimalRecId),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalTrimTasksShowLogs) {
                        console.log(`AnimalTrimTasksProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('AnimalTrimTasksProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalTrimTasksRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [trimTasksAnimalRecId, animalTrimTasksShowLogs, resetProviderState]);

    return (
        <AnimalTrimTasksContext.Provider value={{
            animalTrimTasksRecs,
            setAnimalTrimTasksRecs,
            setTrimTasksAnimalRecId
        }}
        >
            {children}
        </AnimalTrimTasksContext.Provider>
    );
}

export default AnimalTrimTasksProvider;