import React, {useState, useEffect} from 'react';
import DevStateProvider from "../../data/providers/DevStateProvider";
import {RequireAuth} from "../auth/RequireAuth";
import {useLocation} from "react-router-dom";
import ApplyForUserSubScreen from "../../screens/ApplyForUserSubScreen";

export default function WrappedApplyForUserSub({ routerPaths, ampUser, showLogs }) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [locationState, setLocationState] = useState(null);

    let location = useLocation();

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUser) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    // useEffect( setLocationState(location.state);
    useEffect(() => {
        if (location.state) {
            setLocationState(location.state);
        }
    }, [location.state]);

    // useEffect( console.log( locationState
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('WrappedApplyForUserSub locationState', locationState);
    //     }
    // }, [locationState, showLogs]);

    // useEffect( console.log( ampUserRec
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ampUserRec', ampUserRec);
    //     }
    // }, [ampUserRec, showLogs]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUserRec ? (
                    <ApplyForUserSubScreen
                        routerPaths={routerPaths}
                        showLogs={showLogs}
                        ampUser={ampUserRec}
                        locationState={locationState}
                    />
                ) : (
                    <div>
                        <h3>Apply For User Subscription</h3>
                        <p>Loading User Data...</p>
                    </div>
                )}
            </DevStateProvider>
        </RequireAuth>
    );
}
