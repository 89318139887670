import {DataStore} from "aws-amplify";
import { Client } from "../../../models";
import { modelRouterPathAttrFactory } from "../../../utils/breadcrumbsRoutes";
import { getNowAsAWSDateTime } from "../../../utils/datesTimes";


export async function updateClientRec(ampUserRec, clientId, clientFormValsDiff, showLogs) {
    console.log('updateClientRec(clientId, clientFormValsDiff)', clientId, clientFormValsDiff);
    try {
        const original = await DataStore.query(Client, clientId);
        // Check if the client exists
        if (!original) {
            console.error("Client not found");
            return {success: false, error: "Client not found"};
        }
        if (!original.routerPath) {
            const clientRouterPath = modelRouterPathAttrFactory(`${ampUserRec.name}-${clientId}`);
            clientFormValsDiff.routerPath = clientRouterPath;
        }
        clientFormValsDiff.lastEditedAt = getNowAsAWSDateTime();
        clientFormValsDiff.isEdited = true;
        
        const updatedClient = await DataStore.save(
            Client.copyOf(original, updated => {
                Object.keys(clientFormValsDiff).forEach(key => {
                    // Update the corresponding field in the Client model
                    updated[key] = clientFormValsDiff[key];
                });
            })
        );
        return {updatedClient, success: true};
    } catch (error) {
        console.error('updateClientRec error', error);
        return {success: false, error: error};
    }
}
