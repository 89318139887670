import React, {useState, useEffect} from 'react';
import {RequireAuth} from '../auth/RequireAuth';
import DevStateProvider from "../../data/providers/DevStateProvider";
import AmpUserClientsDataProvider from "../../data/providers/AmpUserClientsDataProvider";
import CreateApptScreen from "../../screens/CreateApptScreen";
import {useLocation} from "react-router-dom";
import ClientApptsDataProvider from "../../data/providers/ClientApptsDataProvider";
import {RequireSub} from "../auth/RequireSub";

const WrappedCreateAppt = ({routerPaths, ampUser, showLogs, resetProvidersStates}) => {
    const [animalRec, setAnimalRec] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [localShowLogs, setLocalShowLogs] = useState(false);

    const location = useLocation();
    const locationAnimalRec = location.state?.animalRec;
    const locationClientRec = location.state?.clientRec;

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( setAnimalRec(locationAnimalRec) setClientRec(locationClientRec);
    useEffect(() => {
        if (locationAnimalRec) {
            setAnimalRec(locationAnimalRec);
        }
        if (locationClientRec) {
            setClientRec(locationClientRec);
        }
    }, [location]);

    // useEffect( console.log( location, animalRec, clientRec
    useEffect(() => {
        if (localShowLogs) {
            console.log('WrappedCreateAppt location', location);
            console.log('WrappedCreateAppt animalRec', animalRec);
            console.log('WrappedCreateAppt clientRec', clientRec);
        }
    }, [localShowLogs, location, animalRec, clientRec]);

    return (
        <DevStateProvider>
            <RequireAuth>
                {ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        <AmpUserClientsDataProvider ampUserId={ampUser.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                            <CreateApptScreen
                                routerPaths={routerPaths}
                                ampUser={ampUser}
                                showLogs={showLogs}
                                animalRec={animalRec}
                                clientRec={clientRec}
                            />
                        </AmpUserClientsDataProvider>
                    </RequireSub>
                ) : (
                    <h2>Loading Data...</h2>
                )}
            </RequireAuth>
        </DevStateProvider>
    );
}

export default WrappedCreateAppt;
