import React, { useEffect, useMemo } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HomeScreenClientsCard from "../cards/HomeScreenClientsCard";
import HomeScreenAnimalsCard from "../cards/HomeScreenAnimalsCard";
import HomeScreenApptsCard from "../cards/HomeScreenApptsCard";
import StyledListBox from "../boxes/StyledListBox";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import defaultPic from "../../static/images/default-running-horse.jpg";


export default function HomeCardsList(props) {
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // Preload images
    useEffect(() => {
        // const preloadImages = [clientsPic, animalsPic, apptsPic];
        const preloadImages = [defaultPic];
        preloadImages.forEach((src) => {
            const img = new Image();
            img.src = src;
        });
    }, []);

    // Memoize the list items
    const listItems = useMemo(() => [
        <ListItem key="clients-card">
            <HomeScreenClientsCard />
        </ListItem>,
        <ListItem key="animals-card">
            <HomeScreenAnimalsCard />
        </ListItem>,
        <ListItem key="appts-card">
            <HomeScreenApptsCard />
        </ListItem>
    ], []);

    return (
        <StyledListBox
            width={isLargeScreen ? '90%' : '100%'}
        >
            <nav aria-label="home cards list">
                <List sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {listItems}
                </List>
            </nav>
        </StyledListBox>
    );
}
