import {DataStore} from "aws-amplify";
import {ClientNote} from "../../../models";
import {modelRouterPathAttrFactory} from "../../../utils/breadcrumbsRoutes";

export async function createClientNote(clientId, note, showLogs) {
    const resObj = {};
    try {
        const newClientNote = await DataStore.save(
            new ClientNote({
                note: note,
                clientID: clientId,
                isEdited: false,
                routerPath: modelRouterPathAttrFactory(`${clientId}`)
            })
        );
        if (showLogs) {
            console.log("createClientNote.js: newClientNote: ", newClientNote);
        }
        resObj.success = true;
        resObj.newClientNote = newClientNote;
        return resObj;
    }
    catch (error) {
        console.error("createClientNote.js: error: ", error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return newClientNote;
}
