import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledAccordion from "./StyledAccordion";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "../cards/StyledListCard";
import Divider from "@mui/material/Divider";


export default function ShoePackagesAccordion(props) {
    const [shoePackages, setShoePackages] = useState([]);
    const [accordionHeaderText, setAccordionHeaderText] = useState('');

    // useEffect( setShoePackages(props.shoePackages);
    useEffect(() => {
        if (props?.shoePackages) {
            setShoePackages(props.shoePackages);
        }
    }, [props.shoePackages]);

    // useEffect( setAccordionHeaderText(props.headerText);
    useEffect(() => {
        if (props?.headerText) {
            setAccordionHeaderText(props.headerText);
        }
    }, [props.headerText]);

    return (
        <StyledAccordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StyledCardContentTypography
                    text={accordionHeaderText || 'Shoe Packages:'}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {shoePackages.map((shoePackage, index) => {
                        return (
                            <div key={`${accordionHeaderText}-${shoePackage.id}-${index}`}>
                                <StyledListCard>
                                    <StyledCardContentTypography
                                        text={`Created at: ${shoePackage.createdAt}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Front Left: ${shoePackage.frontLeft}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Front Right: ${shoePackage.frontRight}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Rear Left: ${shoePackage.rearLeft}`}
                                    />
                                    <StyledCardContentTypography
                                        text={`Rear Right: ${shoePackage.rearRight}`}
                                    />
                                </StyledListCard>
                                <Divider />
                            </div>
                        )
                    })}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
}
