import React, { useMemo } from "react";
import HomeCardsList from "../components/lists/HomeCardsList";
import WrappedWaitlistUserCreateForm from "../components/forms/create/WrappedWaitlistUserCreateForm"; // Modify the import path to point to the wrapper component
import {deployState} from "../utils/consts";

function HomeScreen() {
    const memoizedHomeCardsList = useMemo(() => <HomeCardsList />, []);

    return(
        <div className={"home-screen-cards"}>
            {deployState === 'signup' ? <WrappedWaitlistUserCreateForm onSuccess={() => {
                alert('Added to the Fishingigs wait list successfully!');
            }
            }/> : null}
            <div>
                <h2>Home</h2>
            </div>
            <div>
                {memoizedHomeCardsList}
            </div>
        </div>
    );
}

export default React.memo(HomeScreen);
