// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserSubStatus = {
  "LIVE": "LIVE",
  "EXPIRED": "EXPIRED",
  "PENDING": "PENDING",
  "CANCELED": "CANCELED",
  "DELETED": "DELETED",
  "ARCHIVED": "ARCHIVED"
};

const DiscountPeriod = {
  "YEAR": "YEAR",
  "QUARTER": "QUARTER",
  "MONTH": "MONTH",
  "SUBEXPIRY": "SUBEXPIRY"
};

const DiscountType = {
  "PERCENT": "PERCENT",
  "AMOUNT": "AMOUNT",
  "TIME": "TIME"
};

const AmpUserType = {
  "FARRIER": "FARRIER",
  "CLIENT": "CLIENT",
  "OWNER": "OWNER",
  "ADMIN": "ADMIN",
  "SUPER_ADMIN": "SUPER_ADMIN"
};

const ClientPaymentType = {
  "PAYPAL": "PAYPAL",
  "CASH": "CASH",
  "CHECK": "CHECK",
  "CRYPTO": "CRYPTO",
  "CARD": "CARD",
  "VENMO": "VENMO",
  "CASHAPP": "CASHAPP",
  "ZELLE": "ZELLE"
};

const ClientPaymentStatus = {
  "PAID": "PAID",
  "UNPAID": "UNPAID"
};

const AnimalStatus = {
  "NEW": "NEW",
  "LEASE": "LEASE",
  "DECEASED": "DECEASED",
  "SOLD": "SOLD",
  "SICK": "SICK",
  "INJURED": "INJURED",
  "EXISTING": "EXISTING"
};

const AnimalType = {
  "HORSE": "HORSE",
  "PONY": "PONY",
  "DONKEY": "DONKEY",
  "MULE": "MULE",
  "OTHER": "OTHER"
};

const SubPaymentStatus = {
  "LIVE": "LIVE",
  "EXPIRED": "EXPIRED",
  "PENDING": "PENDING",
  "CANCELLED": "CANCELLED",
  "DELETED": "DELETED"
};

const UserStatus = {
  "FREE": "FREE",
  "SUBSCRIBER": "SUBSCRIBER",
  "REMOVED": "REMOVED",
  "DELETED": "DELETED",
  "PENDING": "PENDING",
  "EXPIRED": "EXPIRED"
};

const AppointmentStatus = {
  "PENDING": "PENDING",
  "DONE": "DONE",
  "NOW": "NOW",
  "CANCELLED": "CANCELLED",
  "RESCHEDULED": "RESCHEDULED"
};

const SubscriptionType = {
  "YEAR": "YEAR",
  "QUARTER": "QUARTER",
  "MONTH": "MONTH",
  "PENDING": "PENDING"
};

const ClientStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DELETED": "DELETED"
};

const WaitlistUserStatus = {
  "SUBSCRIBED": "SUBSCRIBED",
  "UNSUBSCRIBED": "UNSUBSCRIBED",
  "ACTIVATED": "ACTIVATED"
};

const { PublicAnimal, PublicClient, SubPaymentView, UserSubCoupon, AmpUserSubscriber, ShoeTask, TrimTask, ClientPayment, ShoePackage, Animal, AnimalOwnerNote, AnimalNote, Owner, ClientNote, AppointmentNote, ApptPic, Appointment, SubPayment, UserSub, Client, AmpUser, WaitlistUser, UserSubUserSubCoupon, AppointmentAnimal } = initSchema(schema);

export {
  PublicAnimal,
  PublicClient,
  SubPaymentView,
  UserSubCoupon,
  AmpUserSubscriber,
  ShoeTask,
  TrimTask,
  ClientPayment,
  ShoePackage,
  Animal,
  AnimalOwnerNote,
  AnimalNote,
  Owner,
  ClientNote,
  AppointmentNote,
  ApptPic,
  Appointment,
  SubPayment,
  UserSub,
  Client,
  AmpUser,
  WaitlistUser,
  UserSubUserSubCoupon,
  AppointmentAnimal,
  UserSubStatus,
  DiscountPeriod,
  DiscountType,
  AmpUserType,
  ClientPaymentType,
  ClientPaymentStatus,
  AnimalStatus,
  AnimalType,
  SubPaymentStatus,
  UserStatus,
  AppointmentStatus,
  SubscriptionType,
  ClientStatus,
  WaitlistUserStatus
};