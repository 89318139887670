import Button from '@mui/material/Button';

const StyledHeaderButton = (props) => {
    return (
        <Button
            {...props}
            // variant="outlined"
            size={'large'}
            sx={{
                // backgroundColor: '#5F9EA0',
                // borderColor: '#5F9EA0',
                color: 'white',
                marginLeft: {
                    xs: '0.25rem',  // Assuming base font-size is 16px, this is 4px
                    sm: '0.5rem',   // 8px
                    md: '1rem',     // 16px
                    lg: '1.5rem',   // 24px
                    xl: '2rem'      // 32px
                },
                textTransform: 'none', // Maintain the original casing
                fontSize: ['5vw', '4vw', '2vw', '1.5vw'], // Responsive font sizes, smallest viewport to largest
                '&:hover': {
                    backgroundColor: '#507D7E',  // A darker shade for hover
                },
            }}
        >
            {props.children}
        </Button>
    );
};

export default StyledHeaderButton;
