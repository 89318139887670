import React, {useState, useEffect} from 'react';
import {ShoePackage} from "../../models";
import { DataStore, SortDirection } from "aws-amplify";
import AnimalShoePackagesContext from "../contexts/AnimalShoePackagesContext";

const AnimalShoePackagesProvider = ({ children, animalId, showLogs, resetProviderState }) => {
    const [animalShoePackagesRecs, setAnimalShoePackagesRecs] = useState([]);
    const [animalShoePackagesShowLogs, setAnimalShoePackagesShowLogs] = useState(false);
    const [animalDetailsAnimalId, setAnimalDetailsAnimalId] = useState(null);

    // useEffect( setAnimalShoePackagesShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setAnimalShoePackagesShowLogs(showLogs);
        } else {
            setAnimalShoePackagesShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( reset state when resetProviderState is true
    useEffect(() => {
        if (resetProviderState) {
            setAnimalShoePackagesRecs([]);
            setAnimalDetailsAnimalId(null);
        }
    }, [resetProviderState]);

    // useEffect(() => {
    //     if (animalId) {
    //         const subscription = DataStore.observeQuery(ShoePackage,
    //             (c) => c.animalID.eq(animalId),
    //             {
    //                 sort: s => s.createdAt(SortDirection.DESCENDING)
    //             })
    //             .subscribe(async msg => {
    //                 const {items, isSynced} = msg;
    //                 if (animalShoePackagesShowLogs) {
    //                     console.log(`AnimalShoePackagesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     console.log('AnimalShoePackagesProvider c [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     setAnimalShoePackagesRecs(items);
    //                 }
    //             });
    //         // Remember to unsubscribe when the component unmounts
    //         return () => subscription.unsubscribe();
    //     }
    // }, [animalId, animalShoePackagesShowLogs])

    useEffect(() => {
        if (animalId) {
            const subscription = DataStore.observeQuery(ShoePackage,
                (c) => c.and(
                    c => [
                    c.animalID.eq(animalId),
                    c.isDeleted.ne(true)
                    ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalShoePackagesShowLogs) {
                        console.log(`AnimalShoePackagesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('AnimalShoePackagesProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalShoePackagesRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [animalId, animalShoePackagesShowLogs])

    // useEffect(() => {
    //     if (animalDetailsAnimalId) {
    //         const subscription = DataStore.observeQuery(ShoePackage,
    //             (c) => c.animalID.eq(animalDetailsAnimalId),
    //             {
    //                 sort: s => s.createdAt(SortDirection.DESCENDING)
    //             })
    //             .subscribe(async msg => {
    //                 const {items, isSynced} = msg;
    //                 if (animalShoePackagesShowLogs) {
    //                     console.log(`AnimalShoePackagesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
    //                     console.log('AnimalShoePackagesProvider c [Snapshot] items', items);
    //                 }
    //                 if (isSynced) {
    //                     setAnimalShoePackagesRecs(items);
    //                 }
    //             });
    //         // Remember to unsubscribe when the component unmounts
    //         return () => subscription.unsubscribe();
    //     }
    // }, [animalDetailsAnimalId, animalShoePackagesShowLogs])

    useEffect(() => {
        if (animalDetailsAnimalId) {
            const subscription = DataStore.observeQuery(ShoePackage,
                (c) => c.and(
                    c => [
                    c.animalID.eq(animalDetailsAnimalId),
                    c.isDeleted.ne(true)
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalShoePackagesShowLogs) {
                        console.log(`AnimalShoePackagesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('AnimalShoePackagesProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalShoePackagesRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [animalDetailsAnimalId, animalShoePackagesShowLogs])

    return (
        <AnimalShoePackagesContext.Provider value={{animalShoePackagesRecs, setAnimalShoePackagesRecs, setAnimalDetailsAnimalId}}>
            {children}
        </AnimalShoePackagesContext.Provider>
    );
}

export default AnimalShoePackagesProvider;
