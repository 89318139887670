import React, {useState, useEffect} from 'react';
import DevStateProvider from "../../data/providers/DevStateProvider";
import {RequireAuth} from "../auth/RequireAuth";
import {useLocation} from "react-router-dom";
import SubscribeSuccessScreen from "../../screens/SubscribeSuccessScreen";
import UserSubsProvider from "../../data/providers/UserSubProvider";
import SubPaymentViewProvider from "../../data/providers/SubPaymentViewProvider";

export default function WrappedSubscriptionSuccess({ routerPaths, ampUser, ampUserSubscriber, showLogs }) {
    const [ampUserRec, setAmpUserRec] = useState(null);
    const [ampUserSubscriberRec, setAmpUserSubscriberRec] = useState(null);
    const [locationState, setLocationState] = useState(null);

    let location = useLocation();

    // useEffect( setAmpUserRec(ampUser);
    useEffect(() => {
        if (ampUser) {
            setAmpUserRec(ampUser);
        }
    }, [ampUser]);

    // useEffect( setAmpUserSubscriberRec(ampUserSubscriber);
    useEffect(() => {
        if (ampUserSubscriber) {
            setAmpUserSubscriberRec(ampUserSubscriber);
        }
    }, [ampUserSubscriber]);

    // useEffect( setLocationState(location.state);
    useEffect(() => {
        if (location.state) {
            setLocationState(location.state);
        }
    }, [location.state]);

    // useEffect( console.log( locationState
    useEffect(() => {
        if (showLogs) {
            console.log('WrappedCreateUserSub locationState', locationState);
        }
    }, [locationState, showLogs]);

    // useEffect( console.log( ampUserRec, ampUserSubscriberRec
    useEffect(() => {
        if (showLogs) {
            console.log('ampUserRec', ampUserRec);
            console.log('ampUserSubscriberRec', ampUserSubscriberRec);
        }
    }, [ampUserRec, ampUserSubscriberRec, showLogs]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUserRec ? (
                    <SubPaymentViewProvider ampUserProp={ampUserRec} showLogs={showLogs}>
                        <UserSubsProvider ampUserProp={ampUserRec} showLogs={showLogs}>
                            <SubscribeSuccessScreen
                                routerPaths={routerPaths}
                                ampUser={ampUserRec}
                                ampUserSubscriber={ampUserSubscriberRec}
                                showLogs={showLogs}
                            />
                        </UserSubsProvider>
                    </SubPaymentViewProvider>
                ) : (
                    <h3>Loading User Info...</h3>
                )}
            </DevStateProvider>
        </RequireAuth>
    );
}
