import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "../cards/StyledListCard";
import { useNavigate } from "react-router-dom";
import StyledAccordion from "./StyledAccordion";

// TODO - FIX ISSUE WITH CUSTOM HEIGHTS AND ACCORDION NOT EXPANDING,
//  OR EXPANDING BEHIND OTHER ELEMENTS
export default function ApptsAccordion({ appts, headerText, clientRec, showLogs }) {
    const [apptRecs, setApptRecs] = useState([]);
    const [accordionHeaderText, setAccordionHeaderText] = useState('');

    let navigate = useNavigate();

    useEffect(() => {
        if (appts) {
            setApptRecs(appts);
        }
    }, [appts]);

    useEffect(() => {
        if (headerText) {
            setAccordionHeaderText(headerText);
        }
    }, [headerText]);

    return (
        <StyledAccordion
            sx={{
                backgroundColor: 'lightgray',
                // height: {
                //     xs: '100px',
                //     sm: '150px',
                //     md: '300px',
                //     lg: '400px',
                //     xl: '500px',
                // },
                // maxHeight: {
                //     xs: '150px',
                //     sm: '250px',
                //     md: '350px',
                //     lg: '450px',
                //     xl: '550px',
                // },
                // minHeight: {
                //     xs: '50px',
                //     sm: '150px',
                //     md: '150px',
                //     lg: '200px',
                //     xl: '250px',
                // },
            }}
            disabled={apptRecs && apptRecs?.length === 0}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h3>{accordionHeaderText || 'Appointments'}</h3>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {apptRecs.map((appt) => (
                        <div key={`${accordionHeaderText}-${appt.id}`}>
                            <StyledListCard
                                onClick={() => navigate(`/appt-info/${appt.routerPath}`, { state: { apptRec: appt, clientRec } })}
                            >
                                <StyledCardContentTypography text={`Date: ${appt.apptDate}`} />
                            </StyledListCard>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
}
