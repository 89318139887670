import React, {useState, useEffect} from 'react';
import ApptNotesContext from '../contexts/ApptNotesContext';
import {AppointmentNote} from "../../models";
import { DataStore, SortDirection } from "aws-amplify";

function ApptNotesProvider({ children, apptId, showLogs }) {
    const [apptNotesRecs, setApptNotesRecs] = useState([]);
    const [localShowLogs, setLocalShowLogs] = useState(false);

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    useEffect(() => {
        if (apptId) {
            const subscription = DataStore.observeQuery(AppointmentNote,
                (c) => c.appointmentID.eq(apptId),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (localShowLogs) {
                        // console.log(`ApptNotesProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        // console.log('ApptNotesProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setApptNotesRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [apptId, localShowLogs])

    return (
        <ApptNotesContext.Provider value={{apptNotesRecs, setApptNotesRecs}}>
            {children}
        </ApptNotesContext.Provider>
    );
}

export default ApptNotesProvider;
