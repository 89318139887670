import React, {useState, useEffect} from 'react';
import {DataStore} from '@aws-amplify/datastore';
import {SortDirection} from "aws-amplify";
import {Appointment, Client} from '../../models';

const ApptByTaskContext = React.createContext(null);

const ApptByTaskProvider = ({children, showLogs, resetProviderState}) => {
    const [taskRecApptId, setTaskRecApptId] = useState(null);
    const [taskApptRec, setTaskApptRec] = useState(null);
    const [taskApptClientRec, setTaskApptClientRec] = useState({});
    const [taskApptAnimalRecs, setTaskApptAnimalRecs] = useState({});

    // useEffect( reset data, based on resetProviderState
    useEffect(() => {
        if (resetProviderState) {
            setTaskRecApptId(null);
            setTaskApptAnimalRecs([]);
        }
    }, [resetProviderState]);

    useEffect(() => {
        if (showLogs) {
            console.log('ApptByTaskProvider taskRecApptId', taskRecApptId);
        }
    }, [showLogs, taskRecApptId]);

    useEffect(() => {
        if (taskRecApptId && !resetProviderState) {
            const subscription = DataStore.observeQuery(Appointment,
                (a) => a.id.eq(taskRecApptId),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (showLogs) {
                        console.log(`ApptByTaskProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('ApptByTaskProvider a [Snapshot] items', items);
                    }
                    if (isSynced) {
                        if (items && items.length > 0) {
                            const apptRec = items[0];
                            setTaskApptRec(apptRec);
                            const clientRec = await DataStore.query(Client, apptRec.clientID);
                            setTaskApptClientRec(clientRec);
                            const relatedAnimalRecs = [];
                            const animals = await apptRec.Animals
                            for await (const animal of animals) {
                                relatedAnimalRecs.push(animal);
                            }
                            setTaskApptAnimalRecs(relatedAnimalRecs);
                        }
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [taskRecApptId, showLogs, resetProviderState]);


    return (
        <ApptByTaskContext.Provider value={{
            taskRecApptId,
            setTaskRecApptId,
            taskApptRec,
            setTaskApptRec,
            setTaskApptClientRec,
            setTaskApptAnimalRecs,
            taskApptClientRec,
            taskApptAnimalRecs
        }}
        >
            {children}
        </ApptByTaskContext.Provider>
    );
}

const useApptByTask = () => React.useContext(ApptByTaskContext);

export {ApptByTaskProvider, useApptByTask};
