import md5 from "md5";

export function modelRouterPathAttrFactory(inputString) {
    const modelRouterPathAttr = md5(`${inputString}-${Date.now()}`);
    return modelRouterPathAttr;
}

export const ROUTER_PATHS = [
    "clients",
    // "countries",
    "animals",
    "owners",
    "appointments",
    "/"
]
export const BASE_ROUTER_PATHS = [
    "client",
    "horse",
    "/"
]

export function fetchAllRouterPaths(recsObj) {
    const recsRouterPaths = recsObj['routerPaths']
    const nestedRouterPathsArr = []
    const nestedRouterPathsObj = {}
    // const parsedRecsObj = JSON.parse(JSON.stringify(recsObj))
    BASE_ROUTER_PATHS.forEach((eachPath) => {
        const routeNestedPaths = []
        if (recsRouterPaths[eachPath]) {
            const nestedPaths = recsRouterPaths[eachPath].map((modelRec) => {
                switch (eachPath) {
                    case 'region':
                        return modelRec.name
                    case 'country':
                        return modelRec.intlCode
                    default:
                        return '/'
                }
            })
            nestedPaths.forEach((eachNestedPath) => {
                nestedRouterPathsArr.push(`${eachPath}/${eachNestedPath}`)
                routeNestedPaths.push(eachNestedPath)
            })
            nestedRouterPathsObj[eachPath] = routeNestedPaths
        } else {
            nestedRouterPathsArr.push(`${eachPath}`)
        }
    })
    return nestedRouterPathsArr
}

export function routerPathsToNames(recsObj) {
    const retObj = {}
    BASE_ROUTER_PATHS.forEach((baseRouterPath) => {
        if (recsObj[baseRouterPath]) {
            const nestedPaths = recsObj[baseRouterPath].map((modelRec) => {
                switch (baseRouterPath) {
                    case 'region':
                        return {[modelRec.routerPath]: modelRec.name}
                    case 'gear-type':
                        return {[modelRec.routerPath]: modelRec.name}
                    case 'country':
                        return {[modelRec.routerPath]: modelRec.intlCode}
                    default:
                        return {'/': '/'}
                }
            })
            const pathNamesFactoryRes = pathNamesFactory(nestedPaths)
            retObj[baseRouterPath] = pathNamesFactoryRes
        }
    })
    // console.error('retObj', retObj)
    return retObj;
}

function pathNamesFactory(nestedPaths) {
    const retObj = {}
    nestedPaths.forEach((eachNestedPath) => {
        const eachNestedPathEntries = Object.entries(eachNestedPath)
        const entryParsed = JSON.parse(JSON.stringify(eachNestedPathEntries[0]))
        const parsedEntries = Object.entries(entryParsed)
        const parsedEntryKey = parsedEntries[0][1]
        const parsedEntryVal = parsedEntries[1][1]
        retObj[parsedEntryKey] = parsedEntryVal
    })
    return retObj
}

export function breadcrumbRoutesBase() {
    return [
        {
            name: 'home',
            breadcrumbRoute: '/'
        }
    ]
}

const routesBaseObj = {
    animals: {
        name: 'animals',
        breadcrumbRoute: '/animals'
    },
    appointments: {
        name: 'appointments',
        breadcrumbRoute: '/appointments'
    },
    clients: {
        name: 'clients',
        breadcrumbRoute: '/clients'
    },
    applyForSub: {
        name: 'subscription-details',
        breadcrumbRoute: '/apply-for-subscription',
    },
    createUserSub: {
        name: 'subscribe',
        breadcrumbRoute: '/subscribe',
    },
    regions: {
        name: 'regions',
        breadcrumbRoute: '/regions'
    },
    userPosts: {
        name: 'your-posts',
        breadcrumbRoute: '/user-posts'
    },
    pricing: {
        name: 'pricing',
        breadcrumbRoute: '/pricing'
    },
    termsOfUse: {
        name: 'terms of use',
        breadcrumbRoute: '/terms-of-use'
    },
    contactUs: {
        name: 'contact us',
        breadcrumbRoute: '/contact-us'
    },
    user: {
        name: 'user',
        breadcrumbRoute: '/user'
    },
    howItWorks: {
        name: 'how it works',
        breadcrumbRoute: '/how-it-works'
    },
    aboutUs: {
        name: 'about us',
        breadcrumbRoute: '/about-us'
    }
}

function breadcrumbRoutesBaseFactory(routeParam) {
    const routeObj = routesBaseObj[routeParam];
    const base = breadcrumbRoutesBase()
    return base.concat(routeObj);
}

export function breadcrumbRoutesApplyForUserSubScreen() {
    return breadcrumbRoutesBase()
}

export function breadcrumbRoutesCreateUserSubScreen() {
    return breadcrumbRoutesBaseFactory('applyForSub');
}

export function breadcrumbRoutesConfirmUserSubScreen() {
    return breadcrumbRoutesBaseFactory('createUserSub');
}

export function breadcrumbRoutesAnimalsScreen() {
    return breadcrumbRoutesBaseFactory('animals');
}

export function breadcrumbRoutesApptsScreen() {
    return breadcrumbRoutesBaseFactory('appointments');
}

export function breadcrumbRoutesClientsScreen() {
    return breadcrumbRoutesBaseFactory('clients');
}

export function breadcrumbRoutesUserAttributesScreen() {
    return breadcrumbRoutesBaseFactory('user');
}

export function breadcrumbRoutesEditApptsScreen() {
    return breadcrumbRoutesBaseFactory('appointments');
}

export function breadcrumbRoutesRegionsScreen() {
    return breadcrumbRoutesBaseFactory('regions')
}

export function breadcrumbRoutesSingleRegion(region) {
    const regions = breadcrumbRoutesRegionsScreen()
    const singleRegionRoutes = [{
        name: region,
        breadcrumbRoute: `/region/${region}`
    }]
    return regions.concat(singleRegionRoutes);
}
