import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js'
import {generateHash} from "../utils/helpers";
import {userSubDataObj} from "../utils/formObjects";
import DevStateContext from "../data/contexts/DevStateContext";
import {updateAmpUserLastPaymentHash} from "../data/update/person/updateAmpUser";
import {Button, Box} from "@mui/material";
import BaseNavigateButton from "../components/links/BaseNavigateButton";
import SubscriptionPreviewCard from "../components/cards/SubscriptionPreviewCard";
import {useConsoleError, useConsoleLog, useConsoleWarn} from "../components/hooks/logging/console";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesConfirmUserSubScreen} from "../utils/breadcrumbsRoutes";

// import {userSubDataObj, generateHash, updateAmpUserLastPaymentHash} from "../consts";
// import SubscriptionPreviewCard from "../components/cards/SubscriptionPreviewCard";
// import {devStateNow} from "../consts";

// TODO - !!! START HERE, FR, FR - ADD SUCCESS, CANCEL SCREENS AND UPDATE ACTIONS FOR MODEL RECS,
//  CREATE LAMBDA FUNCTION AND NEW STRIPE OBJECTS WITH DEDICATED WEBHOOK

export default function UserSubPreviewScreen(props) {
    const [ampUser, setAmpUser] = useState({});
    const [subLength, setSubLength] = useState('');
    const [priceKey, setPriceKey] = useState('');
    // const [contextDevState, setContextDevState] = useState({})
    // const [devStateStatus, setDevStateStatus] = useState({})
    const [showLogs, setShowLogs] = useState(false);
    const [userSubData, setUserSubData] = useState(userSubDataObj);
    const [hash, setHash] = useState('');
    const [locationState, setLocationState] = useState(null);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    let navigate = useNavigate();

    // let navigate = useNavigate();
    let location = useLocation();

    const {devStateObj} = useContext(DevStateContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesConfirmUserSubScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect(() => { setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs) {
            setShowLogs(devStateObj.showLogs);
        }
        else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setLocationState(location.state);
    useEffect(() => {
        if (location?.state) {
            setLocationState(location.state);
        }
    }, [location.state]);

    useConsoleLog(showLogs, [{label: 'UserSubPreviewScreen location', value: location}]);

    useConsoleLog(showLogs, [{label: 'UserSubPreviewScreen locationState', value: locationState}]);

    useConsoleLog(showLogs, [{label: 'UserSubPreviewScreen hash', value: hash}]);

    useConsoleLog(showLogs, [{label: 'UserSubPreviewScreen ampUser', value: ampUser}]);

    useConsoleLog(showLogs, [{label: 'UserSubPreviewScreen subLength', value: subLength}]);

    // TODO - UPDATE AMPUSER REC WITH THIS FIELD,
    //  THEN USE THIS ON SUCCESS PAGE TO UPDATE AMPUSER WITH SUBSCRIPTION

    // useEffect(() => {
    //     const generate = async () => {
    //         const generatedHash = await generateHash(`${ampUser.id}${subLength}${priceKey}`);
    //         setHash(generatedHash);
    //     };
    //
    //     generate();
    // }, [ampUser, subLength, priceKey]); // Re-run the effect if inputString changes

    // TODO - !!! - TOGGLE BACK IN WHEN USED

    // TODO - MAKE SURE PAYMENTS, PRODUCTS ARE STILL CORRECT & LIVE ON STRIPE SIDE
    const handleSubscribeClick = async e => {
        const generatedHash = await generateHash(`${ampUser.id}${subLength}${priceKey}`);
        // setHash(generatedHash);
        await updateAmpUserLastPaymentHash(ampUser, generatedHash);
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)
        const {error} = await stripe.redirectToCheckout({
            lineItems: [{
                price: priceKey || 'missing price',
                quantity: 1
            }],
            mode: 'subscription',
            // successUrl: 'http://localhost:3000/subscription-success',
            // successUrl: `${devStateNow.stripeSuccessURL}`,
            // successUrl: `${devStateObj?.stripeSuccessURL}`,
            // successUrl: `${devStateObj?.stripeSuccessURL}?session_id=${hash}`,
            successUrl: `${devStateObj?.stripeSuccessURL}?session_id=${generatedHash}`,
            // cancelUrl: 'http://localhost:3000/subscription-cancel',
            // cancelUrl: `${devStateNow.stripeCancelURL}`,
            cancelUrl: `${devStateObj?.stripeCancelURL}`,
            clientReferenceId: ampUser.id,
            customerEmail: ampUser.email
            // metadata: {ampUserID: ampUser ? ampUser.id : 'ampUserID'}
        })
    }

    // useEffect( setSubLength(location.state.subLength);
    useEffect(() => {
        if (location.state?.subLength) {
            setSubLength(location.state.subLength);
            // TODO - START HERE, USE ENV VARS FOR THESE TOO
            switch (location.state.subLength) {
                // case 'year':
                case userSubData.Year.subText:
                    setPriceKey(process.env.REACT_APP_STRIPE_YEAR_PRICE_KEY);
                    break
                // case 'quarter':
                case userSubData.Quarter.subText:
                    setPriceKey(process.env.REACT_APP_STRIPE_QUARTER_PRICE_KEY);
                    break
                case userSubData.Month.subText:
                    setPriceKey(process.env.REACT_APP_STRIPE_MONTH_PRICE_KEY);
                    break
                default:
                    setPriceKey(null)
                    break
            }
        }
    }, [location]);

    // TODO - !!! - TOGGLE CARD BACK IN WHEN USED

    // TODO - make sure correct values are passed to card
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h2>Subscription Preview</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <div className={'Subscribe-preview-card'}>
                <SubscriptionPreviewCard
                    showLogs={showLogs}
                    ampUser={ampUser}
                    subLength={subLength}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 0, p: 0 }}>
                <Button
                    variant="contained"
                    onClick={handleSubscribeClick}
                    sx={{
                        textTransform: 'none',
                        fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                        // margin: '1rem',
                        backgroundColor: '#5F9EA0',
                        mt: '1rem', // margin-top
                        mb: '1rem', // margin-bottom
                        // Remove horizontal margins
                        mr: 0, // margin-right
                        ml: 0, // margin-left
                    }}
                >
                    Subscribe
                </Button>
                <BaseNavigateButton
                    navTarget={'/subscription-cancel'}
                    label='Cancel'
                />
                    </Box>
            </div>
        </Box>
    )
}
