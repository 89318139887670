import { Client, ClientNote } from "../../../models";
import { DataStore } from "aws-amplify";
import { modelRouterPathAttrFactory } from "../../../utils/breadcrumbsRoutes";
import { convertToFullInteger } from "../../../utils/numbers";
import { v4 as uuidv4 } from 'uuid';

export async function createClientRec(clientFormValues, clientNoteFormValues, ampUserRec, showLogs) {
    // const clientRouterPath = md5(`${ampUserRec.name}-${clientFormValues.name}-${clientFormValues.phone}-${Date.now()}`);
    const resObj = {};
    const routerPathUUID = uuidv4();
    const clientRouterPath = modelRouterPathAttrFactory(`${ampUserRec.name}-${clientFormValues.name}-${routerPathUUID}`);
    // const clientRecObj = {...clientRec, id: clientRecId};
    try {
        const newClientRec = await DataStore.save(new Client(
            {
                name: clientFormValues.name,
                email: clientFormValues.email,
                phone: clientFormValues.phone,
                balance: convertToFullInteger(clientFormValues.balance),
                streetAddress: clientFormValues.streetAddress,
                city: clientFormValues.city,
                state: clientFormValues.state,
                zipCode: clientFormValues.zipCode,
                clientStatus: clientFormValues.clientStatus,
                ampuserID: ampUserRec.id,
                routerPath: clientRouterPath,
                gateCode: clientFormValues.gateCode,
                alternateContactName: clientFormValues.alternateContactName,
                alternateContactPhone: clientFormValues.alternateContactPhone,
            }
        ));
        if (showLogs) {
            console.log('createClient newClientRec', newClientRec);
        }
        resObj.success = true;
        resObj.newClientRec = newClientRec;
        if (newClientRec && clientNoteFormValues?.note) {
            // const newClientNoteRec = await createClientNote(newClientRec, clientFormValues.clientNote);
            const newClientNoteRec = await createClientNote(newClientRec, clientNoteFormValues.note);
            if (showLogs) {
                console.log('createClient newClientNoteRec', newClientNoteRec);
            }
            resObj.newClientNoteRec = newClientNoteRec;
        }
        return resObj;
    }
    catch (error) {
        console.error('createClientRec error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return resObj;

    // if (newClientRec && clientFormValues.clientNote) {
    //     const newClientNoteRec = await createClientNote(newClientRec, clientFormValues.clientNote);
    //     if (showLogs) {
    //         console.log('createClient newClientNoteRec', newClientNoteRec);
    //     }
    // }
    // return newClientRec;
}

export async function createClientNote(newClientRec, clientNote, showLogs) {
    const newClientNoteRec = await DataStore.save(new ClientNote(
        {
            clientID: newClientRec.id,
            note: clientNote,
            isEdited: false,
            routerPath: modelRouterPathAttrFactory(`${newClientRec.id}`)
        }
    ));
    return newClientNoteRec;
}