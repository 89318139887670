import React, { useState, useEffect, useContext } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import DevStateContext from "../data/contexts/DevStateContext";
import Box from "@mui/material/Box";
import BaseNavigateButton from "../components/links/BaseNavigateButton";
import AmpUserAnimalsContext from "../data/contexts/AmpUserAnimalsContext";
import {breadcrumbRoutesAnimalsScreen} from "../utils/breadcrumbsRoutes";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import StyledListBox from "../components/boxes/StyledListBox";
import {useResponsiveViewport} from "../data/providers/ResponsiveViewportProvider";
import {deleteAnimalById, deleteAnimalRecRelatedRecs} from "../data/delete/deleteRecords";
import BaseStyledDeleteButton from "../components/buttons/BaseStyledDeleteButton";
import VerticalButtonStack from "../components/buttons/VerticalButtonStack";
import DeleteAnimalConfirmationDialog from "../components/modals/delete/DeleteAnimalModal";
import { useConsoleLog } from "../components/hooks/logging/console";
import AnimalInfoCard from "../components/cards/AnimalInfoCard";
import StyledCardContentTypography from "../components/text/StyledCardContentTypography";
import AmpUserClientsContext from "../data/contexts/AmpUserClientsContext";
import AnimalNotesContext from "../data/contexts/AnimalNotesContext";
import ConfirmDeleteAnimalDialog from "../components/modals/delete/ConfirmDeleteAnimalDialog";
import StyledListCard from "../components/cards/StyledListCard";
import {toTitleCase} from "../utils/text";
import AnimalDetailsDialog from "../components/modals/AnimalDetailsDialog";
import BaseStyledButton from "../components/buttons/BaseStyledButton";

// import NotesAccordion from "../components/accordions/NotesAccordion";
// import DeleteApptAnimalConfirmationDialog from "../components/modals/DeleteApptAnimalModal";
// import StyledListCard from "../components/cards/StyledListCard";


export default function AmpUserAnimalsScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [animalRecs, setAnimalRecs] = useState([]);
    const [animalIds, setAnimalIds] = useState([]);
    const [animalsByClientId, setAnimalsByClientId] = useState({}); // {clientId: [animalRecs]
    const [clientRecs, setClientRecs] = useState([]);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    // const [fetchRelatedRecsAnimalId, setFetchRelatedRecsAnimalId] = useState(null);
    // const [fetchAnimalNotesAnimalId, setFetchAnimalNotesAnimalId] = useState(null);
    const [animalNotesByAnimalId, setAnimalNotesByAnimalId] = useState({}); // {animalId: [animalNotesRecs
    // const [updateNotesDone, setUpdateNotesDone] = useState(true);

    const [showDeleteAnimalModal, setShowDeleteAnimalModal] = useState(false);
    const [displayDeleteAnimalDialog, setDisplayDeleteAnimalDialog] = useState(false);
    const [modelRecToDelete, setModelRecToDelete] = useState(null);
    const [animalRecToDelete, setAnimalRecToDelete] = useState(null);
    const [deleteAnimalResObj, setDeleteAnimalResObj] = useState({});
    const [deleteAnimalRelatedRecsObj, setDeleteAnimalRelatedRecsObj] = useState(true);

    const [displayAnimalDetailsDialog, setDisplayAnimalDetailsDialog] = useState(false);
    const [animalDetailsAnimalRec, setAnimalDetailsAnimalRec] = useState(null);
    const [animalDetailsClientRec, setAnimalDetailsClientRec] = useState(null);

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();
    const { devStateObj } = useContext(DevStateContext);
    const { ampUserAnimalsRecs, animalRecsByClientId } = useContext(AmpUserAnimalsContext);
    const { ampUserClientsRecs } = useContext(AmpUserClientsContext);
    const { animalNotesRecs, setAnimalNotesAnimalRecId } = useContext(AnimalNotesContext);

    let navigate = useNavigate();
    let location = useLocation();

    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen animalNotesByAnimalId", value: animalNotesByAnimalId}])
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen animalsByClientId", value: animalsByClientId}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen animalRecs", value: animalRecs}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen ampUserAnimalsRecs", value: ampUserAnimalsRecs}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen animalRecsByClientId", value: animalRecsByClientId}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen ampUserClientsRecs", value: ampUserClientsRecs}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen animalNotesRecs", value: animalNotesRecs}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen deleteAnimalResObj", value: deleteAnimalResObj}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen deleteAnimalRelatedRecsObj", value: deleteAnimalRelatedRecsObj}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen modelRecToDelete", value: modelRecToDelete}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen animalRecToDelete", value: animalRecToDelete}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen displayDeleteAnimalDialog", value: displayDeleteAnimalDialog}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen displayAnimalDetailsDialog", value: displayAnimalDetailsDialog}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen animalDetailsAnimalRec", value: animalDetailsAnimalRec}]);
    useConsoleLog(showLogs, [{label: "AmpUserAnimalsScreen animalDetailsClientRec", value: animalDetailsClientRec}]);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesAnimalsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setAmpUser(props.ampUser)
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props.showLogs]);

    // useEffect( setAnimalRecs(ampUserAnimalRecs);
    useEffect(() => {
        if (ampUserAnimalsRecs) {
            setAnimalRecs(ampUserAnimalsRecs);
        }
    }, [ampUserAnimalsRecs]);

    // useEffect( setAnimalsByClientId(animalRecsByClientId);
    useEffect(() => {
        if (animalRecsByClientId) {
            setAnimalsByClientId(animalRecsByClientId);
        }
    }, [animalRecsByClientId]);

    // useEffect( setClientRecs(ampUserClientsRecs);
    useEffect(() => {
        if (ampUserClientsRecs) {
            setClientRecs(ampUserClientsRecs);
        }
    }, [ampUserClientsRecs]);

    // function displayAnimalRecs() {
    //     // TODO - GET ANIMALS BY CLIENT MAP, INCLUDE CLIENT NAME
    //     if (animalRecs) {
    //         return animalRecs.map((animalRec, index) => {
    //             return (
    //                 <div
    //                     key={`amp-user-animals-${index}-${animalRec.id}`}
    //                     style={{
    //                         marginBottom: '1.5vh',
    //                         backgroundColor: 'lightgray',
    //                     }}
    //                 >
    //                     <StyledListCard
    //                         key={`ampUser-AnimalRec-${index}`}
    //                     >
    //                         <Typography>
    //                             Name: {animalRec.name}
    //                         </Typography>
    //                         <Typography>
    //                             Breed: {animalRec.breed}
    //                         </Typography>
    //                         <VerticalButtonStack
    //                             sx={{ maxWidth: 420,
    //                                 display: 'flex',
    //                                 flexDirection: 'column',
    //                                 alignItems: 'center',
    //                                 justifyContent: 'center',
    //                                 paddingBottom: '1vh',
    //                         }}>
    //                         <BaseNavigateButton
    //                             size="small"
    //                             style={{maxWidth: '80vw'}}
    //                             navTarget={`/animal-info/${animalRec.routerPath}`}
    //                             label={`${animalRec.name} Info`}
    //                             navState={{animalRec: animalRec}}
    //                         />
    //                         <BaseStyledDeleteButton
    //                             size="small"
    //                             style={{maxWidth: '50vw'}}
    //                             label="Delete Animal"
    //                             onClick={() => {
    //                                 setModelRecToDelete(animalRec);
    //                                 setShowDeleteAnimalModal(true);
    //                             }}
    //                         />
    //                         </VerticalButtonStack>
    //                     </StyledListCard>
    //                 </div>
    //             )
    //         });
    //     }
    //     else {
    //         return (
    //             <Typography>
    //                 No animals found.
    //             </Typography>
    //         )
    //     }
    // }

    function animalCardActionButtonsStackFactory(animalRec) {
        return (
            <VerticalButtonStack
                sx={{ maxWidth: 420,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '1vh',
                }}>
                <BaseNavigateButton
                    size="large"
                    style={{maxWidth: '80vw'}}
                    navTarget={`/animal-info/${animalRec.routerPath}`}
                    label={`${animalRec.name} Info`}
                    navState={{animalRec: animalRec}}
                />
                <BaseStyledDeleteButton
                    size="large"
                    style={{maxWidth: '50vw'}}
                    label="Delete Animal"
                    onClick={() => {
                        setModelRecToDelete(animalRec);
                        setShowDeleteAnimalModal(true);
                    }}
                />
            </VerticalButtonStack>
        );
    }

    // useEffect(() => {
    //     if (fetchRelatedRecsAnimalId) {
    //         populateNotesForAnimalId(fetchRelatedRecsAnimalId);
    //     }
    // }, [fetchRelatedRecsAnimalId]);

    // function populateNotesForAnimalId(animalId) {
    //     if (animalId) {
    //         setFetchAnimalNotesAnimalId(animalId);
    //     }
    // }

    // useEffect(() => {
    //     if (fetchAnimalNotesAnimalId) {
    //         setAnimalNotesAnimalRecId(fetchAnimalNotesAnimalId);
    //     }
    //     else {
    //         setAnimalNotesAnimalRecId(null);
    //     }
    // }, [fetchAnimalNotesAnimalId]);

    // useEffect(() => {
    //     if (animalNotesRecs) {
    //         updateAnimalNotesByAnimalId(fetchAnimalNotesAnimalId, animalNotesRecs);
    //     }
    //     else {
    //         console.log('No notes for animalId: fetchAnimalNotesAnimalId', fetchAnimalNotesAnimalId);
    //     }
    // }, [animalNotesRecs]);

    // function updateAnimalNotesByAnimalId(animalId, animalNotesRecs) {
    //     if (showLogs) {
    //         console.log('>>> updateAnimalNotesByAnimalId animalId', animalId);
    //         console.log('>>> updateAnimalNotesByAnimalId animalNotesRecs', animalNotesRecs);
    //     }
    //     if (animalId && animalNotesRecs) {
    //         setAnimalNotesByAnimalId({...animalNotesByAnimalId, [animalId]: animalNotesRecs});
    //     }
    //     // else {
    //     //
    //     // }
    //     cleanUpFetchNotes();
    // }

    // function cleanUpFetchNotes() {
    //     if (showLogs) {
    //         console.log('>>> cleanUpFetchNotes()');
    //     }
    //     setFetchRelatedRecsAnimalId(null);
    //     setFetchAnimalNotesAnimalId(null);
    //     setUpdateNotesDone(true);
    // }

    // function animalNotesAccordionFactory(animalRec) {
    //     const animalId = animalRec.id;
    //     const animalNotesById = animalNotesByAnimalId[animalId];
    //     if (!animalNotesById || animalNotesById.length === 0) {
    //         return null;
    //     }
    //     else {
    //         return (
    //             <NotesAccordion
    //                 notes={animalNotesById}
    //                 headerText={`Notes for ${animalRec.name}`}
    //                 noteType={'animal'}
    //                 showLogs={showLogs}
    //                 animalRecProp={animalRec}
    //             />
    //         )
    //     }
    // }

    // TODO - ADD CREATE APPT BUTTON TO ANIMAL INFO CARD

    // TODO - FIGURE OUT HOW TO PASS NOTES ACCORDION FOR EACH ANIMAL REC,
    //  BUT TRY TO AVOID FETCHING ALL NOTES FOR ALL ANIMALS,
    //  WANT TO ONLY FETCH NOTES FOR ANIMAL REC WHEN USER CLICKS TO DISPLAY MORE INFO IN CARD,
    //  THIS CURRENTLY WORKS BUT ONLY FOR A SECOND, THEN ACCORDION IN INFO CARD DISAPPEARS,
    //  THINK THIS IS DUE TO SOME STATE VAL IN THIS COMPONENT GETTING SET BACK TO NULL AFTER ACCORDION IS INITIALLY POPULATED

    // function displayAnimalInfoCards() {
    //     if (animalRecs) {
    //         return animalRecs.map((animalRec, index) => {
    //             let animalClientRec;
    //             if (clientRecs && clientRecs.length > 0 && animalRec.clientID) {
    //                 animalClientRec = clientRecs.find((clientRec) => {
    //                     return clientRec.id === animalRec.clientID;
    //                 });
    //             }
    //             return (
    //                 <Box
    //                     key={`amp-user-animals-${index}-${animalRec.id}`}
    //                     sx={{
    //                         marginBottom: '1.5vh',
    //                         backgroundColor: 'lightgray',
    //                         width: '100%', // Ensure the item takes the full width of the container
    //                         display: 'flex',
    //                         justifyContent: 'center', // Center the content
    //                     }}
    //                 >
    //                     <AnimalInfoCard
    //                         animalRec={animalRec}
    //                         clientRec={animalClientRec}
    //                         showLogs={showLogs}
    //                         showAllAttrs={'false'}
    //                         cardActionButtonStack={() => animalCardActionButtonsStackFactory(animalRec)}
    //                         // setFetchRelatedRecsAnimalId={setFetchRelatedRecsAnimalId}
    //                         // animalNotesAccordionFactory={animalNotesAccordionFactory}
    //                         // setUpdateNotesDone={setUpdateNotesDone}
    //                         // updateNotesDone={updateNotesDone}
    //                     />
    //                 </Box>
    //             )
    //         });
    //     }
    //     else {
    //         return (
    //             <StyledCardContentTypography
    //                 text={"No animals found."}
    //             />
    //         );
    //     }
    // }

    function displayAnimalRecs() {
        if (animalRecs) {
            return animalRecs.map((animalRec, index) => {
                let animalClientRec;
                if (clientRecs && clientRecs.length > 0 && animalRec.clientID) {
                    animalClientRec = clientRecs.find((clientRec) => {
                        return clientRec.id === animalRec.clientID;
                    });
                }
                return (
                    <Box
                        key={`amp-user-animals-${index}-${animalRec.id}`}
                        sx={{
                            marginBottom: '1.5vh',
                            backgroundColor: 'lightgray',
                            width: '100%', // Ensure the item takes the full width of the container
                            display: 'flex',
                            justifyContent: 'center', // Center the content
                        }}
                    >
                        <StyledListCard
                            key={`ampUserAnimalRec-${index}`}
                            sx={{
                                width: '100%',
                                maxWidth: {
                                    xs: 360,  // max-width for xs breakpoint and up
                                    sm: 480,  // max-width for sm breakpoint and up
                                    md: 600,  // max-width for md breakpoint and up
                                    lg: 800,  // max-width for lg breakpoint and up
                                    xl: 1000, // max-width for xl breakpoint and up
                                },
                            }}
                        >
                            <StyledCardContentTypography text={`Client: ${animalClientRec?.name || '-'}`} />
                            <StyledCardContentTypography text={`Animal: ${animalRec?.name || '-'}`} />
                            <StyledCardContentTypography text={`Animal Type: ${animalRec?.animalType ?  toTitleCase(animalRec.animalType) : '-'}`} />
                            <StyledCardContentTypography text={`Breed: ${animalRec?.breed ? toTitleCase(animalRec.breed) : '-'}`} />
                            <StyledCardContentTypography text={`Age: ${animalRec?.age || '-'}`} />
                            <StyledCardContentTypography text={`Color: ${animalRec?.color || '-'}`} />
                            <BaseStyledButton
                                label={"Details"}
                                onClick={() => {
                                    setAnimalDetailsAnimalRec(animalRec);
                                    setAnimalDetailsClientRec(animalClientRec);
                                    setDisplayAnimalDetailsDialog(true);
                                }}
                            />
                            <BaseNavigateButton
                                navTarget={`/edit-animal/${animalRec?.routerPath}`}
                                label={"Edit"}
                                navState={{ clientRec: animalClientRec, animalRec: animalRec }}
                            />
                            <BaseStyledDeleteButton
                                label={"Delete"}
                                onClick={() => {
                                    setAnimalRecToDelete(animalRec);
                                    setDisplayDeleteAnimalDialog(true);
                                }}
                            />
                        </StyledListCard>
                    </Box>
                )
            });
        }
        else {
            return (
                <StyledListCard>
                    <StyledCardContentTypography text={'Loading Animals...'} />
                </StyledListCard>
            );
        }
    }

    function onCloseAnimalDetailsDialog() {
        setDisplayAnimalDetailsDialog(false);
        setAnimalDetailsAnimalRec(null);
        setAnimalDetailsClientRec(null);
    }

    function onCloseDeleteAnimalDialog() {
        setDisplayDeleteAnimalDialog(false);
        setAnimalRecToDelete(null);
    }

    function onDeleteAnimalDone(deleteAnimalRes) {
        if (deleteAnimalRes) {
            setDeleteAnimalResObj(deleteAnimalRes);
        }
    }

    function onDeleteRelatedRecsDone(deleteRelatedRecsRes) {
        if (deleteRelatedRecsRes) {
            setDeleteAnimalRelatedRecsObj(deleteRelatedRecsRes);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h2>Animals</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                    backgroundColor: 'lightgray',
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                    marginTop: '0.5vh',
                    maxWidth: '100vw', // Ensure it doesn’t exceed viewport width
                    height: '60vh', // Adjust height based on viewport height
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {/*{displayAnimalInfoCards()}*/}
                {displayAnimalRecs()}
            </StyledListBox>
            <div>
                <BaseNavigateButton
                    label={"Create Animal"}
                    navTarget={'/create-animal'}
                />
            </div>
            <div>
                {displayAnimalDetailsDialog ? <AnimalDetailsDialog
                    openProp={displayAnimalDetailsDialog}
                    handleCloseProp={onCloseAnimalDetailsDialog}
                    animalRecProp={animalDetailsAnimalRec}
                    clientRecProp={animalDetailsClientRec}
                    showLogs={showLogs}
                    ampUserProp={ampUser}
                /> : null}
            </div>
            <div>
                {displayDeleteAnimalDialog ? <ConfirmDeleteAnimalDialog
                    openProp={displayDeleteAnimalDialog}
                    handleCloseProp={onCloseDeleteAnimalDialog}
                    ampUser={ampUser}
                    modelRecToDelete={animalRecToDelete}
                    showLogs={showLogs}
                    handleDeleteAnimalDone={onDeleteAnimalDone}
                    handleDeleteRelatedRecsDone={onDeleteRelatedRecsDone}
                /> : null}
            </div>
        </Box>
    );
}
