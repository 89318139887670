import React, {useState, useEffect} from "react";
import DevStateProvider from "../../data/providers/DevStateProvider";
import ClientAnimalsDataProvider from "../../data/providers/ClientAnimalsDataProvider";
import {RequireAuth} from "../auth/RequireAuth";
import {useParams, useLocation} from "react-router-dom";
import AmpUserClientAnimalsScreen from "../../screens/AmpUserClientAnimalsScreen";
import {RequireSub} from "../auth/RequireSub";

export default function WrappedClientAnimals({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [routerParamClientRouterPath, setRouterParamClientRouterPath] = useState(null);
    const [clientRec, setClientRec] = useState(null);

    const {clientRouterPath} = useParams();
    const location = useLocation();

    const stateClientRec = location.state?.clientRec;

    useEffect(() => {
        console.log('location', location);
    }, [location]);

    useEffect(() => {
        if (routerParamClientRouterPath === null && clientRouterPath !== null) {
            setRouterParamClientRouterPath(clientRouterPath);
        }
    }, [clientRouterPath]);

    useEffect(() => {
        if (stateClientRec) {
            setClientRec(stateClientRec);
        }
    }, [location]);

    useEffect(() => {
        if (showLogs) {
            console.log('WrappedClientAnimals routerParamClientRouterPath', routerParamClientRouterPath);
            console.log('clientRec', clientRec);
        }
        // console.log('WrappedClientAnimals routerParamClientRouterPath', routerParamClientRouterPath);
    }, [routerParamClientRouterPath, clientRec, showLogs]);


    return (
        <RequireAuth>
            <DevStateProvider>
                {clientRec && ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        <ClientAnimalsDataProvider clientId={clientRec.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                            <AmpUserClientAnimalsScreen routerPaths={routerPaths}
                                                        ampUser={ampUser}
                                                        showLogs={showLogs}
                                                        clientRec={clientRec}
                            />
                        </ClientAnimalsDataProvider>
                    </RequireSub>
                ) : (
                    <h2>Loading Client Animals Data...</h2>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}
