import React from 'react';
import Accordion from "@mui/material/Accordion";

// TODO - FIX ISSUE WITH ACCORDION NOT EXPANDING,
//  OR EXPANDING BEHIND OTHER ELEMENTS
export default function StyledAccordion(props) {
    return (
        <Accordion sx={{
            // Default styles specific to the Accordion component
            fontSize: props?.fontSize || '1rem',
            fontWeight: props?.fontWeight || 'normal',
            color: props?.color || 'text.primary',
            marginTop: props?.marginTop || '1rem',
            // marginBottom: props?.marginBottom || 1,
            margin: props?.margin || 0,
            // Add more default styles if necessary

            // Responsive styles for height, maxHeight, and minHeight
            height: props?.height || 'auto',
            maxHeight: props?.maxHeight || 'none',
            minHeight: props?.minHeight || '0',

            // Overwrite with props.sx to allow for custom styles
            ...props?.sx,
            marginBottom: props?.sx?.marginBottom || '1rem !important',
        }}
        disabled={props.disabled}
        >
            {props.children}
        </Accordion>
    );
}
