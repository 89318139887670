import React, { useState, useEffect, useContext } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import ClientPreviewCard from "../components/cards/ClientPreviewCard";
import DevStateContext from "../data/contexts/DevStateContext";
import Box from "@mui/material/Box";
import {createClientRec} from "../data/create/person/Client";
import { useConsoleLog } from "../components/hooks/logging/console";
import UpdatingIndicator from "../components/progress/UpdatingIndicator";
import CreateNewRecSuccessDialog from "../components/modals/CreateNewRecSuccessDialog";
import {breadcrumbRoutesClientsScreen} from "../utils/breadcrumbsRoutes";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";


// TODO - USE PROGRESS, SUCCESS DIALOGS

export default function CreateClientPreviewScreen(props) {
    const [ampUser, setAmpUser] = useState({});
    const [showLogs, setShowLogs] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [updatedClientFormVals, setUpdatedClientFormVals] = useState({});
    const [updatedClientNoteFormVals, setUpdatedClientNoteFormVals] = useState({});
    const [displayUpdatingIndicator, setDisplayUpdatingIndicator] = useState(false);
    const [displayCreateNewRecSuccessDialog, setDisplayCreateNewRecSuccessDialog] = useState(false);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    let navigate = useNavigate();

    // let navigate = useNavigate();
    let location = useLocation();

    const {devStateObj} = useContext(DevStateContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesClientsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser, showLogs]);

    // useEffect(() => { console.log('location', location);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('location', location);
    //     }
    // }, [location, showLogs]);

    useConsoleLog(showLogs, [{label: 'CreateClientPreviewScreen location', value: location}]);

    // useEffect( setFormValues(location.state.formValues);
    useEffect(() => {
        if (location?.state?.formValues) {
            setFormValues(location.state.formValues);
        }
    }, [location]);

    // useEffect(() => { console.log('formValues', formValues);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('formValues', formValues);
    //     }
    // }, [formValues, showLogs]);

    // useEffect( setUpdatedClientFormVals(location.state.updatedClientFormVals);
    useEffect(() => {
        if (location?.state?.updatedClientFormVals) {
            setUpdatedClientFormVals(location.state.updatedClientFormVals);
        }
    }, [location]);

    // useEffect(() => {
    //
    // }, [ampUser, updatedClientFormVals]);

    // useEffect( setUpdatedClientNoteFormVals(location.state.updatedClientNoteFormVals);
    useEffect(() => {
        if (location?.state?.updatedClientNoteFormVals) {
            setUpdatedClientNoteFormVals(location.state.updatedClientNoteFormVals);
        }
    }, [location]);

    useConsoleLog(showLogs, [{label: 'CreateClientPreviewScreen formValues', value: formValues}]);
    useConsoleLog(showLogs, [{label: 'CreateClientPreviewScreen updatedClientFormVals', value: updatedClientFormVals}]);
    useConsoleLog(showLogs, [{label: 'CreateClientPreviewScreen updatedClientNoteFormVals', value: updatedClientNoteFormVals}]);

    async function createClient() {
        if (showLogs) {
            console.log('CreateClientPreviewScreen createClient()');
        }
        // await createClientRec(formValues, ampUser, showLogs);
        const createNewClientRecsRes = await createClientRec(updatedClientFormVals, updatedClientNoteFormVals, ampUser, showLogs);
        if (showLogs) {
            console.log('CreateClientPreviewScreen createNewClientRecsRes', createNewClientRecsRes);
        }
        navigate('/clients'); // TODO START HERE - ADD ROUTE FOR CLIENTS BY AMPUSER
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h1>Create Client Preview</h1>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <ClientPreviewCard
                ampUser={ampUser}
                formValues={formValues}
                updatedClientFormVals={updatedClientFormVals}
                updatedClientNoteFormVals={updatedClientNoteFormVals}
                showLogs={showLogs}
                createClient={createClient}
            />
        </Box>
    );
}
