import { Amplify, DataStore } from "aws-amplify";
import { AmpUser, UserStatus } from "../../../models";
import aws_exports from '../../../aws-exports';
import {defaultName, defaultPhone} from "../../../utils/consts";
import {createNewAmpUserSubscriberRec} from "./ampUserSubscriber";
import { v4 as uuidv4 } from 'uuid';

Amplify.configure(aws_exports);

// TODO - ADD A PARAM TO CREATE A NEW AUS REC AFTER THIS ONE IS CREATED, USING SAME ID, SO BOTH ARE CREATED AT SAME TIME,
//  SIMPLIFIES NEW USER REC CREATION PROCESS
export default async function onAmpUserCreate(user, createAUS, showLogs) {
    console.log('!!! onAmpUserCreate(user)', user);
    if (user.attributes?.email) {
        const resObj = {};
        const newAmpUserRec= await DataStore.save(
            new AmpUser({
                name: user.attributes?.name || defaultName,
                email: user.attributes?.email,
                username: user.username,
                phone: defaultPhone,
                userStatus: UserStatus.FREE,
                routerPath: uuidv4(),
                share_attrs: ['email'],
                intakeFormCompleted: false,
            })
        );
        resObj.newAmpUserRec = newAmpUserRec;
        if (newAmpUserRec && createAUS) {
            const newAUSRec = await createNewAmpUserSubscriberRec(newAmpUserRec, showLogs);
            resObj.newAUSRec = newAUSRec;
        }
        return resObj;
    } else {
        console.log('!!! no email user', user);
    }
}

