import React, {useState, useEffect, useContext} from 'react';
import {useLocation} from "react-router-dom";
import {RequireAuth} from "../auth/RequireAuth";
import DevStateProvider from "../../data/providers/DevStateProvider";
import EditApptScreen from "../../screens/EditApptScreen";
import ApptShoeTasksProvider from "../../data/providers/ApptShoeTasksProvider";
import ApptTrimTasksProvider from "../../data/providers/ApptTrimTasksProvider";
import ApptNotesProvider from "../../data/providers/ApptNotesProvider";
import AmpUserApptAnimalsProvider from "../../data/providers/AmpUserApptAnimalsProvider";
import AmpUserApptsProvider from "../../data/providers/AmpUserApptsProvider";
import ClientAnimalsDataProvider from "../../data/providers/ClientAnimalsDataProvider";
import {RequireSub} from "../auth/RequireSub";

export default function WrappedEditAppt({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [apptRecId, setApptRecId] = useState(null);
    const [apptClientId, setApptClientId] = useState(null);
    const [localShowLogs, setLocalShowLogs] = useState(false);

    const location = useLocation();

    const stateApptRecId = location.state?.apptRecId;
    const stateApptClientId = location.state?.apptClientId;

    // useEffect( setApptRec(stateApptRec); setClientRec(stateClientRec);
    useEffect(() => {
        if (stateApptRecId) {
            setApptRecId(stateApptRecId);
        }
        if (stateApptClientId) {
            setApptClientId(stateApptClientId);
        }
    }, [location]);

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        }
    }, [showLogs]);

    // useEffect( console.log( apptRecId, apptClientId
    useEffect(() => {
        if (localShowLogs) {
            console.log('WrappedEditAppt apptRecId', apptRecId);
            console.log('WrappedEditAppt apptClientId', apptClientId);
        }
    }, [localShowLogs, apptRecId, apptClientId]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {ampUser && apptRecId && apptClientId ? (
                    <RequireSub ampUser={ampUser}>
                        <ClientAnimalsDataProvider clientId={apptClientId} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                            <AmpUserApptsProvider apptId={apptRecId} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                <AmpUserApptAnimalsProvider apptId={apptRecId} showLogs={showLogs} ampUser={ampUser} resetProviderState={resetProvidersStates}>
                                    <ApptNotesProvider apptId={apptRecId} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                        <ApptTrimTasksProvider apptId={apptRecId} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                            <ApptShoeTasksProvider apptId={apptRecId} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                                                <EditApptScreen
                                                    ampUser={ampUser}
                                                    routerPaths={routerPaths}
                                                    showLogs={showLogs}
                                                />
                                            </ApptShoeTasksProvider>
                                        </ApptTrimTasksProvider>
                                    </ApptNotesProvider>
                                </AmpUserApptAnimalsProvider>
                            </AmpUserApptsProvider>
                        </ClientAnimalsDataProvider>
                    </RequireSub>
                ) : (
                    <h2>Edit Appt - No Appt Id</h2>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}
