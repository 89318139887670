import React, { useState, useContext, useEffect } from "react";
import DevStateContext from "../data/contexts/DevStateContext";
import ClientAnimalsContext from "../data/contexts/ClientAnimalsContext";
import StyledListCard from "../components/cards/StyledListCard";
import Typography from "@mui/material/Typography";
import AnimalInfoCard from "../components/cards/AnimalInfoCard";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesAnimalsScreen} from "../utils/breadcrumbsRoutes";
import StyledListBox from "../components/boxes/StyledListBox";
import Box from "@mui/material/Box";
import {useResponsiveViewport} from "../data/providers/ResponsiveViewportProvider";

export default function AmpUserClientAnimalsScreen(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [clientRec, setClientRec] = useState(null);
    const [clientAnimalsRecs, setClientAnimalsRecs] = useState([]);
    const [animalShoePackageRecsByAnimalId, setAnimalShoePackageRecsByAnimalId] = useState([])
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const { devStateObj } = useContext(DevStateContext);
    const {ampUserClientAnimalsRecs, shoePackageRecsByAnimalId} = useContext(ClientAnimalsContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesAnimalsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs) {
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( setClientRec(props.clientRec);
    useEffect(() => {
        if (props.clientRec) {
            setClientRec(props.clientRec);
        }
    }, [props.clientRec]);

    // useEffect( setClientAnimalsRecs(ampUserClientAnimalsRecs);
    useEffect(() => {
        if (ampUserClientAnimalsRecs) {
            setClientAnimalsRecs(ampUserClientAnimalsRecs);
        }
    }, [ampUserClientAnimalsRecs]);

    // useEffect( setAnimalShoePackageRecsByAnimalId(shoePackageRecsByAnimalId);
    useEffect(() => {
        if (shoePackageRecsByAnimalId) {
            setAnimalShoePackageRecsByAnimalId(shoePackageRecsByAnimalId);
        }
    }, [shoePackageRecsByAnimalId]);

    useEffect(() => {
        if (showLogs) {
            console.log('AmpUserClientAnimalsScreen clientRec', clientRec);
            console.log('AmpUserClientAnimalsScreen clientAnimalsRecs', clientAnimalsRecs);
            console.log('>>> AmpUserClientAnimalsScreen animalShoePackageRecsByAnimalId', animalShoePackageRecsByAnimalId);
        }
    }, [clientRec, clientAnimalsRecs, animalShoePackageRecsByAnimalId, showLogs]);

    function displayClientAnimalRecs() {
        if (clientAnimalsRecs && animalShoePackageRecsByAnimalId) {
            return clientAnimalsRecs.map((clientAnimalRec, index) => {
                console.log('!!! displayClientNoteRecs clientAnimalRec.animalID', clientAnimalRec.id);
                const animalShoePackageRec = animalShoePackageRecsByAnimalId[clientAnimalRec.id];
                console.log('displayClientNoteRecs animalShoePackageRec', animalShoePackageRec);
                return (
                    <AnimalInfoCard
                        key={`ampUserClientAnimalsRec-${index}`}
                        animalRec={clientAnimalRec}
                        clientRec={clientRec}
                        showLogs={showLogs}
                        shoePackageRec={animalShoePackageRec}
                    />
                )
            })
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        Client Animal Screen No Client Animals Recs
                    </Typography>
                </StyledListCard>
            )
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h3>Client Animals</h3>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            {clientAnimalsRecs && animalShoePackageRecsByAnimalId ? (
                <StyledListBox
                    width={isLargeScreen ? '90%' : '100%'}
                    style={{
                        color: 'white',
                        backgroundColor: 'lightgray',
                        borderTop: '1px solid black',
                        borderBottom: '1px solid black',
                        marginTop: '0.5vh',
                    }}
                >
                    {displayClientAnimalRecs()}
                </StyledListBox>
            ) : null}
        </Box>
    );
}