import React, {useEffect, useState} from "react";
import CreateAnimalScreen from "../../screens/CreateAnimalScreen";
import DevStateProvider from "../../data/providers/DevStateProvider";
import AmpUserClientsDataProvider from "../../data/providers/AmpUserClientsDataProvider";
import {RequireAuth} from '../auth/RequireAuth';
import {RequireSub} from "../auth/RequireSub";
import {useLocation} from "react-router-dom";

export default function WrappedCreateAnimal({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [clientRec, setClientRec] = useState(null);

    const location = useLocation();
    const locationClientRec = location?.state?.clientRec;

    useEffect(() => {
        if (showLogs) {
            console.log('WrappedCreateAnimal location', location);
        }
    }, [location, showLogs]);

    useEffect(() => {
        if (showLogs) {
            console.log('WrappedCreateAnimal clientRec', clientRec);
        }
    }, [clientRec, showLogs]);

    useEffect(() => {
        if (locationClientRec) {
            setClientRec(locationClientRec);
        }
    }, [locationClientRec]);

    function returnCreateAnimalScreen() {
        if (ampUser && locationClientRec) {
            return (
                <AmpUserClientsDataProvider ampUserId={ampUser.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                    <CreateAnimalScreen
                        routerPaths={routerPaths}
                        ampUser={ampUser}
                        // clientRec={clientRec}
                        clientRec={locationClientRec}
                        showLogs={showLogs}
                    />
                </AmpUserClientsDataProvider>
            )
        }
        else if (ampUser) {
            return (
                <AmpUserClientsDataProvider ampUserId={ampUser.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                    <CreateAnimalScreen
                        routerPaths={routerPaths}
                        ampUser={ampUser}
                        // clientRec={clientRec}
                        // clientRec={locationClientRec}
                        showLogs={showLogs}
                    />
                </AmpUserClientsDataProvider>
            )
        }
        else {
            return (
                <div><h2>Loading Data...</h2></div>
            )
        }
    }

    return (
        <DevStateProvider>
            <RequireAuth>
                {ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        <AmpUserClientsDataProvider ampUserId={ampUser.id} showLogs={showLogs} resetProviderState={resetProvidersStates}>
                            <CreateAnimalScreen
                                routerPaths={routerPaths}
                                ampUser={ampUser}
                                // clientRec={clientRec}
                                clientRec={locationClientRec}
                                showLogs={showLogs}
                            />
                        </AmpUserClientsDataProvider>
                        {/*{returnCreateAnimalScreen()}*/}
                    </RequireSub>
                ) : (
                    <div><h2>Loading Data...</h2></div>
                )}
            </RequireAuth>
        </DevStateProvider>
    )
}
