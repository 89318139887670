import React, {useState, useEffect} from 'react';
import { DataStore, SortDirection } from "aws-amplify";
import { Animal } from '../../models';
import AmpUserAnimalsContext from "../contexts/AmpUserAnimalsContext";

const AmpUserAnimalsDataProvider = ({ children, ampUserId, showLogs }) => {
    const [ampUserAnimalsRecs, setAmpUserAnimalsRecs] = useState([]);
    const [ampUserAnimalsShowLogs, setAmpUserAnimalsShowLogs] = useState(false);
    const [animalRecsByClientId, setAnimalRecsByClientId] = useState({});

    // useEffect( setAmpUserAnimalsShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setAmpUserAnimalsShowLogs(showLogs);
        } else {
            setAmpUserAnimalsShowLogs(false);
        }
    }, [showLogs]);

    // useEffect(() => {
    //     if (ampUserAnimalsShowLogs) {
    //         console.log('AmpUserAnimalsDataProvider ampUserAnimalsRecs', ampUserAnimalsRecs);
    //     }
    // }, [ampUserAnimalsRecs, ampUserAnimalsShowLogs]);

    useEffect(() => {
        if (ampUserId) {
            const subscription = DataStore.observeQuery(Animal,
                (a) => a.and( a=> [
                    a.ampuserID.eq(ampUserId),
                    a.isDeleted.ne(true),
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (ampUserAnimalsShowLogs) {
                        // console.log(`AmpUserAnimalsDataProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        // console.log('AmpUserAnimalsDataProvider a [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAmpUserAnimalsRecs(items);
                        if (items && items.length > 0) {
                            const animalRecsByClientId = items.reduce((acc, animal) => {
                                if (!acc[animal.clientID]) {
                                    acc[animal.clientID] = [];
                                }
                                acc[animal.clientID].push(animal);
                                return acc;
                            }, {});
                            setAnimalRecsByClientId(animalRecsByClientId);
                        }
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [ampUserId, ampUserAnimalsShowLogs]); // Dependency array now includes regionId

    return (
        <AmpUserAnimalsContext.Provider value={{ ampUserAnimalsRecs, animalRecsByClientId }}>
            {children}
        </AmpUserAnimalsContext.Provider>
    );
}

export default AmpUserAnimalsDataProvider;
