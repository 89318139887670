import React, { useState, useEffect, useContext } from "react";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import { useConsoleLog } from "../components/hooks/logging/console";
import Box from "@mui/material/Box";
import NewAmpUserIntakeForm from "../components/forms/edit/NewAmpUserIntakeForm";
import {breadcrumbRoutesUserAttributesScreen} from "../utils/breadcrumbsRoutes";
import StyledListBox from "../components/boxes/StyledListBox";
import {useResponsiveViewport} from "../data/providers/ResponsiveViewportProvider";

export default function NewAmpUserIntakeScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesUserAttributesScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( setAmpUser(props.ampUser)
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    return (
        <div
            style={{
                backgroundColor: 'lightgray',
                minHeight: '15vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'calc(10px + 2vmin)',
                // color: 'white',
                color: 'black',
                marginBottom: '0.5vh',
                // alignItems: 'stretch',
                width: '100%',
            }}
        >
            <h2>Update User</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <NewAmpUserIntakeForm
                ampUser={ampUser}
                showLogs={showLogs}
            />
        </div>
    );
}

