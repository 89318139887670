import React, {useState, useEffect} from 'react';
import { DataStore, SortDirection } from "aws-amplify";
import {Animal, AppointmentAnimal} from "../../models";
import AmpUserApptAnimalsContext from "../contexts/AmpUserApptAnimalsContext";


const AmpUserApptAnimalsProvider = ({ children, apptId, animalId, showLogs, ampUser }) => {
    const [ampUserApptAnimalsRecs, setAmpUserApptAnimalsRecs] = useState([]);
    const [localShowLogs, setLocalShowLogs] = useState(false);
    const [localApptIds, setLocalApptIds] = useState([]);
    const [localAnimalIds, setLocalAnimalIds] = useState([]);
    const [animalRecs, setAnimalRecs] = useState([]);
    const [apptRecs, setApptRecs] = useState([]);
    const [animalsRecsByApptId, setAnimalsRecsByApptId] = useState({});
    const [apptRecsByAnimalId, setApptRecsByAnimalId] = useState({});

    // useEffect( setLocalShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setLocalShowLogs(showLogs);
        } else {
            setLocalShowLogs(false);
        }
    }, [showLogs]);

    function filtersArrFactory(model) {
        // return idsArr.map((id, idx) => (model.eq(id)));
        return localApptIds.map((apptId, idx) => (model.appointmentId.eq(apptId)))
    }

    useEffect(() => {
        if (ampUser) {
            if (apptId) {
                const subscription = DataStore.observeQuery(AppointmentAnimal,
                    (aa) => aa.appointmentId.eq(apptId),
                    {
                        sort: s => s.createdAt(SortDirection.ASCENDING)
                    })
                    .subscribe(async msg => {
                        const {items, isSynced} = msg;
                        if (localShowLogs) {
                            // console.log(`AmpUserApptAnimalsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                            // console.log('AmpUserApptAnimalsProvider a [Snapshot] items', items);
                        }
                        if (isSynced) {
                            setAmpUserApptAnimalsRecs(items);
                            if (items) {
                                const relatedAnimalRecs = [];
                                const animalsByAppts = {};
                                for await (const item of items) {
                                    const animal = await DataStore.query(Animal, item.animalId);
                                    relatedAnimalRecs.push(animal);
                                    if (animalsByAppts[item.appointmentId]) {
                                        animalsByAppts[item.appointmentId].push(animal);
                                    } else {
                                        animalsByAppts[item.appointmentId] = [animal];
                                    }
                                }
                                setAnimalRecs(relatedAnimalRecs);
                                setAnimalsRecsByApptId(animalsByAppts);
                            }
                        }
                    });
                // Remember to unsubscribe when the component unmounts
                return () => subscription.unsubscribe();
            }
            else if (animalId) {
                const subscription = DataStore.observeQuery(AppointmentAnimal,
                    (a) => a.animalId.eq(animalId),
                    {
                        sort: s => s.createdAt(SortDirection.ASCENDING)
                    })
                    .subscribe(async msg => {
                        const {items, isSynced} = msg;
                        if (localShowLogs) {
                            // console.log(`AmpUserApptAnimalsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                            // console.log('AmpUserApptAnimalsProvider a [Snapshot] items', items);
                        }
                        if (isSynced) {
                            setAmpUserApptAnimalsRecs(items);
                        }
                    });
                // Remember to unsubscribe when the component unmounts
                return () => subscription.unsubscribe();
            }
                // else if (localApptIds.length > 0) {
                //     const subscription = DataStore.observeQuery(AppointmentAnimal,
                //         (a) => a.or(a => [
                //             localApptIds.map((apptId, idx) => (a.appointmentId.eq(apptId)))
                //         ]),
                //         {
                //             sort: s => s.createdAt(SortDirection.ASCENDING)
                //         })
                //         .subscribe(async msg => {
                //             const {items, isSynced} = msg;
                //             if (localShowLogs) {
                //                 console.log(`AmpUserApptAnimalsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                //                 console.log('AmpUserApptAnimalsProvider a [Snapshot] items', items);
                //             }
                //             if (isSynced) {
                //                 setAmpUserApptAnimalsRecs(items);
                //             }
                //         });
                //     // Remember to unsubscribe when the component unmounts
                //     return () => subscription.unsubscribe();
            // }
            else if (localApptIds.length > 0) {
                // TODO - MAKE SAME WORK FOR LOCALANIMALIDS
                const subscription = DataStore.observeQuery(AppointmentAnimal,
                    (a) => a.or((a) =>
                        localApptIds.map(apptId => a.appointmentId.eq(apptId))
                    ),
                    {
                        sort: s => s.createdAt(SortDirection.ASCENDING)
                    })
                    .subscribe(async msg => {
                        const {items, isSynced} = msg;
                        if (localShowLogs) {
                            // console.log(`AmpUserApptAnimalsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                            // console.log('AmpUserApptAnimalsProvider a [Snapshot] items', items);
                        }
                        if (isSynced) {
                            setAmpUserApptAnimalsRecs(items);
                            if (items) {
                                const  relatedAnimalRecs = [];
                                const animalsByAppts = {};
                                for await (const item of items) {
                                    const animal = await DataStore.query(Animal, item.animalId);
                                    relatedAnimalRecs.push(animal);
                                    if (animalsByAppts[item.appointmentId]) {
                                        animalsByAppts[item.appointmentId].push(animal);
                                    } else {
                                        animalsByAppts[item.appointmentId] = [animal];
                                    }
                                }
                                setAnimalRecs(relatedAnimalRecs);
                                setAnimalsRecsByApptId(animalsByAppts);
                            }
                        }
                    });
                // Remember to unsubscribe when the component unmounts
                return () => subscription.unsubscribe();
            }
            else if (localAnimalIds.length > 0) {
                const subscription = DataStore.observeQuery(AppointmentAnimal,
                    (a) => a.or(a => [
                        localAnimalIds.map(animalId => a.animalId.eq(animalId))
                    ]),
                    {
                        sort: s => s.createdAt(SortDirection.ASCENDING)
                    })
                    .subscribe(async msg => {
                        const {items, isSynced} = msg;
                        if (localShowLogs) {
                            // console.log(`AmpUserApptAnimalsProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                            // console.log('AmpUserApptAnimalsProvider a [Snapshot] items', items);
                        }
                        if (isSynced) {
                            setAmpUserApptAnimalsRecs(items);
                        }
                    });
                // Remember to unsubscribe when the component unmounts
                return () => subscription.unsubscribe();
            }
        }
        else {
            if (localShowLogs) {
                console.log('AmpUserApptAnimalsProvider a [ERROR] ampUser is null');
            }
        }
    }, [apptId, animalId, localApptIds, localAnimalIds, localShowLogs, ampUser]); // Dependency array now includes regionId

    return (
        <AmpUserApptAnimalsContext.Provider value={{ ampUserApptAnimalsRecs, setLocalAnimalIds, setLocalApptIds, animalRecs, animalsRecsByApptId }}>
            {children}
        </AmpUserApptAnimalsContext.Provider>
    );
}

export default AmpUserApptAnimalsProvider;
