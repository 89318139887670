import {Animal, AnimalNote, ShoePackage} from "../../../models";
import {modelRouterPathAttrFactory} from "../../../utils/breadcrumbsRoutes";
import {DataStore} from "aws-amplify";

// export async function createAnimalRecWithNoteShoePkgRecs(animalFormValues, animalNoteFormValues, shoePkgFormValues, clientId, ampUserId, showLogs) {
//     const resObj = {};
//     let newAnimalRec;
//     try {
//         const recRouterPath = modelRouterPathAttrFactory(`${animalFormValues.name}-${animalFormValues.animalType}-${clientId}-${ampUserId}`);
//         newAnimalRec = await DataStore.save(new Animal({
//             name: animalFormValues.name,
//             animalType: animalFormValues.animalType,
//             animalStatus: animalFormValues.animalStatus,
//             temperament: animalFormValues.temperament,
//             stallNumber: animalFormValues.stallNumber,
//             isEdited: false,
//             isDeleted: false,
//             clientID: clientId,
//             ampuserID: ampUserId,
//             routerPath: recRouterPath,
//             breed: animalFormValues.breed,
//             color: animalFormValues.color,
//             discipline: animalFormValues.discipline,
//             age: animalFormValues.age,
//             isMiniature: animalFormValues.isMiniature,
//             recNotes: animalFormValues.recNotes,
//             birthdate: animalFormValues.birthday,
//             addedShoeTaskAmount: animalFormValues.addedShoeTaskAmount,
//             addedTrimTaskAmount: animalFormValues.addedTrimTaskAmount,
//             addedShoeTaskDuration: animalFormValues.addedShoeTaskDuration,
//             addedTrimTaskDuration: animalFormValues.addedTrimTaskDuration,
//             shoeTaskCyclePeriodWeeks: animalFormValues.shoeTaskCyclePeriodWeeks,
//             trimTaskCyclePeriodWeeks: animalFormValues.trimTaskCyclePeriodWeeks,
//         }));
//         if (showLogs) {
//             console.log('createAnimalRecWithNoteShoePkgRecs newAnimalRec', newAnimalRec);
//         }
//         resObj.success = true;
//         resObj.newAnimalRec = newAnimalRec;
//     } catch (error) {
//         console.error('createAnimalRecWithNoteShoePkgRecs create new animal rec error', error);
//         resObj.success = false;
//         resObj.error = error;
//         return resObj;
//     }
//     if (newAnimalRec && newAnimalRec?.id) {
//         if (animalNoteFormValues && animalNoteFormValues.note) {
//             const newAnimalNoteRes = await createAnimalNoteRec(animalNoteFormValues.note, newAnimalRec.id, ampUserId, showLogs);
//             if (showLogs) {
//                 console.log('createAnimalRecWithNoteShoePkgRecs newAnimalNoteRes', newAnimalNoteRes);
//             }
//             resObj.newAnimalNoteRes = newAnimalNoteRes;
//         }
//         if (shoePkgFormValues) {
//             const newShoePackageRes = await createShoePackageRec(shoePkgFormValues, newAnimalRec.id, ampUserId, showLogs);
//             if (showLogs) {
//                 console.log('createAnimalRecWithNoteShoePkgRecs newShoePackageRes', newShoePackageRes);
//             }
//             resObj.newShoePackageRes = newShoePackageRes
//         }
//     }
//     // return animal;
//     return resObj;
// }

export async function createAnimalRec(animalFormValues, ampUserId, showLogs) {
    const resObj = {};
    let newAnimalRec;
    try {
        const recRouterPath = modelRouterPathAttrFactory(`${animalFormValues.name}-${animalFormValues.animalType}-${animalFormValues.clientId}-${ampUserId}`);
        newAnimalRec = await DataStore.save(new Animal({
            name: animalFormValues.name,
            animalType: animalFormValues.animalType,
            animalStatus: animalFormValues.animalStatus,
            temperament: animalFormValues.temperament,
            stallNumber: animalFormValues.stallNumber,
            isEdited: false,
            isDeleted: false,
            clientID: animalFormValues.clientId,
            ampuserID: ampUserId,
            routerPath: recRouterPath,
            breed: animalFormValues.breed,
            color: animalFormValues.color,
            discipline: animalFormValues.discipline,
            age: animalFormValues.age,
            isMiniature: animalFormValues.isMiniature,
            recNotes: animalFormValues.recNotes,
            birthday: animalFormValues?.birthday || null,
            addedShoeTaskAmount: animalFormValues.addedShoeTaskAmount,
            addedTrimTaskAmount: animalFormValues.addedTrimTaskAmount,
            addedShoeTaskDuration: animalFormValues.addedShoeTaskDuration,
            addedTrimTaskDuration: animalFormValues.addedTrimTaskDuration,
            shoeTaskCyclePeriodWeeks: animalFormValues.shoeTaskCyclePeriodWeeks,
            trimTaskCyclePeriodWeeks: animalFormValues.trimTaskCyclePeriodWeeks,
        }));
        if (showLogs) {
            console.log('createAnimalRecWithNoteShoePkgRecs newAnimalRec', newAnimalRec);
        }
        resObj.success = true;
        resObj.newAnimalRec = newAnimalRec;
    } catch (error) {
        console.error('createAnimalRecWithNoteShoePkgRecs create new animal rec error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    return resObj;
}

export async function createBaseAnimalRec(animalFormValues, clientId, ampUserId, showLogs) {
    const resObj = {};
    let newAnimalRec;
    try {
        const recRouterPath = modelRouterPathAttrFactory(`${animalFormValues.name}-${animalFormValues.animalType}-${clientId}-${ampUserId}`);
        newAnimalRec = await DataStore.save(new Animal({
            name: animalFormValues.name,
            animalType: animalFormValues.animalType,
            animalStatus: animalFormValues.animalStatus,
            temperament: animalFormValues.temperament,
            stallNumber: animalFormValues.stallNumber,
            isEdited: false,
            isDeleted: false,
            clientID: clientId,
            ampuserID: ampUserId,
            routerPath: recRouterPath,
            breed: animalFormValues.breed,
            color: animalFormValues.color,
            discipline: animalFormValues.discipline,
            age: animalFormValues.age,
            isMiniature: animalFormValues.isMiniature,
            recNotes: animalFormValues.recNotes,
            birthdate: animalFormValues.birthday,
            addedShoeTaskAmount: animalFormValues.addedShoeTaskAmount,
            addedTrimTaskAmount: animalFormValues.addedTrimTaskAmount,
            addedShoeTaskDuration: animalFormValues.addedShoeTaskDuration,
            addedTrimTaskDuration: animalFormValues.addedTrimTaskDuration,
            shoeTaskCyclePeriodWeeks: animalFormValues.shoeTaskCyclePeriodWeeks,
            trimTaskCyclePeriodWeeks: animalFormValues.trimTaskCyclePeriodWeeks,
        }));
        if (showLogs) {
            console.log('createAnimalRecWithNoteShoePkgRecs newAnimalRec', newAnimalRec);
        }
        resObj.success = true;
        resObj.newAnimalRec = newAnimalRec;
    } catch (error) {
        console.error('createAnimalRecWithNoteShoePkgRecs create new animal rec error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    return resObj;
}

// TODO - UPDATE EDIT ANIMAL FORM TO HANDLE NEW RES OBJ
export async function createAnimalNoteRec(animalNoteText, animalId, ampUserId, showLogs) {
    const resObj = {};
    try {
        const modelRouterPathAttr = modelRouterPathAttrFactory(`create-animal-note-${animalId}-${ampUserId || 'ampUserId'}`);
        const newAnimalNoteRec = await DataStore.save(new AnimalNote({
            note: animalNoteText,
            isEdited: false,
            animalID: animalId,
            isDeleted: false,
            routerPath: modelRouterPathAttr,
        }));
        if (showLogs) {
            console.log('createAnimalNoteRec newAnimalNoteRec', newAnimalNoteRec);
        }
        resObj.success = true;
        resObj.newAnimalNoteRec = newAnimalNoteRec;
    } catch (error) {
        console.error('createAnimalNoteRec error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return animalNote;
    return resObj;
}

// TODO - UPDATE EDIT ANIMAL FORM TO HANDLE NEW RES OBJ
export async function createShoePackageRec(shoePackageValues, animalId, ampUserId, showLogs) {
    // console.log('createShoePackageRec shoePackageValues', shoePackageValues);
    const resObj = {};
    try {
        const modelRouterPathAttr = modelRouterPathAttrFactory(`create-shoe-package-${animalId}-${ampUserId}`);
        const newShoePackageRec = await DataStore.save(new ShoePackage({
            frontLeft: shoePackageValues.frontLeft,
            frontRight: shoePackageValues.frontRight,
            rearLeft: shoePackageValues.rearLeft,
            rearRight: shoePackageValues.rearRight,
            animalID: animalId,
            isDeleted: false,
            routerPath: modelRouterPathAttr,
        }));
        if (showLogs) {
            console.log('createShoePackageRec newShoePackageRec', newShoePackageRec);
        }
        resObj.success = true;
        resObj.newShoePackageRec = newShoePackageRec;
    } catch (error) {
        console.error('createShoePackageRec error', error);
        resObj.success = false;
        resObj.error = error;
        return resObj;
    }
    // return shoePackage;
    return resObj;
}

