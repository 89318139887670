import React, { useState, useEffect, useContext } from "react";
import BaseStyledDeleteButton from "../components/buttons/BaseStyledDeleteButton";
import StyledListCard from "../components/cards/StyledListCard";
import {toTitleCase} from "../utils/text";
import {dateFromAWSDateTime} from "../utils/datesTimes";
import {breadcrumbRoutesApptsScreen} from "../utils/breadcrumbsRoutes";
import DeleteShoeTaskConfirmationDialog from "../components/modals/delete/DeleteShoeTaskModal";
import DeleteTrimTaskConfirmationDialog from "../components/modals/delete/DeleteTrimTaskModal";
import DeleteApptNoteConfirmationDialog from "../components/modals/delete/DeleteApptNoteModal";
import DeleteApptAnimalConfirmationDialog from "../components/modals/delete/DeleteApptAnimalModal";
import DeleteApptModal from "../components/modals/delete/DeleteApptModal";
import {useNavigate} from "react-router-dom";
import ApptShoeTasksContext from "../data/contexts/ApptShoeTasksContext";
import ApptTrimTasksContext from "../data/contexts/ApptTrimTasksContext";
import ApptNotesContext from "../data/contexts/ApptNotesContext";
import AmpUserApptAnimalsContext from "../data/contexts/AmpUserApptAnimalsContext";
import AmpUserApptsContext from "../data/contexts/AmpUserApptsContext";
import {deleteAppointmentNoteById,
    deleteShoeTaskById,
    deleteTrimTaskById,
    deleteAppointmentAnimalById
} from "../data/delete/deleteRecords";
import {fetchApptAnimalByIds, fetchApptAnimalByApptId} from "../data/get/fetchRecords";
import {updateAppointment} from "../data/update/appt/updateAppt";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import BaseNavigateButton from "../components/links/BaseNavigateButton";
import StyledCardContentTypography from "../components/text/StyledCardContentTypography";
import Divider from "@mui/material/Divider";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import Box from "@mui/material/Box";
import StyledListBox from "../components/boxes/StyledListBox";
import {useResponsiveViewport} from "../data/providers/ResponsiveViewportProvider";
import { handleDeleteApptModalClose,
    handleDeleteApptCancel,
    handleDeleteApptConfirm } from "../components/modals/helpers";

export default function ApptInfoScreen(props) {
    const [showLogs, setShowLogs] = useState(false);
    const [ampUser, setAmpUser] = useState(null);
    const [apptRec, setApptRec] = useState(null);
    const [apptClientRec, setApptClientRec] = useState(null);
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]); // [{path: '', name: ''}, ...

    // these are animals for this appt, not the related ApptAnimal recs
    const [apptAnimals, setApptAnimals] = useState([]);
    const [apptAnimalRecs, setApptAnimalRecs] = useState([]);
    const [apptNotes, setApptNotes] = useState([]);
    const [apptShoeTasks, setApptShoeTasks] = useState([]);
    const [apptTrimTasks, setApptTrimTasks] = useState([]);
    const [tasksByAnimalId, setTasksByAnimalId] = useState({});
    const [updateApptAmount, setUpdateApptAmount] = useState(false);
    const [updatedApptAmount, setUpdatedApptAmount] = useState(0);
    const [updateApptDuration, setUpdateApptDuration] = useState(false);
    const [updatedApptDuration, setUpdatedApptDuration] = useState(0);

    const [modelRecToDelete, setModelRecToDelete] = useState(null);
    const [shoeTaskToDelete, setShoeTaskToDelete] = useState(null);
    const [trimTaskToDelete, setTrimTaskToDelete] = useState(null);
    const [taskToDeleteAnimalAllTasks, setTaskToDeleteAnimalAllTasks] = useState([]);
    const [apptNoteToDelete, setApptNoteToDelete] = useState(null);
    const [apptAnimalRecToDelete, setApptAnimalRecToDelete] = useState(null);
    const [animalToDelete, setAnimalToDelete] = useState(null);

    const [showDeleteApptModal, setShowDeleteApptModal] = useState(false);
    const [deleteApptRes, setDeleteApptRes] = useState({});

    const [showDeleteShoeTaskModal, setShowDeleteShoeTaskModal] = useState(false);
    const [showDeleteTrimTaskModal, setShowDeleteTrimTaskModal] = useState(false);
    const [showDeleteApptNoteModal, setShowDeleteApptNoteModal] = useState(false);
    const [showDeleteApptAnimalModal, setShowDeleteApptAnimalModal] = useState(false);

    const [shoeTasksByAnimal, setShoeTasksByAnimal] = useState({});
    const [trimTasksByAnimal, setTrimTasksByAnimal] = useState({});
    const [animalByShoeTask, setAnimalByShoeTask] = useState({});
    const [animalByTrimTask, setAnimalByTrimTask] = useState({});

    const { apptShoeTasksRecs, shoeTaskRecsByAnimalId, animalRecsByShoeTaskId } = useContext(ApptShoeTasksContext);
    const { apptTrimTasksRecs, trimTaskRecsByAnimalId, animalRecsByTrimTaskId } = useContext(ApptTrimTasksContext);
    const { apptNotesRecs } = useContext(ApptNotesContext);
    const { ampUserApptAnimalsRecs, animalRecs, animalsRecsByApptId } = useContext(AmpUserApptAnimalsContext);
    const { ampUserApptRec } = useContext(AmpUserApptsContext);

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const navigate = useNavigate();

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesApptsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // useEffect( console.log( ampUserApptAnimalsRecs, animalRecs, animalsRecsByApptId, ampUserApptRec
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ApptInfoScreen [ampUserApptAnimalsRecs]', ampUserApptAnimalsRecs);
    //         console.log('ApptInfoScreen [animalRecs]', animalRecs);
    //         console.log('ApptInfoScreen [animalsRecsByApptId]', animalsRecsByApptId);
    //         console.log('ApptInfoScreen [ampUserApptRec]', ampUserApptRec);
    //     }
    // }, [showLogs, ampUserApptAnimalsRecs, animalRecs, animalsRecsByApptId, ampUserApptRec]);

    // useEffect( fetchAllApptAnimalRecs();
    useEffect(() => {
        if (ampUserApptAnimalsRecs) {
            setApptAnimalRecs(ampUserApptAnimalsRecs);
        }
        else if (apptRec) {
            const fetchAllApptAnimalRecs = async () => {
                const apptAnimalRecs = await fetchApptAnimalByApptId(apptRec.id);
                setApptAnimalRecs(apptAnimalRecs);
            }
            fetchAllApptAnimalRecs();
        }
    }, [ampUserApptAnimalsRecs, apptRec]);

    // useEffect( console.log( apptAnimalRecs
    useEffect(() => {
        if (showLogs) {
            console.log('ApptInfoScreen [apptAnimalRecs]', apptAnimalRecs);
        }
    }, [showLogs, apptAnimalRecs]);

    // TODO - check back if we still need this
    async function updateApptAnimals() {
        const apptAnimalRecs = await fetchApptAnimalByApptId(apptRec.id);
        setApptAnimalRecs(apptAnimalRecs);
        setApptAnimals(apptAnimalRecs.animalRecs);
    }

    // useEffect( setTotalApptAmount(sumAmount);
    // useEffect(() => {
    //     if (updateApptAmount) {
    //         let totalApptAmount = 0;
    //         const shoeTasksAmount = apptShoeTasks.reduce((accumulator, currentObj) => {
    //             return accumulator + currentObj.amount;
    //         }, 0);
    //         // console.log('shoeTasksAmount', shoeTasksAmount);
    //         const trimTasksAmount = apptTrimTasks.reduce((accumulator, currentObj) => {
    //             return accumulator + currentObj.amount;
    //         }, 0);
    //         // console.log('trimTasksAmount', trimTasksAmount);
    //         totalApptAmount = shoeTasksAmount + trimTasksAmount;
    //         // console.log('totalApptAmount', totalApptAmount);
    //         setUpdatedApptAmount(totalApptAmount);
    //         setUpdateApptAmount(false);
    //     }
    // }, [updateApptAmount]);

    // useEffect( setTotalApptDuration(sumDuration);
    // useEffect(() => {
    //     if (updateApptDuration) {
    //         let totalApptDuration = 0;
    //         const shoeTasksDuration = apptShoeTasks.reduce((accumulator, currentObj) => {
    //             return accumulator + currentObj.duration;
    //         }, 0);
    //         // console.log('shoeTasksDuration', shoeTasksDuration);
    //         const trimTasksDuration = apptTrimTasks.reduce((accumulator, currentObj) => {
    //             return accumulator + currentObj.duration;
    //         }, 0);
    //         // console.log('trimTasksDuration', trimTasksDuration);
    //         totalApptDuration = shoeTasksDuration + trimTasksDuration;
    //         // console.log('totalApptDuration', totalApptDuration);
    //         setUpdatedApptDuration(totalApptDuration);
    //         setUpdateApptDuration(false);
    //     }
    // }, [updateApptDuration]);

    // useEffect( console.log( updateApptAmount, updateApptDuration
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('updateApptAmount', updateApptAmount);
    //         console.log('updateApptDuration', updateApptDuration);
    //     }
    // }, [showLogs, updateApptAmount, updateApptDuration]);

    // useEffect( console.log( updatedApptAmount, updatedApptDuration
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('updatedApptAmount', updatedApptAmount);
    //         console.log('updatedApptDuration', updatedApptDuration);
    //     }
    // }, [showLogs, updatedApptAmount, updatedApptDuration]);

    // useEffect( update appt. amount, duration
    useEffect(() => {
        if (updateApptAmount || updateApptDuration) {
                const updateApptAmountDuration = async () => {
                    if (showLogs) {
                        console.log('UPDATING APPT REC');
                    }
                    const updatedAmountDurationVals = {}
                    if (updateApptAmount) {
                        let totalApptAmount = 0;
                        const shoeTasksAmount = apptShoeTasks.reduce((accumulator, currentObj) => {
                            return accumulator + currentObj.amount;
                        }, 0);
                        // console.log('shoeTasksAmount', shoeTasksAmount);
                        const trimTasksAmount = apptTrimTasks.reduce((accumulator, currentObj) => {
                            return accumulator + currentObj.amount;
                        }, 0);
                        // console.log('trimTasksAmount', trimTasksAmount);
                        totalApptAmount = shoeTasksAmount + trimTasksAmount;
                        // console.log('updateApptAmountDuration totalApptAmount', totalApptAmount);
                        setUpdatedApptAmount(totalApptAmount);
                        // setUpdateApptAmount(false);
                        updatedAmountDurationVals.amount = totalApptAmount;
                    }
                    if (updateApptDuration) {
                        let totalApptDuration = 0;
                        const shoeTasksDuration = apptShoeTasks.reduce((accumulator, currentObj) => {
                            return accumulator + currentObj.duration;
                        }, 0);
                        // console.log('shoeTasksDuration', shoeTasksDuration);
                        const trimTasksDuration = apptTrimTasks.reduce((accumulator, currentObj) => {
                            return accumulator + currentObj.duration;
                        }, 0);
                        // console.log('trimTasksDuration', trimTasksDuration);
                        totalApptDuration = shoeTasksDuration + trimTasksDuration;
                        // console.log('updateApptAmountDuration totalApptDuration', totalApptDuration);
                        setUpdatedApptDuration(totalApptDuration);
                        // setUpdateApptDuration(false);
                        updatedAmountDurationVals.apptDuration = totalApptDuration;
                    }
                    await updateAppointment(apptRec, updatedAmountDurationVals, showLogs);
                    setUpdateApptAmount(false);
                    setUpdateApptDuration(false);
                }
                updateApptAmountDuration();
        }
    }, [showLogs, updateApptAmount, updateApptDuration]);

    // useEffect( console.log( totalApptAmount, totalApptDuration
    // useEffect(() => {
    //     // console.log('BLOCK FOUR');
    //     if (showLogs) {
    //         console.log('totalApptAmount', totalApptAmount);
    //         console.log('totalApptDuration', totalApptDuration);
    //     }
    //     // console.log('BLOCK FIVE');
    // }, [showLogs, totalApptAmount, totalApptDuration]);

    // TODO - BIG TODO - MOVE MODAL ACTIONS TO MODAL HELPERS
    const handleDeleteApptModalOpen = () => {
        setShowDeleteApptModal(true);
    }

    const handleDeleteApptAnimalModalOpen = async (animalId) => {
        const apptAnimalRec = await fetchApptAnimalByIds(apptRec.id, animalId);
        setApptAnimalRecToDelete(apptAnimalRec);
        setShowDeleteApptAnimalModal(true);
    }

    const handleDeleteShoeTaskModalOpen = () => {
        setShowDeleteShoeTaskModal(true);
    };

    const handleDeleteTrimTaskModalOpen = () => {
        setShowDeleteTrimTaskModal(true);
    };

    // useEffect( setTaskToDeleteAnimalAllTasks(animalAllTasks);
    useEffect(() => {
        const animalAllTasks = [];
        if (shoeTaskToDelete) {
            const animalId = shoeTaskToDelete.animalID;
            const animalShoeTasks = shoeTasksByAnimal[animalId];
            animalAllTasks.push(...animalShoeTasks);
        }
        if (trimTaskToDelete) {
            const animalId = trimTaskToDelete.animalID;
            const animalTrimTasks = trimTasksByAnimal[animalId];
            animalAllTasks.push(...animalTrimTasks);
        }
        setTaskToDeleteAnimalAllTasks(animalAllTasks);
    }, [shoeTaskToDelete, trimTaskToDelete]);

    // useEffect( console.log( taskToDeleteAnimalAllTasks
    useEffect(() => {
        if (showLogs) {
            console.log('taskToDeleteAnimalAllTasks', taskToDeleteAnimalAllTasks);
        }
    }, [showLogs, taskToDeleteAnimalAllTasks]);

    const handleDeleteApptNoteModalOpen = () => {
        setShowDeleteApptNoteModal(true);
    }

    // const handleDeleteApptModalClose = () => {
    //     setShowDeleteApptModal(false);
    //     setModelRecToDelete(null);
    // };

    const onDeleteApptModalClose = () => {
        handleDeleteApptModalClose(setShowDeleteApptModal, setModelRecToDelete, showLogs);
    };

    const handleDeleteShoeTaskModalClose = () => {
        setShowDeleteShoeTaskModal(false);
        setShoeTaskToDelete(null);
    };

    const handleDeleteTrimTaskModalClose = () => {
        setShowDeleteTrimTaskModal(false);
        setTrimTaskToDelete(null);
    };

    const handleDeleteApptNoteModalClose = () => {
        setShowDeleteApptNoteModal(false);
        setApptNoteToDelete(null);
    }

    const handleDeleteApptAnimalModalClose = () => {
        setShowDeleteApptAnimalModal(false);
        setApptAnimalRecToDelete(null);
        setAnimalToDelete(null);
    }

    // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
    // const handleDeleteApptConfirm = async () => {
    //     if (showLogs) {
    //         console.log('The Appt record has been deleted.', modelRecToDelete);
    //     }
    //     // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
    //     await deleteAppointmentById(modelRecToDelete, showLogs);
    //     handleDeleteApptModalClose();
    //     navigate('/appointments');
    // };

    // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO

    const onDeleteApptConfirm = async () => {
        await handleDeleteApptConfirm(modelRecToDelete, setDeleteApptRes, setShowDeleteApptModal, setModelRecToDelete, showLogs);
        // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
        navigate('/appointments');
    };

    const onDeleteApptCancel = () => {
        handleDeleteApptCancel(modelRecToDelete, setShowDeleteApptModal, setModelRecToDelete, showLogs);
    };

    // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
    const handleDeleteApptAnimalConfirm = async () => {
        if (showLogs) {
            console.log('The ApptAnimal record has been deleted.', modelRecToDelete);
        }
        // TODO - MAKE SURE ALL RELATED RECS ARE DELETED ALSO
        await deleteAppointmentAnimalById(apptAnimalRecToDelete, showLogs);
        handleDeleteApptAnimalModalClose();

        // TODO - toggle this back in if delete does not update provider
        // await updateApptAnimals();

        setUpdateApptDuration(true);
        setUpdateApptAmount(true);
        // TODO - NEED TO UPDATE APPT AMOUNT, DURATION TOTALS ON ANIMAL AND TASK DELETES
        // navigate('/appointments');
    };

    const handleDeleteShoeTaskConfirm = async (deleteTaskApptAnimal) => {
        // console.log('handleDeleteShoeTaskConfirm shoeTaskToDelete', shoeTaskToDelete);
        // console.log('handleDeleteShoeTaskConfirm deleteTaskApptAnimal', deleteTaskApptAnimal);
        if (deleteTaskApptAnimal) {
            // const thisTaskAnimalId = modelRec.animalID;
            const thisTaskAnimal = animalByShoeTask[shoeTaskToDelete.id];
            // console.log('handleDeleteShoeTaskConfirm thisTaskAnimal', thisTaskAnimal);
            const taskApptAnimalRec = await fetchApptAnimalByIds(apptRec.id, thisTaskAnimal.id);
            // console.log('handleDeleteShoeTaskConfirm taskApptAnimalRec', taskApptAnimalRec);
            setApptAnimalRecToDelete(taskApptAnimalRec);
            await deleteAppointmentAnimalById(taskApptAnimalRec, showLogs);
            if (showLogs) {
                console.log('The ApptAnimal has been deleted.', taskApptAnimalRec);
                console.log('The ApptAnimal Shoe Task has been deleted.', shoeTaskToDelete);
                }
        } else {
            // Delete just the task but leave the ApptAnimal
            await deleteShoeTaskById(shoeTaskToDelete, showLogs);
            if (showLogs) {
                console.log('The Shoe Task record has been deleted.', shoeTaskToDelete);
            }
        }
        handleDeleteShoeTaskModalClose();
        setUpdateApptDuration(true);
        setUpdateApptAmount(true);
    };

    const handleDeleteTrimTaskConfirm = async (deleteTaskApptAnimal) => {
        // console.log('handleDeleteTrimTaskConfirm trimTaskToDelete', trimTaskToDelete);
        // console.log('handleDeleteTrimTaskConfirm deleteTaskApptAnimal', deleteTaskApptAnimal);
        if (deleteTaskApptAnimal) {
            // const thisTaskAnimalId = modelRec.animalID;
            const thisTaskAnimal = animalByTrimTask[trimTaskToDelete.id];
            // console.log('handleDeleteTrimTaskConfirm thisTaskAnimal', thisTaskAnimal);
            const taskApptAnimalRec = await fetchApptAnimalByIds(apptRec.id, thisTaskAnimal.id);
            // console.log('handleDeleteTrimTaskConfirm taskApptAnimalRec', taskApptAnimalRec);
            setApptAnimalRecToDelete(taskApptAnimalRec);
            await deleteAppointmentAnimalById(taskApptAnimalRec, showLogs);
            if (showLogs) {
                console.log('The ApptAnimal has been deleted.', taskApptAnimalRec);
                console.log('The ApptAnimal Trim Task has been deleted.', trimTaskToDelete);
            }
        } else {
            // Delete just the task but leave the ApptAnimal
            await deleteTrimTaskById(trimTaskToDelete, showLogs);
            if (showLogs) {
                console.log('The Trim Task record has been deleted.', trimTaskToDelete);
            }
        }
        handleDeleteTrimTaskModalClose();
        setUpdateApptDuration(true);
        setUpdateApptAmount(true);
    };

    const handleDeleteApptNoteConfirm = async (modelRec) => {
        await deleteAppointmentNoteById(modelRec, showLogs);
        if (showLogs) {
            console.log('The Appointment Note record has been deleted.', modelRec);
        }
        handleDeleteApptNoteModalClose();
    }

    // useEffect( setApptShoeTasks(apptShoeTasksRecs); setShoeTasksByAnimal(shoeTaskRecsByAnimalId);
    useEffect(() => {
        if (apptShoeTasksRecs) {
            setApptShoeTasks(apptShoeTasksRecs);
        }
        if (shoeTaskRecsByAnimalId) {
            setShoeTasksByAnimal(shoeTaskRecsByAnimalId);
        }
        if (animalRecsByShoeTaskId) {
            setAnimalByShoeTask(animalRecsByShoeTaskId);
        }
    }, [apptShoeTasksRecs, shoeTaskRecsByAnimalId, animalRecsByShoeTaskId]);

    // useEffect( setApptTrimTasks(apptTrimTasksRecs); setTrimTasksByAnimal(trimTaskRecsByAnimalId);
    useEffect(() => {
        if (apptTrimTasksRecs) {
            setApptTrimTasks(apptTrimTasksRecs);
        }
        if (trimTaskRecsByAnimalId) {
            setTrimTasksByAnimal(trimTaskRecsByAnimalId);
        }
        if (animalRecsByTrimTaskId) {
            setAnimalByTrimTask(animalRecsByTrimTaskId);
        }
    }, [apptTrimTasksRecs, trimTaskRecsByAnimalId, animalRecsByTrimTaskId]);

    // useEffect( setTasksByAnimalId(tasksByAnimal);
    useEffect(() => {
        const tasksByAnimal = {
            shoe: {},
            trim: {},
        };
        for (const shoeTask of apptShoeTasks) {
            if (shoeTask.animalID in tasksByAnimal.shoe) {
                tasksByAnimal.shoe[shoeTask.animalID].push(shoeTask);
            } else {
                tasksByAnimal.shoe[shoeTask.animalID] = [shoeTask];
            }
        }
        for (const trimTask of apptTrimTasks) {
            if (trimTask.animalID in tasksByAnimal.trim) {
                tasksByAnimal.trim[trimTask.animalID].push(trimTask);
            } else {
                tasksByAnimal.trim[trimTask.animalID] = [trimTask];
            }
        }
        setTasksByAnimalId(tasksByAnimal);
    }, [apptShoeTasks, apptTrimTasks]);

    // useEffect( setApptNotes(apptNotesRecs);
    useEffect(() => {
        if (apptNotesRecs) {
            setApptNotes(apptNotesRecs);
        }
    }, [apptNotesRecs]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser]);

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props.showLogs]);

    // useEffect( setApptRec(ampUserApptRec); setApptRec(props.apptRec);
    useEffect(() => {
        if (ampUserApptRec) {
            setApptRec(ampUserApptRec);
        }
        else if (props?.apptRec) {
            setApptRec(props.apptRec);
        }
    }, [ampUserApptRec, props.apptRec]);

    // useEffect( setApptAnimals( setApptAnimals(animalRecs);, props.animalRecs);
    useEffect(() => {
        if (animalRecs) {
            setApptAnimals(animalRecs);
        }
        else if (props?.animalRecs) {
            setApptAnimals(props.animalRecs);
        }
    }, [animalRecs, props.animalRecs]);

    // useEffect( setApptClientRec(props.clientRec);
    useEffect(() => {
        if (props?.clientRec) {
            if (props.clientRec.length > 0) {
                setApptClientRec(props.clientRec[0]);
            }
            else {
                setApptClientRec(props.clientRec);
            }
        }
    }, [props.clientRec]);

    // useEffect( console.log( apptAnimals, apptRec, apptNotes
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ApptInfoScreen [apptRec]', apptRec);
    //         console.log('ApptInfoScreen [apptClientRec]', apptClientRec);
    //         console.log('ApptInfoScreen [apptAnimals]', apptAnimals);
    //         console.log('ApptInfoScreen [apptNotes]', apptNotes);
    //     }
    // }, [showLogs, apptAnimals, apptRec, apptNotes, apptClientRec]);

    // useEffect( console.log( animalToDelete, apptAnimalRecToDelete
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('animalToDelete', animalToDelete);
    //         console.log('apptAnimalRecToDelete', apptAnimalRecToDelete);
    //     }
    // }, [showLogs, animalToDelete, apptAnimalRecToDelete]);

    // useEffect( console.log( apptShoeTasks, apptTrimTasks, shoeTasksByAnimal, trimTasksByAnimal, animalByShoeTask, animalByTrimTask, tasksByAnimalId
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('ApptInfoScreen [apptShoeTasks]', apptShoeTasks);
    //         console.log('ApptInfoScreen [apptTrimTasks]', apptTrimTasks);
    //         console.log('ApptInfoScreen [shoeTasksByAnimal]', shoeTasksByAnimal);
    //         console.log('ApptInfoScreen [trimTasksByAnimal]', trimTasksByAnimal);
    //         console.log('ApptInfoScreen [animalByShoeTask]', animalByShoeTask);
    //         console.log('ApptInfoScreen [animalByTrimTask]', animalByTrimTask);
    //         console.log('ApptInfoScreen [tasksByAnimalId]', tasksByAnimalId);
    //     }
    // }, [showLogs, apptShoeTasks, apptTrimTasks, shoeTasksByAnimal, trimTasksByAnimal, animalByShoeTask, animalByTrimTask, tasksByAnimalId]);

    function displayApptAnimalsAccordion() {
        // TODO - ADD BUTTON TO Remove Animal,
        //  Update Animal, Add rest of Animal attrs., ADD ANIMAL SHOE PACKAGE(S)
        if (apptAnimals) {
            return (
                <div>
                    <Accordion
                        disabled={apptAnimals.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <StyledCardContentTypography
                                text={`Appt. Animals (${apptAnimals.length})`}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {apptAnimals.map((apptAnimalRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`apptAnimal-${index}`}
                                        >
                                            <StyledCardContentTypography
                                                text={`Name: ${toTitleCase(apptAnimalRec.name)}`}
                                            />
                                            <Divider />
                                            <StyledCardContentTypography
                                                text={`Type: ${toTitleCase(apptAnimalRec.animalType)}`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Status: ${toTitleCase(apptAnimalRec.animalStatus)}`}
                                            />
                                            <Divider />
                                            {apptAnimalRec.note ? (
                                                <StyledCardContentTypography
                                                    text={`Animal Note: ${apptAnimalRec.note}`}
                                                />
                                            ): null}
                                            <Divider />
                                            {/*<BaseNavigateButton*/}
                                            {/*    label={"Update Shoe Task"}*/}
                                            {/*    navTarget={`/edit-shoe-task/${shoeTaskRec.routerPath}`}*/}
                                            {/*    navState={{shoeTaskRec}}*/}
                                            {/*/>*/}
                                            <BaseStyledDeleteButton
                                                label="Delete Appt Animal"
                                                onClick={async () => {
                                                    setAnimalToDelete(apptAnimalRec);
                                                    // setShowDeleteApptAnimalModal(true);
                                                    await handleDeleteApptAnimalModalOpen(apptAnimalRec.id);
                                                }}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Animal Found
                    </Typography>
                </StyledListCard>
            )}}

    function displayApptNotesAccordion() {
        // TODO - ADD BUTTON TO ADD APPT NOTE
        if (apptNotes) {
            return (
                <div>
                    <Accordion
                        disabled={apptNotes.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <StyledCardContentTypography
                                text={`Appt. Notes (${apptNotes.length})`}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {apptNotes.map((apptNoteRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`apptNote-${index}`}
                                        >
                                            <StyledCardContentTypography
                                                text={`Note: ${apptNoteRec.note}`}
                                            />
                                            <Divider />
                                            <Divider />
                                            <div>
                                            <BaseStyledDeleteButton
                                                label="Delete Note"
                                                onClick={() => {
                                                    setApptNoteToDelete(apptNoteRec);
                                                    handleDeleteApptNoteModalOpen();
                                                }}
                                            />
                                            </div>
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Notes Found
                    </Typography>
                </StyledListCard>
            )}
    }

    // TODO - ADD IMAGES ACCORDION, OTHER ACCORDIONS
    function displayApptInfo() {
        // if (apptRec) {
        if (apptRec && apptClientRec) {
            return (
                <div>
                    {/*<h2>Appointment Info</h2>*/}
                    <StyledListCard>
                        <StyledCardContentTypography
                            text={`Client: ${apptClientRec.name}`}
                        />
                        <StyledCardContentTypography
                            text={`Date: ${dateFromAWSDateTime(apptRec.dateTime)}`}
                        />
                        <Divider/>
                        <StyledCardContentTypography
                            text={`Time: ${apptRec.apptTime}`}
                        />
                        <Divider/>
                        <StyledCardContentTypography
                            text={`Street Address: ${apptRec.streetAddress}`}
                        />
                        <StyledCardContentTypography
                            text={`${apptRec.city}, ${apptRec.state} ${apptRec.zipCode}`}
                        />
                        <Divider/>
                        <StyledCardContentTypography
                            text={`Status: ${toTitleCase(apptRec.appointmentStatus)}`}
                        />
                        {apptRec.gateCode ? (
                            <StyledCardContentTypography
                                text={`Gate Code: ${apptRec.gateCode}`}
                            />
                            ) : null}
                        <Divider/>
                        <StyledCardContentTypography
                            text={`Duration: ${apptRec.apptDuration} mins.`}
                        />
                        <StyledCardContentTypography
                            text={`Amount: $${apptRec.amount}`}
                        />
                        <Divider/>
                        <StyledCardContentTypography
                            text={`Created At: ${dateFromAWSDateTime(apptRec.createdAt)}`}
                        />
                        <StyledCardContentTypography
                            text={`Updated At: ${dateFromAWSDateTime(apptRec.updatedAt)}`}
                        />
                        <Divider/>
                        <BaseNavigateButton
                            label={"Update Appointment"}
                            navTarget={`/edit-appt/${apptRec.routerPath}`}
                            navState={{apptRecId: apptRec.id, apptClientId: apptRec.clientID}}
                        />
                        <BaseStyledDeleteButton
                            label="Delete Appointment"
                            onClick={() => {
                                setModelRecToDelete(apptRec);
                                handleDeleteApptModalOpen();
                            }}
                        />
                    </StyledListCard>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Appointment Found
                    </Typography>
                </StyledListCard>
            )
        }
    }

    function displayShoeTasksAccordion() {
        // TODO - ADD BUTTON TO ADD SHOE TASK
        if (apptShoeTasks) {
            return (
                <div>
                    <Accordion
                        disabled={apptShoeTasks.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <StyledCardContentTypography
                                text={`Shoe Tasks (${apptShoeTasks.length})`}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {apptShoeTasks.map((shoeTaskRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`shoeTask-${index}`}
                                        >
                                            <StyledCardContentTypography
                                                text={`Animal: ${animalByShoeTask[shoeTaskRec.id] ? toTitleCase(animalByShoeTask[shoeTaskRec.id].name) : 'Unknown'}`}
                                            />
                                            <Divider />
                                            <StyledCardContentTypography
                                                text={`Amount: $${shoeTaskRec.amount}`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Added Cost: $${shoeTaskRec.addedCost}`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Total Amount: $${shoeTaskRec.amount + shoeTaskRec.addedCost}`}
                                            />
                                            <Divider />
                                            <StyledCardContentTypography
                                                text={`Duration: ${shoeTaskRec.duration} mins.`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Added Time: ${shoeTaskRec.addedTime} mins.`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Total Duration: ${shoeTaskRec.duration + shoeTaskRec.addedTime} mins.`}
                                            />
                                            <Divider />
                                            <Divider />
                                            <BaseNavigateButton
                                                label={"Update Shoe Task"}
                                                navTarget={`/edit-shoe-task/${shoeTaskRec.routerPath}`}
                                                navState={{shoeTaskRec}}
                                            />
                                            <BaseStyledDeleteButton
                                                label="Delete Shoe Task"
                                                onClick={() => {
                                                    setShoeTaskToDelete(shoeTaskRec);
                                                    handleDeleteShoeTaskModalOpen();
                                                }}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Shoe Tasks Found
                    </Typography>
                </StyledListCard>
            )}}

    function displayTrimTasksAccordion() {
        // TODO - ADD BUTTON TO ADD TRIM TASK
        if (apptTrimTasks) {
            return (
                <div>
                    <Accordion
                        disabled={apptTrimTasks.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <StyledCardContentTypography
                                text={`Trim Tasks (${apptTrimTasks.length})`}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {apptTrimTasks.map((trimTaskRec, index) => {
                                    return (
                                        <StyledListCard
                                            key={`trimTask-${index}`}
                                        >
                                            <StyledCardContentTypography
                                                text={`Animal: ${animalByTrimTask[trimTaskRec.id] ? toTitleCase(animalByTrimTask[trimTaskRec.id].name) : 'Unknown'}`}
                                            />
                                            <Divider />
                                            <StyledCardContentTypography
                                                text={`Amount: $${trimTaskRec.amount}`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Added Cost: $${trimTaskRec.addedCost}`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Total Amount: $${trimTaskRec.amount + trimTaskRec.addedCost}`}
                                            />
                                            <Divider />
                                            <StyledCardContentTypography
                                                text={`Duration: ${trimTaskRec.duration} mins.`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Added Time: ${trimTaskRec.addedTime} mins.`}
                                            />
                                            <StyledCardContentTypography
                                                text={`Total Duration: ${trimTaskRec.duration + trimTaskRec.addedTime} mins.`}
                                            />
                                            <Divider />
                                            <Divider />
                                            <BaseNavigateButton
                                                label={"Update Trim Task"}
                                                navTarget={`/edit-trim-task/${trimTaskRec.routerPath}`}
                                                navState={{trimTaskRec}}
                                            />
                                            <BaseStyledDeleteButton
                                                label="Delete Trim Task"
                                                onClick={() => {
                                                    setTrimTaskToDelete(trimTaskRec);
                                                    handleDeleteTrimTaskModalOpen();
                                                }}
                                            />
                                        </StyledListCard>
                                    )
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <StyledListCard>
                    <Typography>
                        No Trim Tasks Found
                    </Typography>
                </StyledListCard>
            )}}

    return (
        // <div>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
            }}
        >
            <h2>Appointment Info</h2>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            {apptRec ? (
                // <div>
                    <StyledListBox
                        width={isLargeScreen ? '90%' : '100%'}
                        style={{
                            color: 'white',
                            backgroundColor: 'lightgray',
                            borderTop: '1px solid black',
                            borderBottom: '1px solid black',
                            marginTop: '0.5vh',
                        }}
                    >
                        {displayApptInfo()}
                    {/*</StyledListBox>*/}
                    {displayApptAnimalsAccordion()}
                    {displayApptNotesAccordion()}
                    {displayShoeTasksAccordion()}
                    {displayTrimTasksAccordion()}
                {/*</div>*/}
                </StyledListBox>
            ) : (
                <div>
                    <h3>No Appointment Found</h3>
                </div>
            )}
            {apptRec ? (
                <div>
                    {modelRecToDelete ? (
                        <div>
                            <DeleteApptModal
                                open={showDeleteApptModal}
                                handleClose={onDeleteApptModalClose}
                                handleConfirm={onDeleteApptConfirm}
                                handleCancel={onDeleteApptCancel}
                                modelRecToDelete={modelRecToDelete}
                                showLogs={showLogs}
                                clientRec={apptClientRec}
                            />
                        </div>
                    ) : null}
                    {animalToDelete ? (
                        <div>
                            <DeleteApptAnimalConfirmationDialog
                                open={showDeleteApptAnimalModal}
                                handleClose={handleDeleteApptAnimalModalClose}
                                handleConfirm={handleDeleteApptAnimalConfirm}
                                modelRecToDelete={animalToDelete}
                                showLogs={showLogs}
                            />
                        </div>
                    ) : null}
                    {apptNoteToDelete ? (
                        <div>
                            <DeleteApptNoteConfirmationDialog
                                open={showDeleteApptNoteModal}
                                handleClose={handleDeleteApptNoteModalClose}
                                handleConfirm={handleDeleteApptNoteConfirm}
                                modelRecToDelete={apptNoteToDelete}
                                showLogs={showLogs}
                                // animalRec={animalRec}
                                apptRec={apptRec}
                            />
                        </div>
                    ) : null}
                    {shoeTaskToDelete ? (
                        <div>
                            <DeleteShoeTaskConfirmationDialog
                                open={showDeleteShoeTaskModal}
                                handleClose={handleDeleteShoeTaskModalClose}
                                handleConfirm={handleDeleteShoeTaskConfirm}
                                modelRecToDelete={shoeTaskToDelete}
                                showLogs={showLogs}
                                // animalRec={animalRec}
                                apptRec={apptRec}
                                animalLastTask={taskToDeleteAnimalAllTasks.length === 1}
                            />
                        </div>
                    ) : null}
                    {trimTaskToDelete ? (
                        <div>
                            <DeleteTrimTaskConfirmationDialog
                                open={showDeleteTrimTaskModal}
                                handleClose={handleDeleteTrimTaskModalClose}
                                handleConfirm={handleDeleteTrimTaskConfirm}
                                modelRecToDelete={trimTaskToDelete}
                                showLogs={showLogs}
                                // animalRec={animalRec}
                                apptRec={apptRec}
                                animalLastTask={taskToDeleteAnimalAllTasks.length === 1}
                            />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </Box>
        // </div>
    )
}
