import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import ClientApptsContext from "../contexts/ClientApptsContext";
import { Appointment } from '../../models';

// TODO - COMPLETE ELSE IF BLOCK, ADD BUTTON TO SHOW ALL APPTS, WHICH INCLUDES PAST APPTS.

const ClientApptsDataProvider = ({ children, ampUserId, showLogs, resetProviderState, ...props }) => {
    const [clientApptsRecs, setClientApptsRecs] = useState([]);
    const [clientFutureApptsRecs, setClientFutureApptsRecs] = useState([]);
    const [clientLastApptRec, setClientLastApptRec] = useState(null);
    const [clientNextApptRec, setClientNextApptRec] = useState(null);
    const [clientApptsShowLogs, setClientApptsShowLogs] = useState(false);
    const [clientRecId, setClientRecId] = useState(null);
    const [ampUserRecId, setAmpUserRecId] = useState(null);

    const resetContext = () => {
        setClientApptsRecs([]);
        setClientFutureApptsRecs([]);
        setClientLastApptRec(null);
        setClientNextApptRec(null);
        setClientRecId(null);
        setAmpUserRecId(null);
    }

    useEffect(() => {
        if (resetProviderState) {
            resetContext();
        }
    }, [resetProviderState]);

    // useEffect(() => {
    //     if (ampUserId) {
    //         setAmpUserRecId(ampUserId);
    //     }
    // }, [ampUserId]);

    // useEffect( setClientApptsShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setClientApptsShowLogs(showLogs);
        }
        else {
            setClientApptsShowLogs(false);
        }
    }, [showLogs]);

    // useEffect( console.log( clientApptsRecs
    useEffect(() => {
        if (clientApptsShowLogs) {
            console.log('ClientApptsDataProvider clientApptsRecs', clientApptsRecs);
        }
    }, [clientApptsRecs, clientApptsShowLogs]);

    // useEffect( console.log( ampUserRecId, clientRecId
    useEffect(() => {
        if (clientApptsShowLogs) {
            console.log('ClientApptsDataProvider clientRecId', clientRecId);
            console.log('ClientApptsDataProvider ampUserRecId', ampUserRecId);
        }
    }, [ampUserRecId, clientRecId, clientApptsShowLogs]);

    // useEffect( console.log( clientLastApptRec, clientNextApptRec
    useEffect(() => {
        if (clientApptsShowLogs) {
            console.log('ClientApptsDataProvider clientLastApptRec', clientLastApptRec);
            console.log('ClientApptsDataProvider clientNextApptRec', clientNextApptRec);
        }
    }, [clientApptsShowLogs, clientLastApptRec, clientNextApptRec]);

    // useEffect( console.log( clientFutureApptsRecs
    useEffect(() => {
        if (clientApptsShowLogs) {
            console.log('ClientApptsDataProvider clientFutureApptsRecs', clientFutureApptsRecs);
        }
    }, [clientApptsShowLogs, clientFutureApptsRecs]);


    useEffect(() => {
        if (!resetProviderState && ampUserId && props.clientId) {
            const subscription = DataStore.observeQuery(Appointment,
                (a) => a.and(a => [
                    a.ampuserID.eq(ampUserId),
                    a.clientID.eq(props.clientId),
                    a.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    console.log(`ClientApptsDataProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    console.log('ClientApptsDataProvider a [Snapshot] items', items);
                    if (isSynced) {
                        // Filter the items based on the related model's postStatus
                        // const filteredItems = await Promise.all(items.filter(async item => {
                        //     const appt = await DataStore.query(Appointment, item.apptID);
                        //     return appt.apptStatus === "LIVE"; // TODO - remove this and show all posts instead?  what about archived posts? <- later version
                        // }));
                        // Update the posts data
                        // setClientApptsRecs(filteredItems);
                        setClientApptsRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
        else if (!resetProviderState && ampUserRecId && clientRecId) {
            const subscription = DataStore.observeQuery(Appointment,
                (a) => a.and(a => [
                    a.ampuserID.eq(ampUserRecId),
                    a.clientID.eq(clientRecId),
                    a.isDeleted.ne(true)  // Filter out records where isDeleted is true
                ]),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    console.log(`ClientApptsDataProvider a [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    console.log('ClientApptsDataProvider a [Snapshot] items', items);
                    if (isSynced) {
                        // Filter the items based on the related model's postStatus
                        // const filteredItems = await Promise.all(items.filter(async item => {
                        //     const appt = await DataStore.query(Appointment, item.apptID);
                        //     return appt.apptStatus === "LIVE"; // TODO - remove this and show all posts instead?  what about archived posts? <- later version
                        // }));
                        // Update the posts data
                        // setClientApptsRecs(filteredItems);
                        setClientApptsRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [ampUserId, props.clientId, clientApptsShowLogs, ampUserRecId, clientRecId, resetProviderState]); // Dependency array now includes regionId

    useEffect(() => {
        if (!resetProviderState && clientApptsRecs.length > 0) {
            const nowUTCDt = new Date(new Date().toUTCString());
            const futureAppts = clientApptsRecs.filter(appt => {
                return new Date(appt.dateTime) > nowUTCDt;
            });
            setClientFutureApptsRecs(futureAppts);
        }
    }, [clientApptsRecs, resetProviderState]);

    // useEffect to find most recent appointment rec, which is in the past, by appointment's dateTime attribute
    useEffect(() => {
        try {
            if (!resetProviderState && clientApptsRecs.length > 0) {
                const nowUTCDt = new Date(new Date().toUTCString());

                const pastAppts = clientApptsRecs.filter(appt => {
                    const apptDate = new Date(appt.dateTime);
                    return !isNaN(apptDate.getTime()) && apptDate < nowUTCDt;
                });

                if (pastAppts.length > 0) {
                    const mostRecentPastAppt = pastAppts.reduce((latest, current) =>
                        new Date(current.dateTime) > new Date(latest.dateTime) ? current : latest
                    );
                    setClientLastApptRec(mostRecentPastAppt);
                } else {
                    setClientLastApptRec(null);
                }
            } else {
                setClientLastApptRec(null);
            }
        } catch (error) {
            console.error("Error processing appointments:", error);
            setClientLastApptRec(null);
        }
    }, [clientApptsRecs, resetProviderState]);

    // useEffect to // find next appointment rec, which is in the future, by appointment's dateTime attribute
    useEffect(() => {
        try {
            if (!resetProviderState && clientApptsRecs.length > 0) {
                const nowUTCDt = new Date(new Date().toUTCString());

                const futureAppts = clientApptsRecs.filter(appt => {
                    const apptDate = new Date(appt.dateTime);
                    return !isNaN(apptDate.getTime()) && apptDate > nowUTCDt;
                });

                if (futureAppts.length > 0) {
                    const closestFutureAppt = futureAppts.reduce((closest, current) => {
                        const closestDate = new Date(closest.dateTime);
                        const currentDate = new Date(current.dateTime);
                        return currentDate < closestDate ? current : closest;
                    });
                    setClientNextApptRec(closestFutureAppt);
                } else {
                    setClientNextApptRec(null);
                }
            } else {
                setClientNextApptRec(null);
            }
        } catch (error) {
            console.error("Error processing future appointments:", error);
            setClientNextApptRec(null);
        }
    }, [clientApptsRecs, resetProviderState]);

    return (
        <ClientApptsContext.Provider value={{ clientApptsRecs,
                                                setClientApptsShowLogs,
                                                clientFutureApptsRecs,
                                                clientLastApptRec,
                                                clientNextApptRec,
                                                setAmpUserRecId,
                                                setClientRecId }}>
            {children}
        </ClientApptsContext.Provider>
    );
}

export default ClientApptsDataProvider;
