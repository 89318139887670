import React, {useState, useEffect, useContext} from 'react';
import {useLocation} from "react-router-dom";
import AmpUserClientNotesProvider from "../../data/providers/AmpUserClientNotesProvider";
import ClientAnimalsDataProvider from "../../data/providers/ClientAnimalsDataProvider";
import ClientApptsDataProvider from "../../data/providers/ClientApptsDataProvider";
import {RequireAuth} from "../auth/RequireAuth";
import DevStateProvider from "../../data/providers/DevStateProvider";
import EditClientScreen from "../../screens/EditClientScreen";
import {RequireSub} from "../auth/RequireSub";
import {EditClientFormProvider} from "../../data/providers/EditClientFormProvider";
import { saveAmpUserClientRec, loadAmpUserClientRec, clearAmpUserClientRec } from "../../data/indexedDb/ampUserClient";

// import AmpUserClientsDataProvider from "../../data/providers/AmpUserClientsDataProvider";

export default function WrappedEditClient({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [clientRec, setClientRec] = useState(null);
    const [localShowLogs, setLocalShowLogs] = useState(false);

    const location = useLocation();

    const stateClientRec = location.state?.clientRec;

    // useEffect( setClientRec(stateClientRec) saveAmpUserClientRec(stateClientRec);
    useEffect(() => {
        if (stateClientRec) {
            setClientRec(stateClientRec);
            saveAmpUserClientRec(stateClientRec); // Save the clientRec to IndexedDB
        }
    }, [location]);

    // useEffect( await loadAmpUserClientRec() =>  setClientRec(storedClientRec);
    useEffect(() => {
        const loadFromIndexedDB = async () => {
            const storedClientRec = await loadAmpUserClientRec();
            if (storedClientRec) {
                setClientRec(storedClientRec);
            }
        };

        loadFromIndexedDB();
    }, []);

    // useEffect( saveAmpUserClientRec(clientRec);
    useEffect(() => {
        if (clientRec) {
            saveAmpUserClientRec(clientRec); // Update the clientRec in IndexedDB whenever it changes
        }
    }, [clientRec]);

    useEffect(() => {
        // Reset or remove clientRec from IndexedDB when navigating away from "/edit-client/clientRouterPath?"
        const resetClientRecIfNavigatingAway = () => {
            const currentPath = location.pathname;
            // const isEditClientPath = /^\/edit-client(\/[^/]+)?$/.test(currentPath); // Match "/edit-client" or "/edit-client/clientRouterPath"
            const isEditClientPath = currentPath.startsWith('/edit-client'); // Match "/edit-client" or "/edit-client/clientRouterPath"

            if (!isEditClientPath) {
                clearAmpUserClientRec(); // Clear clientRec from IndexedDB
                setClientRec(null); // Reset clientRec in state
            }
        };

        resetClientRecIfNavigatingAway();
    }, [location]);

    // useEffect( setClientRec(stateClientRec);
    useEffect(() => {
        if (stateClientRec) {
            setClientRec(stateClientRec);
        }
    }, [location]);

    // useEffect( console.log( clientRec
    useEffect(() => {
        if (showLogs) {
            console.log('WrappedEditClient clientRec', clientRec);
        }
    }, [showLogs, clientRec]);

    return (
        <DevStateProvider>
            <RequireAuth>
                {ampUser && clientRec ? (
                    <RequireSub ampUser={ampUser}>
                        <EditClientFormProvider>
                            <ClientApptsDataProvider clientId={clientRec.id} showLogs={showLogs} ampUserId={ampUser.id}
                                                     resetProviderState={resetProvidersStates}>
                                <ClientAnimalsDataProvider clientId={clientRec.id} showLogs={showLogs}
                                                           resetProviderState={resetProvidersStates}>
                                    <AmpUserClientNotesProvider clientId={clientRec.id} showLogs={showLogs}
                                                                resetProviderState={resetProvidersStates}>
                                        <EditClientScreen
                                            ampUser={ampUser}
                                            routerPaths={routerPaths}
                                            clientRec={clientRec}
                                            showLogs={showLogs}
                                        />
                                    </AmpUserClientNotesProvider>
                                </ClientAnimalsDataProvider>
                            </ClientApptsDataProvider>
                        </EditClientFormProvider>
                    </RequireSub>
                ) : (
                    <div><h2>Loading Client Data...</h2></div>
                )}
            </RequireAuth>
        </DevStateProvider>
    )
}
