import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledListCard from "../cards/StyledListCard";
import { useNavigate } from "react-router-dom";
import StyledAccordion from "./StyledAccordion";
import Divider from "@mui/material/Divider";
import {toTitleCase} from "../../utils/text";

export default function AnimalsSummaryAccordion({ animals, headerText, showLogs }) {
    const [animalRecs, setAnimalRecs] = useState([]);
    const [accordionHeaderText, setAccordionHeaderText] = useState('');

    let navigate = useNavigate();

    // useEffect( setAnimalRecs(animals);
    useEffect(() => {
        if (animals) {
            setAnimalRecs(animals);
        }
    }, [animals]);

    // useEffect( setAccordionHeaderText(headerText);
    useEffect(() => {
        if (headerText) {
            setAccordionHeaderText(headerText);
        }
    }, [headerText]);

    return (
        <StyledAccordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <StyledCardContentTypography
                    text={accordionHeaderText || 'Animals:'}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {animalRecs.map((animalRec, index) => (
                        <div key={`${accordionHeaderText}-${animalRec.id}`}>
                            <StyledListCard
                                onClick={() => {
                                    navigate(`/animal-info/${animalRec.routerPath}`, {state: {animalRec}});
                                }}
                            >
                                <StyledCardContentTypography text={`Name: ${animalRec?.name ? toTitleCase(animalRec.name) : '-'}`} />
                                <StyledCardContentTypography text={`Type: ${animalRec?.animalType ? toTitleCase(animalRec.animalType) : '-'}`} />
                            </StyledListCard>
                            <Divider />
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
}
