import React, {useState, useEffect} from 'react';
import { DataStore, SortDirection } from "aws-amplify";
import {ShoeTask} from "../../models";
import AnimalShoeTasksContext from "../contexts/AnimalShoeTasksContext";

const AnimalShoeTasksProvider = ({ children, animalId, showLogs, resetProviderState }) => {
    const [animalShoeTasksRecs, setAnimalShoeTasksRecs] = useState([]);
    const [animalShoeTasksRecsByApptId, setAnimalShoeTasksRecsByApptId] = useState({});
    const [animalShoeTasksShowLogs, setAnimalShoeTasksShowLogs] = useState(false);
    const [shoeTasksAnimalRecId, setShoeTasksAnimalRecId] = useState(null);

    // useEffect( reset data, based on resetProviderState
    useEffect(() => {
        if (resetProviderState) {
            setShoeTasksAnimalRecId(null);
            setAnimalShoeTasksRecs([]);
            setAnimalShoeTasksRecsByApptId({});
        }
    }, [resetProviderState]);

    // useEffect( setAnimalShoeTasksShowLogs(showLogs);
    useEffect(() => {
        if (showLogs) {
            setAnimalShoeTasksShowLogs(showLogs);
        } else {
            setAnimalShoeTasksShowLogs(false);
        }
    }, [showLogs]);

    useEffect(() => {
        if (animalId && !resetProviderState) {
            const subscription = DataStore.observeQuery(ShoeTask,
                (c) => c.animalID.eq(animalId),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalShoeTasksShowLogs) {
                        console.log(`AnimalShoeTasksProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('AnimalShoeTasksProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalShoeTasksRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [animalId, animalShoeTasksShowLogs, resetProviderState]);


    useEffect(() => {
        if (shoeTasksAnimalRecId && !resetProviderState) {
            const subscription = DataStore.observeQuery(ShoeTask,
                (c) => c.animalID.eq(shoeTasksAnimalRecId),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    if (animalShoeTasksShowLogs) {
                        console.log(`AnimalShoeTasksProvider c [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                        console.log('AnimalShoeTasksProvider c [Snapshot] items', items);
                    }
                    if (isSynced) {
                        setAnimalShoeTasksRecs(items);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [shoeTasksAnimalRecId, animalShoeTasksShowLogs, resetProviderState]);

    useEffect(() => {
        if (!resetProviderState && animalShoeTasksRecs && animalShoeTasksRecs.length > 0) {
            const shoeTasksByApptId = {};
            animalShoeTasksRecs.forEach((shoeTask) => {
                if (shoeTasksByApptId[shoeTask.appointmentID]) {
                    shoeTasksByApptId[shoeTask.appointmentID].push(shoeTask);
                } else {
                    shoeTasksByApptId[shoeTask.appointmentID] = [shoeTask];
                }
            });
            setAnimalShoeTasksRecsByApptId(shoeTasksByApptId);
        }
    }, [animalShoeTasksRecs, resetProviderState]);


    return (
        <AnimalShoeTasksContext.Provider value={{animalShoeTasksRecs,
                                                    setAnimalShoeTasksRecs,
                                                    setShoeTasksAnimalRecId,
                                                    animalShoeTasksRecsByApptId}}
        >
            {children}
        </AnimalShoeTasksContext.Provider>
    );
}

export default AnimalShoeTasksProvider;
