import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import VerticalButtonStack from "../components/buttons/VerticalButtonStack";
import BaseLinkButton from "../components/links/BaseLinkButton";
import {useConsoleError, useConsoleLog, useConsoleWarn} from "../components/hooks/logging/console";

// import AmpUserContext from "../data/contexts/AmpUserContext";

// TODO - !!! START HERE, FR, FR - IF NO USERSUB OR SUBPAYMENTVIEW COMES THROUGH,
//  THEN CREATE A NEW PENDING SUB AND ALLOW USER TO MOVE ON

export default function SubscribeCancelScreen(props) {
    const [ampUser, setAmpUser] = useState(null);

    // const {ampUserRec} = useContext(AmpUserContext);

    // useEffect( setAmpUser(props.ampUser);
    // useEffect(() => {
    //     if (ampUserRec) {
    //         setAmpUser(ampUserRec);
    //     }
    // }, []);
    // useEffect(() => {
    //     if (props?.ampUser) {
    //         setAmpUser(props.ampUser);
    //     }
    // }, [props]);

    // useEffect( console.log('SubscribeCancelScreen(props) ampUser', ampUser);
    useEffect(() => {
        console.log('SubscribeCancelScreen(props) ampUser', ampUser);
    }, [ampUser]);



    // TODO - ? UPDATE AMPUSER WITH CANCELLED SUBSCRIPTION STATUS?

    return (
        <div className={'Post-success-body'}>
            <Card sx={{ maxWidth: 420 }}>
                <CardContent>
                    <Typography>
                        Canceled New Subscription
                    </Typography>
                </CardContent>
                <Divider />
            </Card>
            <VerticalButtonStack>
                {/*<BaseLinkButton*/}
                {/*    buttonText="Create Post"*/}
                {/*    linkTarget="/create"*/}
                {/*/>*/}
                <BaseLinkButton
                    buttonText="Home"
                    linkTarget="/"
                />
                {/*<BaseLinkButton*/}
                {/*    buttonText="Fisheries"*/}
                {/*    linkTarget="/fisheries"*/}
                {/*/>*/}
                {/*<BaseLinkButton*/}
                {/*    buttonText="Regions"*/}
                {/*    linkTarget="/regions"*/}
                {/*/>*/}
                {/*<BaseLinkButton*/}
                {/*    buttonText="Gear Types"*/}
                {/*    linkTarget="/gear-types"*/}
                {/*/>*/}
            </VerticalButtonStack>
        </div>
    )
}