import React, {useState, useContext, useEffect} from "react";
import DevStateProvider from "../../data/providers/DevStateProvider";
import {RequireAuth} from "../auth/RequireAuth";
import {useParams, useLocation} from "react-router-dom";
import ClientApptsDataProvider from "../../data/providers/ClientApptsDataProvider";
import AmpUserClientApptsScreen from "../../screens/AmpUserClientApptsScreen";
import {RequireSub} from "../auth/RequireSub";

export default function WrappedClientAppts({routerPaths, ampUser, showLogs, resetProvidersStates}) {
    const [routerParamClientRouterPath, setRouterParamClientRouterPath] = useState(null);
    const [clientRec, setClientRec] = useState(null);

    const {clientRouterPath} = useParams();
    const location = useLocation();

    const stateClientRec = location.state?.clientRec;

    useEffect(() => {
        console.log('location', location);
    }, [location]);

    useEffect(() => {
        if (routerParamClientRouterPath === null && clientRouterPath !== null) {
            setRouterParamClientRouterPath(clientRouterPath);
        }
    }, [clientRouterPath]);

    useEffect(() => {
        if (stateClientRec) {
            setClientRec(stateClientRec);
        }
    }, [location]);

    useEffect(() => {
        if (showLogs) {
            console.log('WrappedClientAppts routerParamClientRouterPath', routerParamClientRouterPath);
            console.log('clientRec', clientRec);
        }
        // console.log('WrappedClientAppts routerParamClientRouterPath', routerParamClientRouterPath);
    }, [routerParamClientRouterPath, clientRec, showLogs]);

    return (
        <RequireAuth>
            <DevStateProvider>
                {clientRec && ampUser ? (
                    <RequireSub ampUser={ampUser}>
                        <ClientApptsDataProvider clientId={clientRec.id} showLogs={showLogs} ampUserId={ampUser.id} resetProviderState={resetProvidersStates}>
                            <AmpUserClientApptsScreen
                                routerPaths={routerPaths}
                                ampUser={ampUser}
                                showLogs={showLogs}
                                clientRec={clientRec}
                            />
                        </ClientApptsDataProvider>
                    </RequireSub>
                ) : (
                    <h2>Loading Client Rec...</h2>
                )}
            </DevStateProvider>
        </RequireAuth>
    )
}
